:root{
  --btn-font-size: 1em;
  --btn-font-size-sm: 0.8em;
  --btn-font-size-md: 1.2em;
  --btn-font-size-lg: 1.4em;
  --btn-padding-x: var(--space-md);
  --btn-padding-y: var(--space-xs);
  --btn-radius: 0;
}

.btn {
  cursor: pointer;
  font-family: var(--font-primary);
  font-weight: 800;
  text-decoration: none;
  line-height: 1;
  box-shadow: 0px 2px 8px 0 alpha(var(--color-black), 0.12), 0px 1px 1px 0 alpha(var(--color-black), 0.12);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0px 1px 2px 0 alpha(var(--color-black), 0.12), 0px 1px 1px 0 alpha(var(--color-black), 0.12);
  }

  &:focus {
    box-shadow: 0px 0px 0px 2px alpha(var(--color-black), 0.2);
    outline: none;
  }

  &:active {
    transform: translateY(2px);
  }
}

// themes
.btn--primary {
  background-color: var(--color-primary);
  color: var(--color-white);
  @include fontSmooth;

  &:hover {
    background-color: var(--color-primary-dark);
  }

  &:focus {
    box-shadow: 0px 0px 0px 2px alpha(var(--color-primary), 0.2);
  }
}

.btn--subtle {
  background-color: var(--color-contrast-lower);
  color: var(--color-contrast-higher);
}

.btn--accent {
  background-color: var(--color-accent);
  color: var(--color-white);
  @include fontSmooth;

  &:hover {
    background-color: var(--color-accent-dark);
  }

  &:focus {
    box-shadow: 0px 0px 0px 2px alpha(var(--color-accent), 0.2);
  }
}

// feedback
.btn--disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

// size
.btn--sm {
}

.btn--md {
}

.btn--lg {
}

.arrow {
  transition: all .5s!important;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 73px;
    height: 2px;
    background: currentColor;
    margin-left: 14px;

    &:before {
      content: "";
    position: absolute;
    right: 1px;
    width: 15px;
    height: 15px;
    border: 2px solid currentColor;
    border-left: 0;
    border-bottom: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -6px;
    }

    &:hover{
      width: 80px;
    }
}

/* -------------------------------- 

File#: _1_btn-slide-fx
Title: Button Slide Effect
Descr: Button w/ animated background that slides-in on :hover
Usage: codyhouse.co/license

-------------------------------- */

.btn--slide-fx {
  position: relative;
  overflow: hidden;

  .btn__label, .icon {
    position: relative;
    z-index: 2;
  }

  &::before { // sliding background
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-contrast-higher);
    z-index: 1;
    transform: scaleX(0) scaleY(1);
  }
}

.btn--slide-fx:not(.js-btn--slide-fx),
.btn--slide-fx.btn-slide-fx-animate {
  &::before {
    transform-origin: right center;
    transition: transform .3s var(--ease-in-out);
  }
}

.btn--slide-fx:not(.js-btn--slide-fx):hover,
.btn--slide-fx.btn-slide-fx-hover {
  &::before {
    transform: scaleX(1) scaleY(1);
    transform-origin: left center;
  }
}

.btn--slide-fx-from-right:not(.js-btn--slide-fx),
.btn--slide-fx-from-right.btn-slide-fx-animate {
  &::before {
    transform-origin: left center;
  }
}

.btn--slide-fx-from-right:not(.js-btn--slide-fx):hover,
.btn--slide-fx-from-right.btn-slide-fx-hover {
  &::before {
    transform-origin: right center;
  }
}

.btn--slide-fx-from-bottom,
.btn--slide-fx-from-top {
  &::before {
    transform: scaleX(1) scaleY(0);
  }
}

.btn--slide-fx-from-bottom:not(.js-btn--slide-fx):hover,
.btn--slide-fx-from-top:not(.js-btn--slide-fx):hover,
.btn--slide-fx-from-bottom.btn-slide-fx-hover
.btn--slide-fx-from-top.btn-slide-fx-hover {
  &::before {
    transform: scaleX(1) scaleY(1);
  }
}

.btn--slide-fx-from-bottom:not(.js-btn--slide-fx),
.btn--slide-fx-from-bottom.btn-slide-fx-animate {
  &::before {
    transform-origin: center top;
  }
}

.btn--slide-fx-from-bottom:not(.js-btn--slide-fx):hover,
.btn--slide-fx-from-bottom.btn-slide-fx-hover {
  &::before {
    transform-origin: center bottom;
  }
}

.btn--slide-fx-from-top:not(.js-btn--slide-fx),
.btn--slide-fx-from-top.btn-slide-fx-animate { 
  &::before {
    transform-origin: center bottom;
  }
}

.btn--slide-fx-from-top:not(.js-btn--slide-fx):hover,
.btn--slide-fx-from-top.btn-slide-fx-hover {
  &::before {
    transform-origin: center top;
  }
}

.btn--slide-fx-to-left, 
.btn--slide-fx-to-right,
.btn--slide-fx-to-top, 
.btn--slide-fx-to-bottom {
  &::before {
    transform: scaleX(1) scaleY(1);
  }
}

.btn--slide-fx-to-left.btn-slide-fx-hover-out, 
.btn--slide-fx-to-right.btn-slide-fx-hover-out {
  &::before {
    transform: scaleX(0) scaleY(1);
  }
}

.btn--slide-fx-to-top.btn-slide-fx-hover-out, 
.btn--slide-fx-to-bottom.btn-slide-fx-hover-out {
  &::before {
    transform: scaleX(1) scaleY(0);
  }
}

.btn--slide-fx-to-left.btn-slide-fx-animate::before {
  transform-origin: left center;
}

.btn--slide-fx-to-right.btn-slide-fx-animate::before {
  transform-origin: right center;
}

.btn--slide-fx-to-top.btn-slide-fx-animate::before {
  transform-origin: center top;
}

.btn--slide-fx-to-bottom.btn-slide-fx-animate::before {
  transform-origin: center bottom;
}


.btn--slide-fx-replace-label { // replace label with icon
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
    transition: transform .3s .1s, opacity .3s var(--ease-out);
  }

  &:hover .icon {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}