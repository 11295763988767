/* -------------------------------- 

File#: _1_main-footer-v4
Title: Main Footer v4
Descr: Footer navigation template
Usage: codyhouse.co/license

-------------------------------- */

.footer-v4 {}

.footer-v4__nav {
  margin-bottom: var(--space-lg);
}

.footer-v4__nav-item {
  margin-bottom: var(--space-sm);

  a {
    color: var(--color-contrast-high);
    font-size: 1.25em;

    &:hover {
      color: var(--color-primary);
    }
  }
}

.footer-v4__logo {
  margin-bottom: var(--space-sm);
  
  a, svg, img {
    width: 160px;
    display: block;
  }
}

.footer-v4__print {
  color: var(--color-contrast-medium);
  font-size: var(--text-sm);
  margin-bottom: var(--space-sm);
}

.footer-v4__socials {
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
    display: inline-block;
    margin-right: var(--space-xs);
    color: var(--color-contrast-medium); // icons color

    &:hover {
      color: var(--color-contrast-high);
    }

    svg {
      display: block;
      width: 1.25em;
      height: 1.25em;
      color: inherit;
    }
  }
}

@include breakpoint(md) {
  .footer-v4 {
    text-align: center;
  }

  .footer-v4__nav-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .footer-v4__nav-item  {
    display: inline-block; // fallback
    margin: var(--space-xxxs) var(--space-xs);

    a {
      font-size: 0.75em; // reduce nav items size
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  .footer-v4__logo {
    display: inline-block;
  }

  .footer-v4__print {
    font-size: var(--text-xs);
  }

  .footer-v4__socials {
    justify-content: center;

    a {
      margin: 0 var(--space-xxxs);

      svg {
        width: 1em;
        height: 1em;
      }
    }
  }
}

/* -------------------------------- 

File#: _1_main-footer
Title: Main Footer
Descr: Footer navigation
Usage: codyhouse.co/license

-------------------------------- */

.main-footer {}

.main-footer__content {
  @include breakpoint(md) { // align logo + nav
    display: flex;
    justify-content: space-between;
  }
}

.main-footer__logo {
  flex-shrink: 0;
  margin-bottom: var(--space-md);

  a, svg, img {
    width: 180px;
    display: block;
  }
}

.main-footer__nav {
  @include breakpoint(md) {
    width: calc(100% - 420px);
  }

  @include breakpoint(lg) {
    width: auto;
  }
}

.main-footer__nav-list { // ul
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.main-footer__nav-item {
  display: grid;
  grid-gap: var(--space-xxs);
  margin-bottom: var(--space-md);
  width: 100%;
  padding-right: var(--space-sm);

  @include breakpoint(xs) {
    width: 50%;
  }

  @include breakpoint(lg) {
    grid-gap: var(--space-xs);
    width: 240px;
  }
}

.main-footer__nav-item a { // footer nav link
  color: var(--color-contrast-medium);

  &:hover {
    color: var(--color-contrast-high);
  }

  @include breakpoint(lg) {
    font-size: 0.9em;
  }
}

// colophon
.main-footer__colophon {
  border-top: 1px solid var(--color-contrast-low);
  padding-top: var(--space-xxxs);
  margin-top: var(--space-md);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.main-footer__colophon-nav {
  font-size: var(--text-sm);
  color: var(--color-contrast-medium);
  margin-right: var(--space-sm);

  > * {
    margin-right: var(--space-xxs);
  }

  a {
    color: inherit;

    &:hover {
      color: var(--color-contrast-high);
    }
  }

  @include breakpoint(md) {
    font-size: var(--text-xs);
  }
}

.main-footer__socials {
  display: flex;
  margin: var(--space-xxs) 0;

  a {
    text-decoration: none;
    display: inline-block;
    margin-left: var(--space-xxs);
    color: var(--color-contrast-medium);

    &:hover {
      color: var(--color-contrast-high);
    }

    svg {
      display: block;
      width: 1em;
      height: 1em;
      color: inherit;
    }
  }
}