*, *::after, *::before {
  box-sizing: inherit;
}

* {
  font: inherit;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, hr {
  margin: 0;
  padding: 0;
  border: 0;
}

html {
  box-sizing: border-box;
}

body {
  background-color: var(--color-bg, white);
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main, form legend {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

button, input, textarea, select {
  margin: 0;
}

.btn, .form-control, .link, .reset {
  background-color: transparent;
  padding: 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  line-height: inherit;
  appearance: none;
}

select.form-control::-ms-expand {
  display: none;
}

textarea {
  resize: vertical;
  overflow: auto;
  vertical-align: top;
}

input::-ms-clear {
  display: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img, video, svg {
  max-width: 100%;
}

[data-theme] {
  background-color: var(--color-bg, white);
  color: var(--color-contrast-high, #313135);
}

:root {
  --space-unit:  1em;
  --space-xxxxs: calc(0.125 * var(--space-unit));
  --space-xxxs:  calc(0.25 * var(--space-unit));
  --space-xxs:   calc(0.375 * var(--space-unit));
  --space-xs:    calc(0.5 * var(--space-unit));
  --space-sm:    calc(0.75 * var(--space-unit));
  --space-md:    calc(1.25 * var(--space-unit));
  --space-lg:    calc(2 * var(--space-unit));
  --space-xl:    calc(3.25 * var(--space-unit));
  --space-xxl:   calc(5.25 * var(--space-unit));
  --space-xxxl:  calc(8.5 * var(--space-unit));
  --space-xxxxl: calc(13.75 * var(--space-unit));
  --component-padding: var(--space-md);
}

:root {
  --max-width-xxxxs: 20rem;
  --max-width-xxxs:  26rem;
  --max-width-xxs:   32rem;
  --max-width-xs:    38rem;
  --max-width-sm:    48rem;
  --max-width-md:    64rem;
  --max-width-lg:    80rem;
  --max-width-xl:    90rem;
  --max-width-xxl:   100rem;
  --max-width-xxxl:  120rem;
  --max-width-xxxxl: 150rem;
}

.container {
  width: calc(100% - 2*var(--component-padding));
  margin-left: auto;
  margin-right: auto;
}

.max-width-xxxxs {
  max-width: var(--max-width-xxxxs);
}

.max-width-xxxs {
  max-width: var(--max-width-xxxs);
}

.max-width-xxs {
  max-width: var(--max-width-xxs);
}

.max-width-xs {
  max-width: var(--max-width-xs);
}

.max-width-sm {
  max-width: var(--max-width-sm);
}

.max-width-md {
  max-width: var(--max-width-md);
}

.max-width-lg {
  max-width: var(--max-width-lg);
}

.max-width-xl {
  max-width: var(--max-width-xl);
}

.max-width-xxl {
  max-width: var(--max-width-xxl);
}

.max-width-xxxl {
  max-width: var(--max-width-xxxl);
}

.max-width-xxxxl {
  max-width: var(--max-width-xxxxl);
}

[class*="max-width-adaptive"] {
  max-width: 32rem;
}

@media (min-width: 48rem) {
  [class*="max-width-adaptive"] {
    max-width: 48rem;
  }
}

@media (min-width: 64rem) {
  .max-width-adaptive-md,
  .max-width-adaptive,
  .max-width-adaptive-lg,
  .max-width-adaptive-xl {
    max-width: 64rem;
  }
}

@media (min-width: 80rem) {
  .max-width-adaptive,
  .max-width-adaptive-lg,
  .max-width-adaptive-xl {
    max-width: 80rem;
  }
}

@media (min-width: 90rem) {
  .max-width-adaptive-xl {
    max-width: 90rem;
  }
}

.grid {
  --grid-gap: 0px;
  --offset: var(--grid-gap);
  display: flex;
  flex-wrap: wrap;
}

.grid > * {
  flex-basis: 100%;
}

[class*="grid-gap"]:not([class*="grid-auto"]) {
  margin-bottom: calc(-1 * var(--grid-gap, 1em));
  margin-left: calc(-1 * var(--grid-gap, 1em));
}

[class*="grid-gap"]:not([class*="grid-auto"]) > * {
  margin-bottom: var(--grid-gap, 1em);
  margin-left: var(--offset, 1em);
}

@supports (--css: variables) {
  .grid-gap-xxxxs {
    --grid-gap: var(--space-xxxxs);
  }
  .grid-gap-xxxs {
    --grid-gap: var(--space-xxxs);
  }
  .grid-gap-xxs {
    --grid-gap: var(--space-xxs);
  }
  .grid-gap-xs {
    --grid-gap: var(--space-xs);
  }
  .grid-gap-sm {
    --grid-gap: var(--space-sm);
  }
  .grid-gap-md {
    --grid-gap: var(--space-md);
  }
  .grid-gap-lg {
    --grid-gap: var(--space-lg);
  }
  .grid-gap-xl {
    --grid-gap: var(--space-xl);
  }
  .grid-gap-xxl {
    --grid-gap: var(--space-xxl);
  }
  .grid-gap-xxxl {
    --grid-gap: var(--space-xxxl);
  }
  .grid-gap-xxxxl {
    --grid-gap: var(--space-xxxxl);
  }
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-1 {
  flex-basis: calc( 8.33% - 0.01px - var(--grid-gap, 1em));
  max-width: calc( 8.33% - 0.01px - var(--grid-gap, 1em));
}

.col-2 {
  flex-basis: calc( 16.66% - 0.01px - var(--grid-gap, 1em));
  max-width: calc( 16.66% - 0.01px - var(--grid-gap, 1em));
}

.col-3 {
  flex-basis: calc( 25% - 0.01px - var(--grid-gap, 1em));
  max-width: calc( 25% - 0.01px - var(--grid-gap, 1em));
}

.col-4 {
  flex-basis: calc( 33.33% - 0.01px - var(--grid-gap, 1em));
  max-width: calc( 33.33% - 0.01px - var(--grid-gap, 1em));
}

.col-5 {
  flex-basis: calc( 41.66% - 0.01px - var(--grid-gap, 1em));
  max-width: calc( 41.66% - 0.01px - var(--grid-gap, 1em));
}

.col-6 {
  flex-basis: calc( 50% - 0.01px - var(--grid-gap, 1em));
  max-width: calc( 50% - 0.01px - var(--grid-gap, 1em));
}

.col-7 {
  flex-basis: calc( 58.33% - 0.01px - var(--grid-gap, 1em));
  max-width: calc( 58.33% - 0.01px - var(--grid-gap, 1em));
}

.col-8 {
  flex-basis: calc( 66.66% - 0.01px - var(--grid-gap, 1em));
  max-width: calc( 66.66% - 0.01px - var(--grid-gap, 1em));
}

.col-9 {
  flex-basis: calc( 75% - 0.01px - var(--grid-gap, 1em));
  max-width: calc( 75% - 0.01px - var(--grid-gap, 1em));
}

.col-10 {
  flex-basis: calc( 83.33% - 0.01px - var(--grid-gap, 1em));
  max-width: calc( 83.33% - 0.01px - var(--grid-gap, 1em));
}

.col-11 {
  flex-basis: calc( 91.66% - 0.01px - var(--grid-gap, 1em));
  max-width: calc( 91.66% - 0.01px - var(--grid-gap, 1em));
}

.col-12 {
  flex-basis: calc( 100% - 0.01px - var(--grid-gap, 1em));
  max-width: calc( 100% - 0.01px - var(--grid-gap, 1em));
}

.offset-1 {
  --offset: calc(8.33% + var(--grid-gap, 1em));
}

.offset-2 {
  --offset: calc(16.66% + var(--grid-gap, 1em));
}

.offset-3 {
  --offset: calc(25% + var(--grid-gap, 1em));
}

.offset-4 {
  --offset: calc(33.33% + var(--grid-gap, 1em));
}

.offset-5 {
  --offset: calc(41.66% + var(--grid-gap, 1em));
}

.offset-6 {
  --offset: calc(50% + var(--grid-gap, 1em));
}

.offset-7 {
  --offset: calc(58.33% + var(--grid-gap, 1em));
}

.offset-8 {
  --offset: calc(66.66% + var(--grid-gap, 1em));
}

.offset-9 {
  --offset: calc(75% + var(--grid-gap, 1em));
}

.offset-10 {
  --offset: calc(83.33% + var(--grid-gap, 1em));
}

.offset-11 {
  --offset: calc(91.66% + var(--grid-gap, 1em));
}

@media (min-width: 32rem) {
  .col\@xs {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-1\@xs {
    flex-basis: calc( 8.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 8.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-2\@xs {
    flex-basis: calc( 16.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 16.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-3\@xs {
    flex-basis: calc( 25% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 25% - 0.01px - var(--grid-gap, 1em));
  }
  .col-4\@xs {
    flex-basis: calc( 33.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 33.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-5\@xs {
    flex-basis: calc( 41.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 41.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-6\@xs {
    flex-basis: calc( 50% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 50% - 0.01px - var(--grid-gap, 1em));
  }
  .col-7\@xs {
    flex-basis: calc( 58.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 58.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-8\@xs {
    flex-basis: calc( 66.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 66.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-9\@xs {
    flex-basis: calc( 75% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 75% - 0.01px - var(--grid-gap, 1em));
  }
  .col-10\@xs {
    flex-basis: calc( 83.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 83.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-11\@xs {
    flex-basis: calc( 91.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 91.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-12\@xs {
    flex-basis: calc( 100% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 100% - 0.01px - var(--grid-gap, 1em));
  }
  .offset-1\@xs {
    --offset: calc(8.33% + var(--grid-gap, 1em));
  }
  .offset-2\@xs {
    --offset: calc(16.66% + var(--grid-gap, 1em));
  }
  .offset-3\@xs {
    --offset: calc(25% + var(--grid-gap, 1em));
  }
  .offset-4\@xs {
    --offset: calc(33.33% + var(--grid-gap, 1em));
  }
  .offset-5\@xs {
    --offset: calc(41.66% + var(--grid-gap, 1em));
  }
  .offset-6\@xs {
    --offset: calc(50% + var(--grid-gap, 1em));
  }
  .offset-7\@xs {
    --offset: calc(58.33% + var(--grid-gap, 1em));
  }
  .offset-8\@xs {
    --offset: calc(66.66% + var(--grid-gap, 1em));
  }
  .offset-9\@xs {
    --offset: calc(75% + var(--grid-gap, 1em));
  }
  .offset-10\@xs {
    --offset: calc(83.33% + var(--grid-gap, 1em));
  }
  .offset-11\@xs {
    --offset: calc(91.66% + var(--grid-gap, 1em));
  }
}

@media (min-width: 48rem) {
  .col\@sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-1\@sm {
    flex-basis: calc( 8.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 8.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-2\@sm {
    flex-basis: calc( 16.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 16.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-3\@sm {
    flex-basis: calc( 25% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 25% - 0.01px - var(--grid-gap, 1em));
  }
  .col-4\@sm {
    flex-basis: calc( 33.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 33.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-5\@sm {
    flex-basis: calc( 41.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 41.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-6\@sm {
    flex-basis: calc( 50% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 50% - 0.01px - var(--grid-gap, 1em));
  }
  .col-7\@sm {
    flex-basis: calc( 58.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 58.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-8\@sm {
    flex-basis: calc( 66.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 66.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-9\@sm {
    flex-basis: calc( 75% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 75% - 0.01px - var(--grid-gap, 1em));
  }
  .col-10\@sm {
    flex-basis: calc( 83.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 83.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-11\@sm {
    flex-basis: calc( 91.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 91.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-12\@sm {
    flex-basis: calc( 100% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 100% - 0.01px - var(--grid-gap, 1em));
  }
  .offset-1\@sm {
    --offset: calc(8.33% + var(--grid-gap, 1em));
  }
  .offset-2\@sm {
    --offset: calc(16.66% + var(--grid-gap, 1em));
  }
  .offset-3\@sm {
    --offset: calc(25% + var(--grid-gap, 1em));
  }
  .offset-4\@sm {
    --offset: calc(33.33% + var(--grid-gap, 1em));
  }
  .offset-5\@sm {
    --offset: calc(41.66% + var(--grid-gap, 1em));
  }
  .offset-6\@sm {
    --offset: calc(50% + var(--grid-gap, 1em));
  }
  .offset-7\@sm {
    --offset: calc(58.33% + var(--grid-gap, 1em));
  }
  .offset-8\@sm {
    --offset: calc(66.66% + var(--grid-gap, 1em));
  }
  .offset-9\@sm {
    --offset: calc(75% + var(--grid-gap, 1em));
  }
  .offset-10\@sm {
    --offset: calc(83.33% + var(--grid-gap, 1em));
  }
  .offset-11\@sm {
    --offset: calc(91.66% + var(--grid-gap, 1em));
  }
}

@media (min-width: 64rem) {
  .col\@md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-1\@md {
    flex-basis: calc( 8.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 8.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-2\@md {
    flex-basis: calc( 16.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 16.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-3\@md {
    flex-basis: calc( 25% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 25% - 0.01px - var(--grid-gap, 1em));
  }
  .col-4\@md {
    flex-basis: calc( 33.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 33.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-5\@md {
    flex-basis: calc( 41.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 41.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-6\@md {
    flex-basis: calc( 50% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 50% - 0.01px - var(--grid-gap, 1em));
  }
  .col-7\@md {
    flex-basis: calc( 58.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 58.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-8\@md {
    flex-basis: calc( 66.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 66.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-9\@md {
    flex-basis: calc( 75% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 75% - 0.01px - var(--grid-gap, 1em));
  }
  .col-10\@md {
    flex-basis: calc( 83.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 83.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-11\@md {
    flex-basis: calc( 91.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 91.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-12\@md {
    flex-basis: calc( 100% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 100% - 0.01px - var(--grid-gap, 1em));
  }
  .offset-1\@md {
    --offset: calc(8.33% + var(--grid-gap, 1em));
  }
  .offset-2\@md {
    --offset: calc(16.66% + var(--grid-gap, 1em));
  }
  .offset-3\@md {
    --offset: calc(25% + var(--grid-gap, 1em));
  }
  .offset-4\@md {
    --offset: calc(33.33% + var(--grid-gap, 1em));
  }
  .offset-5\@md {
    --offset: calc(41.66% + var(--grid-gap, 1em));
  }
  .offset-6\@md {
    --offset: calc(50% + var(--grid-gap, 1em));
  }
  .offset-7\@md {
    --offset: calc(58.33% + var(--grid-gap, 1em));
  }
  .offset-8\@md {
    --offset: calc(66.66% + var(--grid-gap, 1em));
  }
  .offset-9\@md {
    --offset: calc(75% + var(--grid-gap, 1em));
  }
  .offset-10\@md {
    --offset: calc(83.33% + var(--grid-gap, 1em));
  }
  .offset-11\@md {
    --offset: calc(91.66% + var(--grid-gap, 1em));
  }
}

@media (min-width: 80rem) {
  .col\@lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-1\@lg {
    flex-basis: calc( 8.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 8.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-2\@lg {
    flex-basis: calc( 16.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 16.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-3\@lg {
    flex-basis: calc( 25% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 25% - 0.01px - var(--grid-gap, 1em));
  }
  .col-4\@lg {
    flex-basis: calc( 33.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 33.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-5\@lg {
    flex-basis: calc( 41.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 41.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-6\@lg {
    flex-basis: calc( 50% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 50% - 0.01px - var(--grid-gap, 1em));
  }
  .col-7\@lg {
    flex-basis: calc( 58.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 58.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-8\@lg {
    flex-basis: calc( 66.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 66.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-9\@lg {
    flex-basis: calc( 75% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 75% - 0.01px - var(--grid-gap, 1em));
  }
  .col-10\@lg {
    flex-basis: calc( 83.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 83.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-11\@lg {
    flex-basis: calc( 91.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 91.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-12\@lg {
    flex-basis: calc( 100% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 100% - 0.01px - var(--grid-gap, 1em));
  }
  .offset-1\@lg {
    --offset: calc(8.33% + var(--grid-gap, 1em));
  }
  .offset-2\@lg {
    --offset: calc(16.66% + var(--grid-gap, 1em));
  }
  .offset-3\@lg {
    --offset: calc(25% + var(--grid-gap, 1em));
  }
  .offset-4\@lg {
    --offset: calc(33.33% + var(--grid-gap, 1em));
  }
  .offset-5\@lg {
    --offset: calc(41.66% + var(--grid-gap, 1em));
  }
  .offset-6\@lg {
    --offset: calc(50% + var(--grid-gap, 1em));
  }
  .offset-7\@lg {
    --offset: calc(58.33% + var(--grid-gap, 1em));
  }
  .offset-8\@lg {
    --offset: calc(66.66% + var(--grid-gap, 1em));
  }
  .offset-9\@lg {
    --offset: calc(75% + var(--grid-gap, 1em));
  }
  .offset-10\@lg {
    --offset: calc(83.33% + var(--grid-gap, 1em));
  }
  .offset-11\@lg {
    --offset: calc(91.66% + var(--grid-gap, 1em));
  }
}

@media (min-width: 90rem) {
  .col\@xl {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-1\@xl {
    flex-basis: calc( 8.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 8.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-2\@xl {
    flex-basis: calc( 16.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 16.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-3\@xl {
    flex-basis: calc( 25% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 25% - 0.01px - var(--grid-gap, 1em));
  }
  .col-4\@xl {
    flex-basis: calc( 33.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 33.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-5\@xl {
    flex-basis: calc( 41.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 41.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-6\@xl {
    flex-basis: calc( 50% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 50% - 0.01px - var(--grid-gap, 1em));
  }
  .col-7\@xl {
    flex-basis: calc( 58.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 58.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-8\@xl {
    flex-basis: calc( 66.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 66.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-9\@xl {
    flex-basis: calc( 75% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 75% - 0.01px - var(--grid-gap, 1em));
  }
  .col-10\@xl {
    flex-basis: calc( 83.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 83.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-11\@xl {
    flex-basis: calc( 91.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 91.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-12\@xl {
    flex-basis: calc( 100% - 0.01px - var(--grid-gap, 1em));
    max-width: calc( 100% - 0.01px - var(--grid-gap, 1em));
  }
  .offset-1\@xl {
    --offset: calc(8.33% + var(--grid-gap, 1em));
  }
  .offset-2\@xl {
    --offset: calc(16.66% + var(--grid-gap, 1em));
  }
  .offset-3\@xl {
    --offset: calc(25% + var(--grid-gap, 1em));
  }
  .offset-4\@xl {
    --offset: calc(33.33% + var(--grid-gap, 1em));
  }
  .offset-5\@xl {
    --offset: calc(41.66% + var(--grid-gap, 1em));
  }
  .offset-6\@xl {
    --offset: calc(50% + var(--grid-gap, 1em));
  }
  .offset-7\@xl {
    --offset: calc(58.33% + var(--grid-gap, 1em));
  }
  .offset-8\@xl {
    --offset: calc(66.66% + var(--grid-gap, 1em));
  }
  .offset-9\@xl {
    --offset: calc(75% + var(--grid-gap, 1em));
  }
  .offset-10\@xl {
    --offset: calc(83.33% + var(--grid-gap, 1em));
  }
  .offset-11\@xl {
    --offset: calc(91.66% + var(--grid-gap, 1em));
  }
}

:root {
  --radius-sm: calc(var(--radius, 0.25em)/2);
  --radius-md: var(--radius, 0.25em);
  --radius-lg: calc(var(--radius, 0.25em)*2);
  --shadow-xs: 0 1px 2px rgba(0, 0, 0, .1);
  --shadow-sm: 0 1px 2px rgba(0, 0, 0, .085), 0 1px 8px rgba(0, 0, 0, .1);
  --shadow-md: 0 1px 8px rgba(0, 0, 0, .085), 0 8px 24px rgba(0, 0, 0, .1);
  --shadow-lg: 0 1px 8px rgba(0, 0, 0, .085), 0 16px 48px rgba(0, 0, 0, .085), 0 24px 60px rgba(0, 0, 0, .085);
  --shadow-xl: 0 1px 8px rgba(0, 0, 0, .085), 0 16px 64px rgba(0, 0, 0, .15), 0 24px 100px rgba(0, 0, 0, .15);
  --bounce: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
  --ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  --ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
}

:root {
  --heading-line-height: 1.2;
  --body-line-height: 1.4;
}

body {
  font-size: var(--text-base-size, 1em);
  font-family: var(--font-primary, sans-serif);
  color: var(--color-contrast-high, #313135);
}

h1, h2, h3, h4 {
  color: var(--color-contrast-higher, #1c1c21);
  line-height: var(--heading-line-height, 1.2);
}

.text-xxxl {
  font-size: var(--text-xxxl, 2.488em);
}

h1, .text-xxl {
  font-size: var(--text-xxl, 2.074em);
}

h2, .text-xl {
  font-size: var(--text-xl, 1.728em);
}

h3, .text-lg {
  font-size: var(--text-lg, 1.44em);
}

h4, .text-md {
  font-size: var(--text-md, 1.2em);
}

.text-base {
  font-size: 1em;
}

small, .text-sm {
  font-size: var(--text-sm, 0.833em);
}

.text-xs {
  font-size: var(--text-xs, 0.694em);
}

a, .link {
  color: var(--color-primary, #2a6df4);
  text-decoration: underline;
}

strong {
  font-weight: bold;
}

s {
  text-decoration: line-through;
}

u {
  text-decoration: underline;
}

.text-component h1, .text-component h2, .text-component h3, .text-component h4 {
  line-height: calc(var(--heading-line-height) * var(--line-height-multiplier, 1));
  margin-bottom: calc(var(--space-unit) * 0.25 * var(--text-vspace-multiplier, 1));
}

.text-component h2, .text-component h3, .text-component h4 {
  margin-top: calc(var(--space-unit) * 0.75 * var(--text-vspace-multiplier, 1));
}

.text-component p, .text-component blockquote, .text-component ul li, .text-component ol li {
  line-height: calc(var(--body-line-height) * var(--line-height-multiplier, 1));
}

.text-component ul, .text-component ol, .text-component p, .text-component blockquote, .text-component .text-component__block {
  margin-bottom: calc(var(--space-unit) * 0.75 * var(--text-vspace-multiplier, 1));
}

.text-component ul, .text-component ol {
  list-style-position: inside;
}

.text-component ul {
  list-style-type: disc;
}

.text-component ol {
  list-style-type: decimal;
}

.text-component img {
  display: block;
  margin: 0 auto;
}

.text-component figcaption {
  text-align: center;
  margin-top: calc(var(--space-unit) * 0.5);
}

.text-component em {
  font-style: italic;
}

.text-component hr {
  margin-top: calc(var(--space-unit) * 2 * var(--text-vspace-multiplier, 1));
  margin-bottom: calc(var(--space-unit) * 2 * var(--text-vspace-multiplier, 1));
  margin-left: auto;
  margin-right: auto;
}

.text-component > *:first-child {
  margin-top: 0;
}

.text-component > *:last-child {
  margin-bottom: 0;
}

.text-component__block--full-width {
  width: 100vw;
  margin-left: calc(50% - 50vw);
}

@media (min-width: 48rem) {
  .text-component__block--left,
  .text-component__block--right {
    width: 45%;
  }
  .text-component__block--left img,
  .text-component__block--right img {
    width: 100%;
  }
  .text-component__block--left {
    float: left;
    margin-right: calc(var(--space-unit) * 0.75 * var(--text-vspace-multiplier, 1));
  }
  .text-component__block--right {
    float: right;
    margin-left: calc(var(--space-unit) * 0.75 * var(--text-vspace-multiplier, 1));
  }
}

@media (min-width: 90rem) {
  .text-component__block--outset {
    width: calc(100% + 10.5 * var(--space-unit));
  }
  .text-component__block--outset img {
    width: 100%;
  }
  .text-component__block--outset:not(.text-component__block--right) {
    margin-left: calc(-5.25 * var(--space-unit));
  }
  .text-component__block--left, .text-component__block--right {
    width: 50%;
  }
  .text-component__block--right.text-component__block--outset {
    margin-right: calc(-5.25 * var(--space-unit));
  }
}

:root {
  --icon-xxs: 12px;
  --icon-xs:  16px;
  --icon-sm:  24px;
  --icon-md:  32px;
  --icon-lg:  48px;
  --icon-xl:  64px;
  --icon-xxl: 128px;
}

.icon {
  display: inline-block;
  color: inherit;
  fill: currentColor;
  height: 1em;
  width: 1em;
  line-height: 1;
  flex-shrink: 0;
  max-width: initial;
}

.icon--xxs {
  font-size: var(--icon-xxs);
}

.icon--xs {
  font-size: var(--icon-xs);
}

.icon--sm {
  font-size: var(--icon-sm);
}

.icon--md {
  font-size: var(--icon-md);
}

.icon--lg {
  font-size: var(--icon-lg);
}

.icon--xl {
  font-size: var(--icon-xl);
}

.icon--xxl {
  font-size: var(--icon-xxl);
}

.icon--is-spinning {
  animation: icon-spin 1s infinite linear;
}

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.icon use {
  color: inherit;
  fill: currentColor;
}

.btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  text-decoration: none;
  line-height: 1;
  font-size: var(--btn-font-size, 1em);
  padding-top: var(--btn-padding-y, 0.5em);
  padding-bottom: var(--btn-padding-y, 0.5em);
  padding-left: var(--btn-padding-x, 0.75em);
  padding-right: var(--btn-padding-x, 0.75em);
  border-radius: var(--btn-radius, 0.25em);
}

.btn--primary {
  background-color: var(--color-primary, #2a6df4);
  color: var(--color-white, white);
}

.btn--subtle {
  background-color: var(--color-contrast-low, #d3d3d4);
  color: var(--color-contrast-higher, #1c1c21);
}

.btn--accent {
  background-color: var(--color-accent, #e02447);
  color: var(--color-white, white);
}

.btn--sm {
  font-size: var(--btn-font-size-sm, 0.8em);
}

.btn--md {
  font-size: var(--btn-font-size-md, 1.2em);
}

.btn--lg {
  font-size: var(--btn-font-size-lg, 1.4em);
}

.btn--icon {
  padding: var(--btn-padding-y, 0.5em);
}

.form-control {
  background-color: var(--color-bg, #f2f2f2);
  padding-top: var(--form-control-padding-y, 0.5em);
  padding-bottom: var(--form-control-padding-y, 0.5em);
  padding-left: var(--form-control-padding-x, 0.75em);
  padding-right: var(--form-control-padding-x, 0.75em);
  border-radius: var(--form-control-radius, 0.25em);
}

.form-control::-webkit-input-placeholder {
  color: var(--color-contrast-medium, #79797c);
}

.form-control::-moz-placeholder {
  opacity: 1;
  color: var(--color-contrast-medium, #79797c);
}

.form-control:-ms-input-placeholder {
  color: var(--color-contrast-medium, #79797c);
}

.form-control:-moz-placeholder {
  color: var(--color-contrast-medium, #79797c);
}

.form-legend {
  color: var(--color-contrast-higher, #1c1c21);
  line-height: 1.2;
  font-size: var(--text-md, 1.2em);
  margin-bottom: var(--space-xxs);
}

.form-label {
  display: inline-block;
}

.form__msg-error, .form-error-msg {
  color: var(--color-error, #e02447);
  font-size: var(--text-sm, 0.833em);
  margin-top: var(--space-xxs);
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
}

.form__msg-error--is-visible, .form-error-msg--is-visible {
  position: relative;
  clip: auto;
}

.radio-list > *, .checkbox-list > * {
  position: relative;
  display: flex;
  align-items: baseline;
}

.radio-list label, .checkbox-list label {
  line-height: var(--body-line-height);
}

.radio-list input, .checkbox-list input {
  margin-right: var(--space-xxxs);
  flex-shrink: 0;
}

:root {
  --zindex-header: 2;
  --zindex-popover: 5;
  --zindex-fixed-element: 10;
  --zindex-overlay: 15;
}

:root {
  --display: block;
}

.is-visible {
  display: var(--display) !important;
}

.is-hidden {
  display: none !important;
}

.sr-only {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  overflow: hidden;
  padding: 0;
  border: 0;
  white-space: nowrap;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-basis-0 {
  flex-basis: 0;
}

[class*="flex-gap-"] {
  margin-bottom: calc(-1 * var(--gap, 0.5em));
  margin-left: calc(-1 * var(--gap, 0.5em));
}

[class*="flex-gap-"] > * {
  margin-bottom: var(--gap, 0.5em);
  margin-left: var(--gap, 0.5em);
}

@supports (--css: variables) {
  .flex-gap-xxxxs {
    --gap: var(--space-xxxxs);
  }
  .flex-gap-xxxs {
    --gap: var(--space-xxxs);
  }
  .flex-gap-xxs {
    --gap: var(--space-xxs);
  }
  .flex-gap-xs {
    --gap: var(--space-xs);
  }
  .flex-gap-sm {
    --gap: var(--space-sm);
  }
  .flex-gap-md {
    --gap: var(--space-md);
  }
  .flex-gap-lg {
    --gap: var(--space-lg);
  }
  .flex-gap-xl {
    --gap: var(--space-xl);
  }
  .flex-gap-xxl {
    --gap: var(--space-xxl);
  }
  .flex-gap-xxxl {
    --gap: var(--space-xxxl);
  }
  .flex-gap-xxxxl {
    --gap: var(--space-xxxxl);
  }
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.margin-xxxxs {
  margin: var(--space-xxxxs);
}

.margin-xxxs {
  margin: var(--space-xxxs);
}

.margin-xxs {
  margin: var(--space-xxs);
}

.margin-xs {
  margin: var(--space-xs);
}

.margin-sm {
  margin: var(--space-sm);
}

.margin-md {
  margin: var(--space-md);
}

.margin-lg {
  margin: var(--space-lg);
}

.margin-xl {
  margin: var(--space-xl);
}

.margin-xxl {
  margin: var(--space-xxl);
}

.margin-xxxl {
  margin: var(--space-xxxl);
}

.margin-xxxxl {
  margin: var(--space-xxxxl);
}

.margin-auto {
  margin: auto;
}

.margin-top-xxxxs {
  margin-top: var(--space-xxxxs);
}

.margin-top-xxxs {
  margin-top: var(--space-xxxs);
}

.margin-top-xxs {
  margin-top: var(--space-xxs);
}

.margin-top-xs {
  margin-top: var(--space-xs);
}

.margin-top-sm {
  margin-top: var(--space-sm);
}

.margin-top-md {
  margin-top: var(--space-md);
}

.margin-top-lg {
  margin-top: var(--space-lg);
}

.margin-top-xl {
  margin-top: var(--space-xl);
}

.margin-top-xxl {
  margin-top: var(--space-xxl);
}

.margin-top-xxxl {
  margin-top: var(--space-xxxl);
}

.margin-top-xxxxl {
  margin-top: var(--space-xxxxl);
}

.margin-top-auto {
  margin-top: auto;
}

.margin-bottom-xxxxs {
  margin-bottom: var(--space-xxxxs);
}

.margin-bottom-xxxs {
  margin-bottom: var(--space-xxxs);
}

.margin-bottom-xxs {
  margin-bottom: var(--space-xxs);
}

.margin-bottom-xs {
  margin-bottom: var(--space-xs);
}

.margin-bottom-sm {
  margin-bottom: var(--space-sm);
}

.margin-bottom-md {
  margin-bottom: var(--space-md);
}

.margin-bottom-lg {
  margin-bottom: var(--space-lg);
}

.margin-bottom-xl {
  margin-bottom: var(--space-xl);
}

.margin-bottom-xxl {
  margin-bottom: var(--space-xxl);
}

.margin-bottom-xxxl {
  margin-bottom: var(--space-xxxl);
}

.margin-bottom-xxxxl {
  margin-bottom: var(--space-xxxxl);
}

.margin-bottom-auto {
  margin-bottom: auto;
}

.margin-right-xxxxs {
  margin-right: var(--space-xxxxs);
}

.margin-right-xxxs {
  margin-right: var(--space-xxxs);
}

.margin-right-xxs {
  margin-right: var(--space-xxs);
}

.margin-right-xs {
  margin-right: var(--space-xs);
}

.margin-right-sm {
  margin-right: var(--space-sm);
}

.margin-right-md {
  margin-right: var(--space-md);
}

.margin-right-lg {
  margin-right: var(--space-lg);
}

.margin-right-xl {
  margin-right: var(--space-xl);
}

.margin-right-xxl {
  margin-right: var(--space-xxl);
}

.margin-right-xxxl {
  margin-right: var(--space-xxxl);
}

.margin-right-xxxxl {
  margin-right: var(--space-xxxxl);
}

.margin-right-auto {
  margin-right: auto;
}

.margin-left-xxxxs {
  margin-left: var(--space-xxxxs);
}

.margin-left-xxxs {
  margin-left: var(--space-xxxs);
}

.margin-left-xxs {
  margin-left: var(--space-xxs);
}

.margin-left-xs {
  margin-left: var(--space-xs);
}

.margin-left-sm {
  margin-left: var(--space-sm);
}

.margin-left-md {
  margin-left: var(--space-md);
}

.margin-left-lg {
  margin-left: var(--space-lg);
}

.margin-left-xl {
  margin-left: var(--space-xl);
}

.margin-left-xxl {
  margin-left: var(--space-xxl);
}

.margin-left-xxxl {
  margin-left: var(--space-xxxl);
}

.margin-left-xxxxl {
  margin-left: var(--space-xxxxl);
}

.margin-left-auto {
  margin-left: auto;
}

.margin-x-xxxxs {
  margin-left: var(--space-xxxxs);
  margin-right: var(--space-xxxxs);
}

.margin-x-xxxs {
  margin-left: var(--space-xxxs);
  margin-right: var(--space-xxxs);
}

.margin-x-xxs {
  margin-left: var(--space-xxs);
  margin-right: var(--space-xxs);
}

.margin-x-xs {
  margin-left: var(--space-xs);
  margin-right: var(--space-xs);
}

.margin-x-sm {
  margin-left: var(--space-sm);
  margin-right: var(--space-sm);
}

.margin-x-md {
  margin-left: var(--space-md);
  margin-right: var(--space-md);
}

.margin-x-lg {
  margin-left: var(--space-lg);
  margin-right: var(--space-lg);
}

.margin-x-xl {
  margin-left: var(--space-xl);
  margin-right: var(--space-xl);
}

.margin-x-xxl {
  margin-left: var(--space-xxl);
  margin-right: var(--space-xxl);
}

.margin-x-xxxl {
  margin-left: var(--space-xxxl);
  margin-right: var(--space-xxxl);
}

.margin-x-xxxxl {
  margin-left: var(--space-xxxxl);
  margin-right: var(--space-xxxxl);
}

.margin-x-auto {
  margin-left: auto;
  margin-right: auto;
}

.margin-y-xxxxs {
  margin-top: var(--space-xxxxs);
  margin-bottom: var(--space-xxxxs);
}

.margin-y-xxxs {
  margin-top: var(--space-xxxs);
  margin-bottom: var(--space-xxxs);
}

.margin-y-xxs {
  margin-top: var(--space-xxs);
  margin-bottom: var(--space-xxs);
}

.margin-y-xs {
  margin-top: var(--space-xs);
  margin-bottom: var(--space-xs);
}

.margin-y-sm {
  margin-top: var(--space-sm);
  margin-bottom: var(--space-sm);
}

.margin-y-md {
  margin-top: var(--space-md);
  margin-bottom: var(--space-md);
}

.margin-y-lg {
  margin-top: var(--space-lg);
  margin-bottom: var(--space-lg);
}

.margin-y-xl {
  margin-top: var(--space-xl);
  margin-bottom: var(--space-xl);
}

.margin-y-xxl {
  margin-top: var(--space-xxl);
  margin-bottom: var(--space-xxl);
}

.margin-y-xxxl {
  margin-top: var(--space-xxxl);
  margin-bottom: var(--space-xxxl);
}

.margin-y-xxxxl {
  margin-top: var(--space-xxxxl);
  margin-bottom: var(--space-xxxxl);
}

.margin-y-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.padding-xxxxs {
  padding: var(--space-xxxxs);
}

.padding-xxxs {
  padding: var(--space-xxxs);
}

.padding-xxs {
  padding: var(--space-xxs);
}

.padding-xs {
  padding: var(--space-xs);
}

.padding-sm {
  padding: var(--space-sm);
}

.padding-md {
  padding: var(--space-md);
}

.padding-lg {
  padding: var(--space-lg);
}

.padding-xl {
  padding: var(--space-xl);
}

.padding-xxl {
  padding: var(--space-xxl);
}

.padding-xxxl {
  padding: var(--space-xxxl);
}

.padding-xxxxl {
  padding: var(--space-xxxxl);
}

.padding-component {
  padding: var(--component-padding);
}

.padding-top-xxxxs {
  padding-top: var(--space-xxxxs);
}

.padding-top-xxxs {
  padding-top: var(--space-xxxs);
}

.padding-top-xxs {
  padding-top: var(--space-xxs);
}

.padding-top-xs {
  padding-top: var(--space-xs);
}

.padding-top-sm {
  padding-top: var(--space-sm);
}

.padding-top-md {
  padding-top: var(--space-md);
}

.padding-top-lg {
  padding-top: var(--space-lg);
}

.padding-top-xl {
  padding-top: var(--space-xl);
}

.padding-top-xxl {
  padding-top: var(--space-xxl);
}

.padding-top-xxxl {
  padding-top: var(--space-xxxl);
}

.padding-top-xxxxl {
  padding-top: var(--space-xxxxl);
}

.padding-top-component {
  padding-top: var(--component-padding);
}

.padding-bottom-xxxxs {
  padding-bottom: var(--space-xxxxs);
}

.padding-bottom-xxxs {
  padding-bottom: var(--space-xxxs);
}

.padding-bottom-xxs {
  padding-bottom: var(--space-xxs);
}

.padding-bottom-xs {
  padding-bottom: var(--space-xs);
}

.padding-bottom-sm {
  padding-bottom: var(--space-sm);
}

.padding-bottom-md {
  padding-bottom: var(--space-md);
}

.padding-bottom-lg {
  padding-bottom: var(--space-lg);
}

.padding-bottom-xl {
  padding-bottom: var(--space-xl);
}

.padding-bottom-xxl {
  padding-bottom: var(--space-xxl);
}

.padding-bottom-xxxl {
  padding-bottom: var(--space-xxxl);
}

.padding-bottom-xxxxl {
  padding-bottom: var(--space-xxxxl);
}

.padding-bottom-component {
  padding-bottom: var(--component-padding);
}

.padding-right-xxxxs {
  padding-right: var(--space-xxxxs);
}

.padding-right-xxxs {
  padding-right: var(--space-xxxs);
}

.padding-right-xxs {
  padding-right: var(--space-xxs);
}

.padding-right-xs {
  padding-right: var(--space-xs);
}

.padding-right-sm {
  padding-right: var(--space-sm);
}

.padding-right-md {
  padding-right: var(--space-md);
}

.padding-right-lg {
  padding-right: var(--space-lg);
}

.padding-right-xl {
  padding-right: var(--space-xl);
}

.padding-right-xxl {
  padding-right: var(--space-xxl);
}

.padding-right-xxxl {
  padding-right: var(--space-xxxl);
}

.padding-right-xxxxl {
  padding-right: var(--space-xxxxl);
}

.padding-right-component {
  padding-right: var(--component-padding);
}

.padding-left-xxxxs {
  padding-left: var(--space-xxxxs);
}

.padding-left-xxxs {
  padding-left: var(--space-xxxs);
}

.padding-left-xxs {
  padding-left: var(--space-xxs);
}

.padding-left-xs {
  padding-left: var(--space-xs);
}

.padding-left-sm {
  padding-left: var(--space-sm);
}

.padding-left-md {
  padding-left: var(--space-md);
}

.padding-left-lg {
  padding-left: var(--space-lg);
}

.padding-left-xl {
  padding-left: var(--space-xl);
}

.padding-left-xxl {
  padding-left: var(--space-xxl);
}

.padding-left-xxxl {
  padding-left: var(--space-xxxl);
}

.padding-left-xxxxl {
  padding-left: var(--space-xxxxl);
}

.padding-left-component {
  padding-left: var(--component-padding);
}

.padding-x-xxxxs {
  padding-left: var(--space-xxxxs);
  padding-right: var(--space-xxxxs);
}

.padding-x-xxxs {
  padding-left: var(--space-xxxs);
  padding-right: var(--space-xxxs);
}

.padding-x-xxs {
  padding-left: var(--space-xxs);
  padding-right: var(--space-xxs);
}

.padding-x-xs {
  padding-left: var(--space-xs);
  padding-right: var(--space-xs);
}

.padding-x-sm {
  padding-left: var(--space-sm);
  padding-right: var(--space-sm);
}

.padding-x-md {
  padding-left: var(--space-md);
  padding-right: var(--space-md);
}

.padding-x-lg {
  padding-left: var(--space-lg);
  padding-right: var(--space-lg);
}

.padding-x-xl {
  padding-left: var(--space-xl);
  padding-right: var(--space-xl);
}

.padding-x-xxl {
  padding-left: var(--space-xxl);
  padding-right: var(--space-xxl);
}

.padding-x-xxxl {
  padding-left: var(--space-xxxl);
  padding-right: var(--space-xxxl);
}

.padding-x-xxxxl {
  padding-left: var(--space-xxxxl);
  padding-right: var(--space-xxxxl);
}

.padding-x-component {
  padding-left: var(--component-padding);
  padding-right: var(--component-padding);
}

.padding-y-xxxxs {
  padding-top: var(--space-xxxxs);
  padding-bottom: var(--space-xxxxs);
}

.padding-y-xxxs {
  padding-top: var(--space-xxxs);
  padding-bottom: var(--space-xxxs);
}

.padding-y-xxs {
  padding-top: var(--space-xxs);
  padding-bottom: var(--space-xxs);
}

.padding-y-xs {
  padding-top: var(--space-xs);
  padding-bottom: var(--space-xs);
}

.padding-y-sm {
  padding-top: var(--space-sm);
  padding-bottom: var(--space-sm);
}

.padding-y-md {
  padding-top: var(--space-md);
  padding-bottom: var(--space-md);
}

.padding-y-lg {
  padding-top: var(--space-lg);
  padding-bottom: var(--space-lg);
}

.padding-y-xl {
  padding-top: var(--space-xl);
  padding-bottom: var(--space-xl);
}

.padding-y-xxl {
  padding-top: var(--space-xxl);
  padding-bottom: var(--space-xxl);
}

.padding-y-xxxl {
  padding-top: var(--space-xxxl);
  padding-bottom: var(--space-xxxl);
}

.padding-y-xxxxl {
  padding-top: var(--space-xxxxl);
  padding-bottom: var(--space-xxxxl);
}

.padding-y-component {
  padding-top: var(--component-padding);
  padding-bottom: var(--component-padding);
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.truncate, .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-replace {
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.letter-spacing-sm {
  letter-spacing: -0.05em;
}

.letter-spacing-md {
  letter-spacing: 0.05em;
}

.letter-spacing-lg {
  letter-spacing: 0.1em;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold, .text-bold {
  font-weight: 700;
}

.font-smooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-primary {
  font-family: var(--font-primary);
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-line-through {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

.text-decoration-none {
  text-decoration: none;
}

.line-height-xs {
  --heading-line-height: 1;
  --body-line-height: 1;
}

.line-height-sm {
  --heading-line-height: 1.1;
  --body-line-height: 1.2;
}

.line-height-md {
  --heading-line-height: 1.15;
  --body-line-height: 1.4;
}

.line-height-lg {
  --heading-line-height: 1.22;
  --body-line-height: 1.58;
}

.line-height-xl {
  --heading-line-height: 1.3;
  --body-line-height: 1.72;
}

.v-space-xxs {
  --text-vspace-multiplier: 0.25 !important;
}

.v-space-xs {
  --text-vspace-multiplier: 0.5 !important;
}

.v-space-sm {
  --text-vspace-multiplier: 0.75 !important;
}

.v-space-md {
  --text-vspace-multiplier: 1.25 !important;
}

.v-space-lg {
  --text-vspace-multiplier: 1.5 !important;
}

.v-space-xl {
  --text-vspace-multiplier: 1.75 !important;
}

.v-space-xxl {
  --text-vspace-multiplier: 2 !important;
}

.ws-nowrap, .text-nowrap {
  white-space: nowrap;
}

[class*="color-"] {
  --color-o: 1;
}

.color-inherit {
  color: inherit;
}

.color-bg {
  color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), var(--color-o, 1));
}

.color-contrast-lower {
  color: hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), var(--color-o, 1));
}

.color-contrast-low {
  color: hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--color-o, 1));
}

.color-contrast-medium {
  color: hsla(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l), var(--color-o, 1));
}

.color-contrast-high {
  color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), var(--color-o, 1));
}

.color-contrast-higher {
  color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--color-o, 1));
}

.color-primary {
  color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), var(--color-o, 1));
}

.color-accent {
  color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), var(--color-o, 1));
}

.color-success {
  color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), var(--color-o, 1));
}

.color-warning {
  color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), var(--color-o, 1));
}

.color-error {
  color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), var(--color-o, 1));
}

.color-white {
  color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), var(--color-o, 1));
}

.color-black {
  color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), var(--color-o, 1));
}

@supports (--css: variables) {
  .color-opacity-10\% {
    --color-o: 0.1;
  }
  .color-opacity-20\% {
    --color-o: 0.2;
  }
  .color-opacity-30\% {
    --color-o: 0.3;
  }
  .color-opacity-40\% {
    --color-o: 0.4;
  }
  .color-opacity-50\% {
    --color-o: 0.5;
  }
  .color-opacity-60\% {
    --color-o: 0.6;
  }
  .color-opacity-70\% {
    --color-o: 0.7;
  }
  .color-opacity-80\% {
    --color-o: 0.8;
  }
  .color-opacity-90\% {
    --color-o: 0.9;
  }
}

.width-xxxxs {
  width: 0.25rem;
}

.width-xxxs {
  width: 0.5rem;
}

.width-xxs {
  width: 0.75rem;
}

.width-xs {
  width: 1rem;
}

.width-sm {
  width: 1.5rem;
}

.width-md {
  width: 2rem;
}

.width-lg {
  width: 3rem;
}

.width-xl {
  width: 4rem;
}

.width-xxl {
  width: 6rem;
}

.width-xxxl {
  width: 8rem;
}

.width-xxxxl {
  width: 16rem;
}

.width-10\% {
  width: 10%;
}

.width-20\% {
  width: 20%;
}

.width-25\% {
  width: 25%;
}

.width-30\% {
  width: 30%;
}

.width-33\% {
  width: calc(100% / 3);
}

.width-40\% {
  width: 40%;
}

.width-50\% {
  width: 50%;
}

.width-60\% {
  width: 60%;
}

.width-66\% {
  width: calc(100% / 1.5);
}

.width-70\% {
  width: 70%;
}

.width-75\% {
  width: 75%;
}

.width-80\% {
  width: 80%;
}

.width-90\% {
  width: 90%;
}

.width-100\% {
  width: 100%;
}

.width-100vw {
  width: 100vw;
}

.width-auto {
  width: auto;
}

.height-xxxxs {
  height: 0.25rem;
}

.height-xxxs {
  height: 0.5rem;
}

.height-xxs {
  height: 0.75rem;
}

.height-xs {
  height: 1rem;
}

.height-sm {
  height: 1.5rem;
}

.height-md {
  height: 2rem;
}

.height-lg {
  height: 3rem;
}

.height-xl {
  height: 4rem;
}

.height-xxl {
  height: 6rem;
}

.height-xxxl {
  height: 8rem;
}

.height-xxxxl {
  height: 16rem;
}

.height-10\% {
  height: 10%;
}

.height-20\% {
  height: 20%;
}

.height-25\% {
  height: 25%;
}

.height-30\% {
  height: 30%;
}

.height-33\% {
  height: calc(100% / 3);
}

.height-40\% {
  height: 40%;
}

.height-50\% {
  height: 50%;
}

.height-60\% {
  height: 60%;
}

.height-66\% {
  height: calc(100% / 1.5);
}

.height-70\% {
  height: 70%;
}

.height-75\% {
  height: 75%;
}

.height-80\% {
  height: 80%;
}

.height-90\% {
  height: 90%;
}

.height-100\% {
  height: 100%;
}

.height-100vh {
  height: 100vh;
}

.height-auto {
  height: auto;
}

.min-width-0 {
  min-width: 0;
}

.min-width-25\% {
  min-width: 25%;
}

.min-width-33\% {
  min-width: calc(100% / 3);
}

.min-width-50\% {
  min-width: 50%;
}

.min-width-66\% {
  min-width: calc(100% / 1.5);
}

.min-width-75\% {
  min-width: 75%;
}

.min-width-100\% {
  min-width: 100%;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.zindex-1 {
  z-index: 1;
}

.zindex-2 {
  z-index: 2;
}

.zindex-3 {
  z-index: 3;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.opacity-10\% {
  opacity: 0.1;
}

.opacity-20\% {
  opacity: 0.2;
}

.opacity-30\% {
  opacity: 0.3;
}

.opacity-40\% {
  opacity: 0.4;
}

.opacity-50\% {
  opacity: 0.5;
}

.opacity-60\% {
  opacity: 0.6;
}

.opacity-70\% {
  opacity: 0.7;
}

.opacity-80\% {
  opacity: 0.8;
}

.opacity-90\% {
  opacity: 0.9;
}

.media-wrapper {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
}

.media-wrapper iframe, .media-wrapper video, .media-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.media-wrapper video, .media-wrapper img {
  object-fit: cover;
}

.media-wrapper--4\:3 {
  padding-bottom: calc((3 / 4) * 100%);
}

.media-wrapper--1\:1 {
  padding-bottom: calc((1 / 1) * 100%);
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

[class*="border-"] {
  --border-o: 1;
}

.border {
  border: 1px solid hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--border-o, 1));
}

.border-top {
  border-top: 1px solid hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--border-o, 1));
}

.border-bottom {
  border-bottom: 1px solid hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--border-o, 1));
}

.border-left {
  border-left: 1px solid hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--border-o, 1));
}

.border-right {
  border-right: 1px solid hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--border-o, 1));
}

.border-2 {
  border-width: 2px;
}

.border-3 {
  border-width: 3px;
}

.border-bg {
  border-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), var(--border-o, 1));
}

.border-contrast-lower {
  border-color: hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), var(--border-o, 1));
}

.border-contrast-low {
  border-color: hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--border-o, 1));
}

.border-contrast-medium {
  border-color: hsla(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l), var(--border-o, 1));
}

.border-contrast-high {
  border-color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), var(--border-o, 1));
}

.border-contrast-higher {
  border-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--border-o, 1));
}

.border-primary {
  border-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), var(--border-o, 1));
}

.border-accent {
  border-color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), var(--border-o, 1));
}

.border-success {
  border-color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), var(--border-o, 1));
}

.border-warning {
  border-color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), var(--border-o, 1));
}

.border-error {
  border-color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), var(--border-o, 1));
}

.border-white {
  border-color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), var(--border-o, 1));
}

.border-black {
  border-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), var(--border-o, 1));
}

@supports (--css: variables) {
  .border-opacity-10\% {
    --border-o: 0.1;
  }
  .border-opacity-20\% {
    --border-o: 0.2;
  }
  .border-opacity-30\% {
    --border-o: 0.3;
  }
  .border-opacity-40\% {
    --border-o: 0.4;
  }
  .border-opacity-50\% {
    --border-o: 0.5;
  }
  .border-opacity-60\% {
    --border-o: 0.6;
  }
  .border-opacity-70\% {
    --border-o: 0.7;
  }
  .border-opacity-80\% {
    --border-o: 0.8;
  }
  .border-opacity-90\% {
    --border-o: 0.9;
  }
}

.radius-sm {
  border-radius: var(--radius-sm);
}

.radius-md {
  border-radius: var(--radius-md);
}

.radius-lg {
  border-radius: var(--radius-lg);
}

.radius-50\% {
  border-radius: 50%;
}

.shadow-xs {
  box-shadow: var(--shadow-xs);
}

.shadow-sm {
  box-shadow: var(--shadow-sm);
}

.shadow-md {
  box-shadow: var(--shadow-md);
}

.shadow-lg {
  box-shadow: var(--shadow-lg);
}

.shadow-xl {
  box-shadow: var(--shadow-xl);
}

.bg, [class*="bg-contrast-"], .bg-primary, .bg-accent, .bg-success, .bg-warning, .bg-error, .bg-white, .bg-black {
  --bg-o: 1;
}

.bg-transparent {
  background-color: transparent;
}

.bg-inherit {
  background-color: inherit;
}

.bg {
  background-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), var(--bg-o));
}

.bg-contrast-lower {
  background-color: hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), var(--bg-o, 1));
}

.bg-contrast-low {
  background-color: hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--bg-o, 1));
}

.bg-contrast-medium {
  background-color: hsla(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l), var(--bg-o, 1));
}

.bg-contrast-high {
  background-color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), var(--bg-o, 1));
}

.bg-contrast-higher {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--bg-o, 1));
}

.bg-primary {
  background-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), var(--bg-o));
}

.bg-accent {
  background-color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), var(--bg-o));
}

.bg-success {
  background-color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), var(--bg-o));
}

.bg-warning {
  background-color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), var(--bg-o));
}

.bg-error {
  background-color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), var(--bg-o));
}

.bg-white {
  background-color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), var(--bg-o));
}

.bg-black {
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), var(--bg-o));
}

@supports (--css: variables) {
  .bg-opacity-10\% {
    --bg-o: 0.1;
  }
  .bg-opacity-20\% {
    --bg-o: 0.2;
  }
  .bg-opacity-30\% {
    --bg-o: 0.3;
  }
  .bg-opacity-40\% {
    --bg-o: 0.4;
  }
  .bg-opacity-50\% {
    --bg-o: 0.5;
  }
  .bg-opacity-60\% {
    --bg-o: 0.6;
  }
  .bg-opacity-70\% {
    --bg-o: 0.7;
  }
  .bg-opacity-80\% {
    --bg-o: 0.8;
  }
  .bg-opacity-90\% {
    --bg-o: 0.9;
  }
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

@media (min-width: 32rem) {
  .flex\@xs {
    display: flex;
  }
  .inline-flex\@xs {
    display: inline-flex;
  }
  .flex-wrap\@xs {
    flex-wrap: wrap;
  }
  .flex-column\@xs {
    flex-direction: column;
  }
  .flex-column-reverse\@xs {
    flex-direction: column-reverse;
  }
  .flex-row\@xs {
    flex-direction: row;
  }
  .flex-row-reverse\@xs {
    flex-direction: row-reverse;
  }
  .flex-center\@xs {
    justify-content: center;
    align-items: center;
  }
  .flex-grow-0\@xs {
    flex-grow: 0;
  }
  .flex-shrink\@xs {
    flex-shrink: 1;
  }
  .justify-start\@xs {
    justify-content: flex-start;
  }
  .justify-end\@xs {
    justify-content: flex-end;
  }
  .justify-center\@xs {
    justify-content: center;
  }
  .justify-between\@xs {
    justify-content: space-between;
  }
  .items-center\@xs {
    align-items: center;
  }
  .items-start\@xs {
    align-items: flex-start;
  }
  .items-end\@xs {
    align-items: flex-end;
  }
  .order-1\@xs {
    order: 1;
  }
  .order-2\@xs {
    order: 2;
  }
  .order-3\@xs {
    order: 3;
  }
  .block\@xs {
    display: block;
  }
  .inline-block\@xs {
    display: inline-block;
  }
  .inline\@xs {
    display: inline;
  }
  @supports (--css: variables) {
    .margin-xxxxs\@xs {
      margin: var(--space-xxxxs);
    }
    .margin-xxxs\@xs {
      margin: var(--space-xxxs);
    }
    .margin-xxs\@xs {
      margin: var(--space-xxs);
    }
    .margin-xs\@xs {
      margin: var(--space-xs);
    }
    .margin-sm\@xs {
      margin: var(--space-sm);
    }
    .margin-md\@xs {
      margin: var(--space-md);
    }
    .margin-lg\@xs {
      margin: var(--space-lg);
    }
    .margin-xl\@xs {
      margin: var(--space-xl);
    }
    .margin-xxl\@xs {
      margin: var(--space-xxl);
    }
    .margin-xxxl\@xs {
      margin: var(--space-xxxl);
    }
    .margin-xxxxl\@xs {
      margin: var(--space-xxxxl);
    }
    .margin-auto\@xs {
      margin: auto;
    }
    .margin-0\@xs {
      margin: 0;
    }
    .margin-top-xxxxs\@xs {
      margin-top: var(--space-xxxxs);
    }
    .margin-top-xxxs\@xs {
      margin-top: var(--space-xxxs);
    }
    .margin-top-xxs\@xs {
      margin-top: var(--space-xxs);
    }
    .margin-top-xs\@xs {
      margin-top: var(--space-xs);
    }
    .margin-top-sm\@xs {
      margin-top: var(--space-sm);
    }
    .margin-top-md\@xs {
      margin-top: var(--space-md);
    }
    .margin-top-lg\@xs {
      margin-top: var(--space-lg);
    }
    .margin-top-xl\@xs {
      margin-top: var(--space-xl);
    }
    .margin-top-xxl\@xs {
      margin-top: var(--space-xxl);
    }
    .margin-top-xxxl\@xs {
      margin-top: var(--space-xxxl);
    }
    .margin-top-xxxxl\@xs {
      margin-top: var(--space-xxxxl);
    }
    .margin-top-auto\@xs {
      margin-top: auto;
    }
    .margin-top-0\@xs {
      margin-top: 0;
    }
    .margin-bottom-xxxxs\@xs {
      margin-bottom: var(--space-xxxxs);
    }
    .margin-bottom-xxxs\@xs {
      margin-bottom: var(--space-xxxs);
    }
    .margin-bottom-xxs\@xs {
      margin-bottom: var(--space-xxs);
    }
    .margin-bottom-xs\@xs {
      margin-bottom: var(--space-xs);
    }
    .margin-bottom-sm\@xs {
      margin-bottom: var(--space-sm);
    }
    .margin-bottom-md\@xs {
      margin-bottom: var(--space-md);
    }
    .margin-bottom-lg\@xs {
      margin-bottom: var(--space-lg);
    }
    .margin-bottom-xl\@xs {
      margin-bottom: var(--space-xl);
    }
    .margin-bottom-xxl\@xs {
      margin-bottom: var(--space-xxl);
    }
    .margin-bottom-xxxl\@xs {
      margin-bottom: var(--space-xxxl);
    }
    .margin-bottom-xxxxl\@xs {
      margin-bottom: var(--space-xxxxl);
    }
    .margin-bottom-auto\@xs {
      margin-bottom: auto;
    }
    .margin-bottom-0\@xs {
      margin-bottom: 0;
    }
    .margin-right-xxxxs\@xs {
      margin-right: var(--space-xxxxs);
    }
    .margin-right-xxxs\@xs {
      margin-right: var(--space-xxxs);
    }
    .margin-right-xxs\@xs {
      margin-right: var(--space-xxs);
    }
    .margin-right-xs\@xs {
      margin-right: var(--space-xs);
    }
    .margin-right-sm\@xs {
      margin-right: var(--space-sm);
    }
    .margin-right-md\@xs {
      margin-right: var(--space-md);
    }
    .margin-right-lg\@xs {
      margin-right: var(--space-lg);
    }
    .margin-right-xl\@xs {
      margin-right: var(--space-xl);
    }
    .margin-right-xxl\@xs {
      margin-right: var(--space-xxl);
    }
    .margin-right-xxxl\@xs {
      margin-right: var(--space-xxxl);
    }
    .margin-right-xxxxl\@xs {
      margin-right: var(--space-xxxxl);
    }
    .margin-right-auto\@xs {
      margin-right: auto;
    }
    .margin-right-0\@xs {
      margin-right: 0;
    }
    .margin-left-xxxxs\@xs {
      margin-left: var(--space-xxxxs);
    }
    .margin-left-xxxs\@xs {
      margin-left: var(--space-xxxs);
    }
    .margin-left-xxs\@xs {
      margin-left: var(--space-xxs);
    }
    .margin-left-xs\@xs {
      margin-left: var(--space-xs);
    }
    .margin-left-sm\@xs {
      margin-left: var(--space-sm);
    }
    .margin-left-md\@xs {
      margin-left: var(--space-md);
    }
    .margin-left-lg\@xs {
      margin-left: var(--space-lg);
    }
    .margin-left-xl\@xs {
      margin-left: var(--space-xl);
    }
    .margin-left-xxl\@xs {
      margin-left: var(--space-xxl);
    }
    .margin-left-xxxl\@xs {
      margin-left: var(--space-xxxl);
    }
    .margin-left-xxxxl\@xs {
      margin-left: var(--space-xxxxl);
    }
    .margin-left-auto\@xs {
      margin-left: auto;
    }
    .margin-left-0\@xs {
      margin-left: 0;
    }
    .margin-x-xxxxs\@xs {
      margin-left: var(--space-xxxxs);
      margin-right: var(--space-xxxxs);
    }
    .margin-x-xxxs\@xs {
      margin-left: var(--space-xxxs);
      margin-right: var(--space-xxxs);
    }
    .margin-x-xxs\@xs {
      margin-left: var(--space-xxs);
      margin-right: var(--space-xxs);
    }
    .margin-x-xs\@xs {
      margin-left: var(--space-xs);
      margin-right: var(--space-xs);
    }
    .margin-x-sm\@xs {
      margin-left: var(--space-sm);
      margin-right: var(--space-sm);
    }
    .margin-x-md\@xs {
      margin-left: var(--space-md);
      margin-right: var(--space-md);
    }
    .margin-x-lg\@xs {
      margin-left: var(--space-lg);
      margin-right: var(--space-lg);
    }
    .margin-x-xl\@xs {
      margin-left: var(--space-xl);
      margin-right: var(--space-xl);
    }
    .margin-x-xxl\@xs {
      margin-left: var(--space-xxl);
      margin-right: var(--space-xxl);
    }
    .margin-x-xxxl\@xs {
      margin-left: var(--space-xxxl);
      margin-right: var(--space-xxxl);
    }
    .margin-x-xxxxl\@xs {
      margin-left: var(--space-xxxxl);
      margin-right: var(--space-xxxxl);
    }
    .margin-x-auto\@xs {
      margin-left: auto;
      margin-right: auto;
    }
    .margin-x-0\@xs {
      margin-left: 0;
      margin-right: 0;
    }
    .margin-y-xxxxs\@xs {
      margin-top: var(--space-xxxxs);
      margin-bottom: var(--space-xxxxs);
    }
    .margin-y-xxxs\@xs {
      margin-top: var(--space-xxxs);
      margin-bottom: var(--space-xxxs);
    }
    .margin-y-xxs\@xs {
      margin-top: var(--space-xxs);
      margin-bottom: var(--space-xxs);
    }
    .margin-y-xs\@xs {
      margin-top: var(--space-xs);
      margin-bottom: var(--space-xs);
    }
    .margin-y-sm\@xs {
      margin-top: var(--space-sm);
      margin-bottom: var(--space-sm);
    }
    .margin-y-md\@xs {
      margin-top: var(--space-md);
      margin-bottom: var(--space-md);
    }
    .margin-y-lg\@xs {
      margin-top: var(--space-lg);
      margin-bottom: var(--space-lg);
    }
    .margin-y-xl\@xs {
      margin-top: var(--space-xl);
      margin-bottom: var(--space-xl);
    }
    .margin-y-xxl\@xs {
      margin-top: var(--space-xxl);
      margin-bottom: var(--space-xxl);
    }
    .margin-y-xxxl\@xs {
      margin-top: var(--space-xxxl);
      margin-bottom: var(--space-xxxl);
    }
    .margin-y-xxxxl\@xs {
      margin-top: var(--space-xxxxl);
      margin-bottom: var(--space-xxxxl);
    }
    .margin-y-auto\@xs {
      margin-top: auto;
      margin-bottom: auto;
    }
    .margin-y-0\@xs {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  @supports (--css: variables) {
    .padding-xxxxs\@xs {
      padding: var(--space-xxxxs);
    }
    .padding-xxxs\@xs {
      padding: var(--space-xxxs);
    }
    .padding-xxs\@xs {
      padding: var(--space-xxs);
    }
    .padding-xs\@xs {
      padding: var(--space-xs);
    }
    .padding-sm\@xs {
      padding: var(--space-sm);
    }
    .padding-md\@xs {
      padding: var(--space-md);
    }
    .padding-lg\@xs {
      padding: var(--space-lg);
    }
    .padding-xl\@xs {
      padding: var(--space-xl);
    }
    .padding-xxl\@xs {
      padding: var(--space-xxl);
    }
    .padding-xxxl\@xs {
      padding: var(--space-xxxl);
    }
    .padding-xxxxl\@xs {
      padding: var(--space-xxxxl);
    }
    .padding-0\@xs {
      padding: 0;
    }
    .padding-component\@xs {
      padding: var(--component-padding);
    }
    .padding-top-xxxxs\@xs {
      padding-top: var(--space-xxxxs);
    }
    .padding-top-xxxs\@xs {
      padding-top: var(--space-xxxs);
    }
    .padding-top-xxs\@xs {
      padding-top: var(--space-xxs);
    }
    .padding-top-xs\@xs {
      padding-top: var(--space-xs);
    }
    .padding-top-sm\@xs {
      padding-top: var(--space-sm);
    }
    .padding-top-md\@xs {
      padding-top: var(--space-md);
    }
    .padding-top-lg\@xs {
      padding-top: var(--space-lg);
    }
    .padding-top-xl\@xs {
      padding-top: var(--space-xl);
    }
    .padding-top-xxl\@xs {
      padding-top: var(--space-xxl);
    }
    .padding-top-xxxl\@xs {
      padding-top: var(--space-xxxl);
    }
    .padding-top-xxxxl\@xs {
      padding-top: var(--space-xxxxl);
    }
    .padding-top-0\@xs {
      padding-top: 0;
    }
    .padding-top-component\@xs {
      padding-top: var(--component-padding);
    }
    .padding-bottom-xxxxs\@xs {
      padding-bottom: var(--space-xxxxs);
    }
    .padding-bottom-xxxs\@xs {
      padding-bottom: var(--space-xxxs);
    }
    .padding-bottom-xxs\@xs {
      padding-bottom: var(--space-xxs);
    }
    .padding-bottom-xs\@xs {
      padding-bottom: var(--space-xs);
    }
    .padding-bottom-sm\@xs {
      padding-bottom: var(--space-sm);
    }
    .padding-bottom-md\@xs {
      padding-bottom: var(--space-md);
    }
    .padding-bottom-lg\@xs {
      padding-bottom: var(--space-lg);
    }
    .padding-bottom-xl\@xs {
      padding-bottom: var(--space-xl);
    }
    .padding-bottom-xxl\@xs {
      padding-bottom: var(--space-xxl);
    }
    .padding-bottom-xxxl\@xs {
      padding-bottom: var(--space-xxxl);
    }
    .padding-bottom-xxxxl\@xs {
      padding-bottom: var(--space-xxxxl);
    }
    .padding-bottom-0\@xs {
      padding-bottom: 0;
    }
    .padding-bottom-component\@xs {
      padding-bottom: var(--component-padding);
    }
    .padding-right-xxxxs\@xs {
      padding-right: var(--space-xxxxs);
    }
    .padding-right-xxxs\@xs {
      padding-right: var(--space-xxxs);
    }
    .padding-right-xxs\@xs {
      padding-right: var(--space-xxs);
    }
    .padding-right-xs\@xs {
      padding-right: var(--space-xs);
    }
    .padding-right-sm\@xs {
      padding-right: var(--space-sm);
    }
    .padding-right-md\@xs {
      padding-right: var(--space-md);
    }
    .padding-right-lg\@xs {
      padding-right: var(--space-lg);
    }
    .padding-right-xl\@xs {
      padding-right: var(--space-xl);
    }
    .padding-right-xxl\@xs {
      padding-right: var(--space-xxl);
    }
    .padding-right-xxxl\@xs {
      padding-right: var(--space-xxxl);
    }
    .padding-right-xxxxl\@xs {
      padding-right: var(--space-xxxxl);
    }
    .padding-right-0\@xs {
      padding-right: 0;
    }
    .padding-right-component\@xs {
      padding-right: var(--component-padding);
    }
    .padding-left-xxxxs\@xs {
      padding-left: var(--space-xxxxs);
    }
    .padding-left-xxxs\@xs {
      padding-left: var(--space-xxxs);
    }
    .padding-left-xxs\@xs {
      padding-left: var(--space-xxs);
    }
    .padding-left-xs\@xs {
      padding-left: var(--space-xs);
    }
    .padding-left-sm\@xs {
      padding-left: var(--space-sm);
    }
    .padding-left-md\@xs {
      padding-left: var(--space-md);
    }
    .padding-left-lg\@xs {
      padding-left: var(--space-lg);
    }
    .padding-left-xl\@xs {
      padding-left: var(--space-xl);
    }
    .padding-left-xxl\@xs {
      padding-left: var(--space-xxl);
    }
    .padding-left-xxxl\@xs {
      padding-left: var(--space-xxxl);
    }
    .padding-left-xxxxl\@xs {
      padding-left: var(--space-xxxxl);
    }
    .padding-left-0\@xs {
      padding-left: 0;
    }
    .padding-left-component\@xs {
      padding-left: var(--component-padding);
    }
    .padding-x-xxxxs\@xs {
      padding-left: var(--space-xxxxs);
      padding-right: var(--space-xxxxs);
    }
    .padding-x-xxxs\@xs {
      padding-left: var(--space-xxxs);
      padding-right: var(--space-xxxs);
    }
    .padding-x-xxs\@xs {
      padding-left: var(--space-xxs);
      padding-right: var(--space-xxs);
    }
    .padding-x-xs\@xs {
      padding-left: var(--space-xs);
      padding-right: var(--space-xs);
    }
    .padding-x-sm\@xs {
      padding-left: var(--space-sm);
      padding-right: var(--space-sm);
    }
    .padding-x-md\@xs {
      padding-left: var(--space-md);
      padding-right: var(--space-md);
    }
    .padding-x-lg\@xs {
      padding-left: var(--space-lg);
      padding-right: var(--space-lg);
    }
    .padding-x-xl\@xs {
      padding-left: var(--space-xl);
      padding-right: var(--space-xl);
    }
    .padding-x-xxl\@xs {
      padding-left: var(--space-xxl);
      padding-right: var(--space-xxl);
    }
    .padding-x-xxxl\@xs {
      padding-left: var(--space-xxxl);
      padding-right: var(--space-xxxl);
    }
    .padding-x-xxxxl\@xs {
      padding-left: var(--space-xxxxl);
      padding-right: var(--space-xxxxl);
    }
    .padding-x-0\@xs {
      padding-left: 0;
      padding-right: 0;
    }
    .padding-x-component\@xs {
      padding-left: var(--component-padding);
      padding-right: var(--component-padding);
    }
    .padding-y-xxxxs\@xs {
      padding-top: var(--space-xxxxs);
      padding-bottom: var(--space-xxxxs);
    }
    .padding-y-xxxs\@xs {
      padding-top: var(--space-xxxs);
      padding-bottom: var(--space-xxxs);
    }
    .padding-y-xxs\@xs {
      padding-top: var(--space-xxs);
      padding-bottom: var(--space-xxs);
    }
    .padding-y-xs\@xs {
      padding-top: var(--space-xs);
      padding-bottom: var(--space-xs);
    }
    .padding-y-sm\@xs {
      padding-top: var(--space-sm);
      padding-bottom: var(--space-sm);
    }
    .padding-y-md\@xs {
      padding-top: var(--space-md);
      padding-bottom: var(--space-md);
    }
    .padding-y-lg\@xs {
      padding-top: var(--space-lg);
      padding-bottom: var(--space-lg);
    }
    .padding-y-xl\@xs {
      padding-top: var(--space-xl);
      padding-bottom: var(--space-xl);
    }
    .padding-y-xxl\@xs {
      padding-top: var(--space-xxl);
      padding-bottom: var(--space-xxl);
    }
    .padding-y-xxxl\@xs {
      padding-top: var(--space-xxxl);
      padding-bottom: var(--space-xxxl);
    }
    .padding-y-xxxxl\@xs {
      padding-top: var(--space-xxxxl);
      padding-bottom: var(--space-xxxxl);
    }
    .padding-y-0\@xs {
      padding-top: 0;
      padding-bottom: 0;
    }
    .padding-y-component\@xs {
      padding-top: var(--component-padding);
      padding-bottom: var(--component-padding);
    }
  }
  .text-center\@xs {
    text-align: center;
  }
  .text-left\@xs {
    text-align: left;
  }
  .text-right\@xs {
    text-align: right;
  }
  @supports (--css: variables) {
    .text-xs\@xs {
      font-size: var(--text-xs, 0.694em);
    }
    .text-sm\@xs {
      font-size: var(--text-sm, 0.833em);
    }
    .text-base\@xs {
      font-size: 1em;
    }
    .text-md\@xs {
      font-size: var(--text-md, 1.2em);
    }
    .text-lg\@xs {
      font-size: var(--text-lg, 1.44em);
    }
    .text-xl\@xs {
      font-size: var(--text-xl, 1.728em);
    }
    .text-xxl\@xs {
      font-size: var(--text-xxl, 2.074em);
    }
    .text-xxxl\@xs {
      font-size: var(--text-xxxl, 2.488em);
    }
  }
  .hide\@xs {
    display: none !important;
  }
}

@media (min-width: 48rem) {
  .flex\@sm {
    display: flex;
  }
  .inline-flex\@sm {
    display: inline-flex;
  }
  .flex-wrap\@sm {
    flex-wrap: wrap;
  }
  .flex-column\@sm {
    flex-direction: column;
  }
  .flex-column-reverse\@sm {
    flex-direction: column-reverse;
  }
  .flex-row\@sm {
    flex-direction: row;
  }
  .flex-row-reverse\@sm {
    flex-direction: row-reverse;
  }
  .flex-center\@sm {
    justify-content: center;
    align-items: center;
  }
  .flex-grow-0\@sm {
    flex-grow: 0;
  }
  .flex-grow\@sm {
    flex-grow: 1;
  }
  .flex-shrink-0\@sm {
    flex-shrink: 0;
  }
  .flex-shrink\@sm {
    flex-shrink: 1;
  }
  .justify-start\@sm {
    justify-content: flex-start;
  }
  .justify-end\@sm {
    justify-content: flex-end;
  }
  .justify-center\@sm {
    justify-content: center;
  }
  .justify-between\@sm {
    justify-content: space-between;
  }
  .items-center\@sm {
    align-items: center;
  }
  .items-start\@sm {
    align-items: flex-start;
  }
  .items-end\@sm {
    align-items: flex-end;
  }
  .order-1\@sm {
    order: 1;
  }
  .order-2\@sm {
    order: 2;
  }
  .order-3\@sm {
    order: 3;
  }
  .block\@sm {
    display: block;
  }
  .inline-block\@sm {
    display: inline-block;
  }
  .inline\@sm {
    display: inline;
  }
  @supports (--css: variables) {
    .margin-xxxxs\@sm {
      margin: var(--space-xxxxs);
    }
    .margin-xxxs\@sm {
      margin: var(--space-xxxs);
    }
    .margin-xxs\@sm {
      margin: var(--space-xxs);
    }
    .margin-xs\@sm {
      margin: var(--space-xs);
    }
    .margin-sm\@sm {
      margin: var(--space-sm);
    }
    .margin-md\@sm {
      margin: var(--space-md);
    }
    .margin-lg\@sm {
      margin: var(--space-lg);
    }
    .margin-xl\@sm {
      margin: var(--space-xl);
    }
    .margin-xxl\@sm {
      margin: var(--space-xxl);
    }
    .margin-xxxl\@sm {
      margin: var(--space-xxxl);
    }
    .margin-xxxxl\@sm {
      margin: var(--space-xxxxl);
    }
    .margin-auto\@sm {
      margin: auto;
    }
    .margin-0\@sm {
      margin: 0;
    }
    .margin-top-xxxxs\@sm {
      margin-top: var(--space-xxxxs);
    }
    .margin-top-xxxs\@sm {
      margin-top: var(--space-xxxs);
    }
    .margin-top-xxs\@sm {
      margin-top: var(--space-xxs);
    }
    .margin-top-xs\@sm {
      margin-top: var(--space-xs);
    }
    .margin-top-sm\@sm {
      margin-top: var(--space-sm);
    }
    .margin-top-md\@sm {
      margin-top: var(--space-md);
    }
    .margin-top-lg\@sm {
      margin-top: var(--space-lg);
    }
    .margin-top-xl\@sm {
      margin-top: var(--space-xl);
    }
    .margin-top-xxl\@sm {
      margin-top: var(--space-xxl);
    }
    .margin-top-xxxl\@sm {
      margin-top: var(--space-xxxl);
    }
    .margin-top-xxxxl\@sm {
      margin-top: var(--space-xxxxl);
    }
    .margin-top-auto\@sm {
      margin-top: auto;
    }
    .margin-top-0\@sm {
      margin-top: 0;
    }
    .margin-bottom-xxxxs\@sm {
      margin-bottom: var(--space-xxxxs);
    }
    .margin-bottom-xxxs\@sm {
      margin-bottom: var(--space-xxxs);
    }
    .margin-bottom-xxs\@sm {
      margin-bottom: var(--space-xxs);
    }
    .margin-bottom-xs\@sm {
      margin-bottom: var(--space-xs);
    }
    .margin-bottom-sm\@sm {
      margin-bottom: var(--space-sm);
    }
    .margin-bottom-md\@sm {
      margin-bottom: var(--space-md);
    }
    .margin-bottom-lg\@sm {
      margin-bottom: var(--space-lg);
    }
    .margin-bottom-xl\@sm {
      margin-bottom: var(--space-xl);
    }
    .margin-bottom-xxl\@sm {
      margin-bottom: var(--space-xxl);
    }
    .margin-bottom-xxxl\@sm {
      margin-bottom: var(--space-xxxl);
    }
    .margin-bottom-xxxxl\@sm {
      margin-bottom: var(--space-xxxxl);
    }
    .margin-bottom-auto\@sm {
      margin-bottom: auto;
    }
    .margin-bottom-0\@sm {
      margin-bottom: 0;
    }
    .margin-right-xxxxs\@sm {
      margin-right: var(--space-xxxxs);
    }
    .margin-right-xxxs\@sm {
      margin-right: var(--space-xxxs);
    }
    .margin-right-xxs\@sm {
      margin-right: var(--space-xxs);
    }
    .margin-right-xs\@sm {
      margin-right: var(--space-xs);
    }
    .margin-right-sm\@sm {
      margin-right: var(--space-sm);
    }
    .margin-right-md\@sm {
      margin-right: var(--space-md);
    }
    .margin-right-lg\@sm {
      margin-right: var(--space-lg);
    }
    .margin-right-xl\@sm {
      margin-right: var(--space-xl);
    }
    .margin-right-xxl\@sm {
      margin-right: var(--space-xxl);
    }
    .margin-right-xxxl\@sm {
      margin-right: var(--space-xxxl);
    }
    .margin-right-xxxxl\@sm {
      margin-right: var(--space-xxxxl);
    }
    .margin-right-auto\@sm {
      margin-right: auto;
    }
    .margin-right-0\@sm {
      margin-right: 0;
    }
    .margin-left-xxxxs\@sm {
      margin-left: var(--space-xxxxs);
    }
    .margin-left-xxxs\@sm {
      margin-left: var(--space-xxxs);
    }
    .margin-left-xxs\@sm {
      margin-left: var(--space-xxs);
    }
    .margin-left-xs\@sm {
      margin-left: var(--space-xs);
    }
    .margin-left-sm\@sm {
      margin-left: var(--space-sm);
    }
    .margin-left-md\@sm {
      margin-left: var(--space-md);
    }
    .margin-left-lg\@sm {
      margin-left: var(--space-lg);
    }
    .margin-left-xl\@sm {
      margin-left: var(--space-xl);
    }
    .margin-left-xxl\@sm {
      margin-left: var(--space-xxl);
    }
    .margin-left-xxxl\@sm {
      margin-left: var(--space-xxxl);
    }
    .margin-left-xxxxl\@sm {
      margin-left: var(--space-xxxxl);
    }
    .margin-left-auto\@sm {
      margin-left: auto;
    }
    .margin-left-0\@sm {
      margin-left: 0;
    }
    .margin-x-xxxxs\@sm {
      margin-left: var(--space-xxxxs);
      margin-right: var(--space-xxxxs);
    }
    .margin-x-xxxs\@sm {
      margin-left: var(--space-xxxs);
      margin-right: var(--space-xxxs);
    }
    .margin-x-xxs\@sm {
      margin-left: var(--space-xxs);
      margin-right: var(--space-xxs);
    }
    .margin-x-xs\@sm {
      margin-left: var(--space-xs);
      margin-right: var(--space-xs);
    }
    .margin-x-sm\@sm {
      margin-left: var(--space-sm);
      margin-right: var(--space-sm);
    }
    .margin-x-md\@sm {
      margin-left: var(--space-md);
      margin-right: var(--space-md);
    }
    .margin-x-lg\@sm {
      margin-left: var(--space-lg);
      margin-right: var(--space-lg);
    }
    .margin-x-xl\@sm {
      margin-left: var(--space-xl);
      margin-right: var(--space-xl);
    }
    .margin-x-xxl\@sm {
      margin-left: var(--space-xxl);
      margin-right: var(--space-xxl);
    }
    .margin-x-xxxl\@sm {
      margin-left: var(--space-xxxl);
      margin-right: var(--space-xxxl);
    }
    .margin-x-xxxxl\@sm {
      margin-left: var(--space-xxxxl);
      margin-right: var(--space-xxxxl);
    }
    .margin-x-auto\@sm {
      margin-left: auto;
      margin-right: auto;
    }
    .margin-x-0\@sm {
      margin-left: 0;
      margin-right: 0;
    }
    .margin-y-xxxxs\@sm {
      margin-top: var(--space-xxxxs);
      margin-bottom: var(--space-xxxxs);
    }
    .margin-y-xxxs\@sm {
      margin-top: var(--space-xxxs);
      margin-bottom: var(--space-xxxs);
    }
    .margin-y-xxs\@sm {
      margin-top: var(--space-xxs);
      margin-bottom: var(--space-xxs);
    }
    .margin-y-xs\@sm {
      margin-top: var(--space-xs);
      margin-bottom: var(--space-xs);
    }
    .margin-y-sm\@sm {
      margin-top: var(--space-sm);
      margin-bottom: var(--space-sm);
    }
    .margin-y-md\@sm {
      margin-top: var(--space-md);
      margin-bottom: var(--space-md);
    }
    .margin-y-lg\@sm {
      margin-top: var(--space-lg);
      margin-bottom: var(--space-lg);
    }
    .margin-y-xl\@sm {
      margin-top: var(--space-xl);
      margin-bottom: var(--space-xl);
    }
    .margin-y-xxl\@sm {
      margin-top: var(--space-xxl);
      margin-bottom: var(--space-xxl);
    }
    .margin-y-xxxl\@sm {
      margin-top: var(--space-xxxl);
      margin-bottom: var(--space-xxxl);
    }
    .margin-y-xxxxl\@sm {
      margin-top: var(--space-xxxxl);
      margin-bottom: var(--space-xxxxl);
    }
    .margin-y-auto\@sm {
      margin-top: auto;
      margin-bottom: auto;
    }
    .margin-y-0\@sm {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  @supports (--css: variables) {
    .padding-xxxxs\@sm {
      padding: var(--space-xxxxs);
    }
    .padding-xxxs\@sm {
      padding: var(--space-xxxs);
    }
    .padding-xxs\@sm {
      padding: var(--space-xxs);
    }
    .padding-xs\@sm {
      padding: var(--space-xs);
    }
    .padding-sm\@sm {
      padding: var(--space-sm);
    }
    .padding-md\@sm {
      padding: var(--space-md);
    }
    .padding-lg\@sm {
      padding: var(--space-lg);
    }
    .padding-xl\@sm {
      padding: var(--space-xl);
    }
    .padding-xxl\@sm {
      padding: var(--space-xxl);
    }
    .padding-xxxl\@sm {
      padding: var(--space-xxxl);
    }
    .padding-xxxxl\@sm {
      padding: var(--space-xxxxl);
    }
    .padding-0\@sm {
      padding: 0;
    }
    .padding-component\@sm {
      padding: var(--component-padding);
    }
    .padding-top-xxxxs\@sm {
      padding-top: var(--space-xxxxs);
    }
    .padding-top-xxxs\@sm {
      padding-top: var(--space-xxxs);
    }
    .padding-top-xxs\@sm {
      padding-top: var(--space-xxs);
    }
    .padding-top-xs\@sm {
      padding-top: var(--space-xs);
    }
    .padding-top-sm\@sm {
      padding-top: var(--space-sm);
    }
    .padding-top-md\@sm {
      padding-top: var(--space-md);
    }
    .padding-top-lg\@sm {
      padding-top: var(--space-lg);
    }
    .padding-top-xl\@sm {
      padding-top: var(--space-xl);
    }
    .padding-top-xxl\@sm {
      padding-top: var(--space-xxl);
    }
    .padding-top-xxxl\@sm {
      padding-top: var(--space-xxxl);
    }
    .padding-top-xxxxl\@sm {
      padding-top: var(--space-xxxxl);
    }
    .padding-top-0\@sm {
      padding-top: 0;
    }
    .padding-top-component\@sm {
      padding-top: var(--component-padding);
    }
    .padding-bottom-xxxxs\@sm {
      padding-bottom: var(--space-xxxxs);
    }
    .padding-bottom-xxxs\@sm {
      padding-bottom: var(--space-xxxs);
    }
    .padding-bottom-xxs\@sm {
      padding-bottom: var(--space-xxs);
    }
    .padding-bottom-xs\@sm {
      padding-bottom: var(--space-xs);
    }
    .padding-bottom-sm\@sm {
      padding-bottom: var(--space-sm);
    }
    .padding-bottom-md\@sm {
      padding-bottom: var(--space-md);
    }
    .padding-bottom-lg\@sm {
      padding-bottom: var(--space-lg);
    }
    .padding-bottom-xl\@sm {
      padding-bottom: var(--space-xl);
    }
    .padding-bottom-xxl\@sm {
      padding-bottom: var(--space-xxl);
    }
    .padding-bottom-xxxl\@sm {
      padding-bottom: var(--space-xxxl);
    }
    .padding-bottom-xxxxl\@sm {
      padding-bottom: var(--space-xxxxl);
    }
    .padding-bottom-0\@sm {
      padding-bottom: 0;
    }
    .padding-bottom-component\@sm {
      padding-bottom: var(--component-padding);
    }
    .padding-right-xxxxs\@sm {
      padding-right: var(--space-xxxxs);
    }
    .padding-right-xxxs\@sm {
      padding-right: var(--space-xxxs);
    }
    .padding-right-xxs\@sm {
      padding-right: var(--space-xxs);
    }
    .padding-right-xs\@sm {
      padding-right: var(--space-xs);
    }
    .padding-right-sm\@sm {
      padding-right: var(--space-sm);
    }
    .padding-right-md\@sm {
      padding-right: var(--space-md);
    }
    .padding-right-lg\@sm {
      padding-right: var(--space-lg);
    }
    .padding-right-xl\@sm {
      padding-right: var(--space-xl);
    }
    .padding-right-xxl\@sm {
      padding-right: var(--space-xxl);
    }
    .padding-right-xxxl\@sm {
      padding-right: var(--space-xxxl);
    }
    .padding-right-xxxxl\@sm {
      padding-right: var(--space-xxxxl);
    }
    .padding-right-0\@sm {
      padding-right: 0;
    }
    .padding-right-component\@sm {
      padding-right: var(--component-padding);
    }
    .padding-left-xxxxs\@sm {
      padding-left: var(--space-xxxxs);
    }
    .padding-left-xxxs\@sm {
      padding-left: var(--space-xxxs);
    }
    .padding-left-xxs\@sm {
      padding-left: var(--space-xxs);
    }
    .padding-left-xs\@sm {
      padding-left: var(--space-xs);
    }
    .padding-left-sm\@sm {
      padding-left: var(--space-sm);
    }
    .padding-left-md\@sm {
      padding-left: var(--space-md);
    }
    .padding-left-lg\@sm {
      padding-left: var(--space-lg);
    }
    .padding-left-xl\@sm {
      padding-left: var(--space-xl);
    }
    .padding-left-xxl\@sm {
      padding-left: var(--space-xxl);
    }
    .padding-left-xxxl\@sm {
      padding-left: var(--space-xxxl);
    }
    .padding-left-xxxxl\@sm {
      padding-left: var(--space-xxxxl);
    }
    .padding-left-0\@sm {
      padding-left: 0;
    }
    .padding-left-component\@sm {
      padding-left: var(--component-padding);
    }
    .padding-x-xxxxs\@sm {
      padding-left: var(--space-xxxxs);
      padding-right: var(--space-xxxxs);
    }
    .padding-x-xxxs\@sm {
      padding-left: var(--space-xxxs);
      padding-right: var(--space-xxxs);
    }
    .padding-x-xxs\@sm {
      padding-left: var(--space-xxs);
      padding-right: var(--space-xxs);
    }
    .padding-x-xs\@sm {
      padding-left: var(--space-xs);
      padding-right: var(--space-xs);
    }
    .padding-x-sm\@sm {
      padding-left: var(--space-sm);
      padding-right: var(--space-sm);
    }
    .padding-x-md\@sm {
      padding-left: var(--space-md);
      padding-right: var(--space-md);
    }
    .padding-x-lg\@sm {
      padding-left: var(--space-lg);
      padding-right: var(--space-lg);
    }
    .padding-x-xl\@sm {
      padding-left: var(--space-xl);
      padding-right: var(--space-xl);
    }
    .padding-x-xxl\@sm {
      padding-left: var(--space-xxl);
      padding-right: var(--space-xxl);
    }
    .padding-x-xxxl\@sm {
      padding-left: var(--space-xxxl);
      padding-right: var(--space-xxxl);
    }
    .padding-x-xxxxl\@sm {
      padding-left: var(--space-xxxxl);
      padding-right: var(--space-xxxxl);
    }
    .padding-x-0\@sm {
      padding-left: 0;
      padding-right: 0;
    }
    .padding-x-component\@sm {
      padding-left: var(--component-padding);
      padding-right: var(--component-padding);
    }
    .padding-y-xxxxs\@sm {
      padding-top: var(--space-xxxxs);
      padding-bottom: var(--space-xxxxs);
    }
    .padding-y-xxxs\@sm {
      padding-top: var(--space-xxxs);
      padding-bottom: var(--space-xxxs);
    }
    .padding-y-xxs\@sm {
      padding-top: var(--space-xxs);
      padding-bottom: var(--space-xxs);
    }
    .padding-y-xs\@sm {
      padding-top: var(--space-xs);
      padding-bottom: var(--space-xs);
    }
    .padding-y-sm\@sm {
      padding-top: var(--space-sm);
      padding-bottom: var(--space-sm);
    }
    .padding-y-md\@sm {
      padding-top: var(--space-md);
      padding-bottom: var(--space-md);
    }
    .padding-y-lg\@sm {
      padding-top: var(--space-lg);
      padding-bottom: var(--space-lg);
    }
    .padding-y-xl\@sm {
      padding-top: var(--space-xl);
      padding-bottom: var(--space-xl);
    }
    .padding-y-xxl\@sm {
      padding-top: var(--space-xxl);
      padding-bottom: var(--space-xxl);
    }
    .padding-y-xxxl\@sm {
      padding-top: var(--space-xxxl);
      padding-bottom: var(--space-xxxl);
    }
    .padding-y-xxxxl\@sm {
      padding-top: var(--space-xxxxl);
      padding-bottom: var(--space-xxxxl);
    }
    .padding-y-0\@sm {
      padding-top: 0;
      padding-bottom: 0;
    }
    .padding-y-component\@sm {
      padding-top: var(--component-padding);
      padding-bottom: var(--component-padding);
    }
  }
  .text-center\@sm {
    text-align: center;
  }
  .text-left\@sm {
    text-align: left;
  }
  .text-right\@sm {
    text-align: right;
  }
  @supports (--css: variables) {
    .text-xs\@sm {
      font-size: var(--text-xs, 0.694em);
    }
    .text-sm\@sm {
      font-size: var(--text-sm, 0.833em);
    }
    .text-base\@sm {
      font-size: 1em;
    }
    .text-md\@sm {
      font-size: var(--text-md, 1.2em);
    }
    .text-lg\@sm {
      font-size: var(--text-lg, 1.44em);
    }
    .text-xl\@sm {
      font-size: var(--text-xl, 1.728em);
    }
    .text-xxl\@sm {
      font-size: var(--text-xxl, 2.074em);
    }
    .text-xxxl\@sm {
      font-size: var(--text-xxxl, 2.488em);
    }
  }
  .hide\@sm {
    display: none !important;
  }
}

@media (min-width: 64rem) {
  .flex\@md {
    display: flex;
  }
  .inline-flex\@md {
    display: inline-flex;
  }
  .flex-wrap\@md {
    flex-wrap: wrap;
  }
  .flex-column\@md {
    flex-direction: column;
  }
  .flex-column-reverse\@md {
    flex-direction: column-reverse;
  }
  .flex-row\@md {
    flex-direction: row;
  }
  .flex-row-reverse\@md {
    flex-direction: row-reverse;
  }
  .flex-center\@md {
    justify-content: center;
    align-items: center;
  }
  .flex-grow-0\@md {
    flex-grow: 0;
  }
  .flex-grow\@md {
    flex-grow: 1;
  }
  .flex-shrink-0\@md {
    flex-shrink: 0;
  }
  .flex-shrink\@md {
    flex-shrink: 1;
  }
  .justify-start\@md {
    justify-content: flex-start;
  }
  .justify-end\@md {
    justify-content: flex-end;
  }
  .justify-center\@md {
    justify-content: center;
  }
  .justify-between\@md {
    justify-content: space-between;
  }
  .items-center\@md {
    align-items: center;
  }
  .items-start\@md {
    align-items: flex-start;
  }
  .items-end\@md {
    align-items: flex-end;
  }
  .order-1\@md {
    order: 1;
  }
  .order-2\@md {
    order: 2;
  }
  .order-3\@md {
    order: 3;
  }
  .block\@md {
    display: block;
  }
  .inline-block\@md {
    display: inline-block;
  }
  .inline\@md {
    display: inline;
  }
  @supports (--css: variables) {
    .margin-xxxxs\@md {
      margin: var(--space-xxxxs);
    }
    .margin-xxxs\@md {
      margin: var(--space-xxxs);
    }
    .margin-xxs\@md {
      margin: var(--space-xxs);
    }
    .margin-xs\@md {
      margin: var(--space-xs);
    }
    .margin-sm\@md {
      margin: var(--space-sm);
    }
    .margin-md\@md {
      margin: var(--space-md);
    }
    .margin-lg\@md {
      margin: var(--space-lg);
    }
    .margin-xl\@md {
      margin: var(--space-xl);
    }
    .margin-xxl\@md {
      margin: var(--space-xxl);
    }
    .margin-xxxl\@md {
      margin: var(--space-xxxl);
    }
    .margin-xxxxl\@md {
      margin: var(--space-xxxxl);
    }
    .margin-auto\@md {
      margin: auto;
    }
    .margin-0\@md {
      margin: 0;
    }
    .margin-top-xxxxs\@md {
      margin-top: var(--space-xxxxs);
    }
    .margin-top-xxxs\@md {
      margin-top: var(--space-xxxs);
    }
    .margin-top-xxs\@md {
      margin-top: var(--space-xxs);
    }
    .margin-top-xs\@md {
      margin-top: var(--space-xs);
    }
    .margin-top-sm\@md {
      margin-top: var(--space-sm);
    }
    .margin-top-md\@md {
      margin-top: var(--space-md);
    }
    .margin-top-lg\@md {
      margin-top: var(--space-lg);
    }
    .margin-top-xl\@md {
      margin-top: var(--space-xl);
    }
    .margin-top-xxl\@md {
      margin-top: var(--space-xxl);
    }
    .margin-top-xxxl\@md {
      margin-top: var(--space-xxxl);
    }
    .margin-top-xxxxl\@md {
      margin-top: var(--space-xxxxl);
    }
    .margin-top-auto\@md {
      margin-top: auto;
    }
    .margin-top-0\@md {
      margin-top: 0;
    }
    .margin-bottom-xxxxs\@md {
      margin-bottom: var(--space-xxxxs);
    }
    .margin-bottom-xxxs\@md {
      margin-bottom: var(--space-xxxs);
    }
    .margin-bottom-xxs\@md {
      margin-bottom: var(--space-xxs);
    }
    .margin-bottom-xs\@md {
      margin-bottom: var(--space-xs);
    }
    .margin-bottom-sm\@md {
      margin-bottom: var(--space-sm);
    }
    .margin-bottom-md\@md {
      margin-bottom: var(--space-md);
    }
    .margin-bottom-lg\@md {
      margin-bottom: var(--space-lg);
    }
    .margin-bottom-xl\@md {
      margin-bottom: var(--space-xl);
    }
    .margin-bottom-xxl\@md {
      margin-bottom: var(--space-xxl);
    }
    .margin-bottom-xxxl\@md {
      margin-bottom: var(--space-xxxl);
    }
    .margin-bottom-xxxxl\@md {
      margin-bottom: var(--space-xxxxl);
    }
    .margin-bottom-auto\@md {
      margin-bottom: auto;
    }
    .margin-bottom-0\@md {
      margin-bottom: 0;
    }
    .margin-right-xxxxs\@md {
      margin-right: var(--space-xxxxs);
    }
    .margin-right-xxxs\@md {
      margin-right: var(--space-xxxs);
    }
    .margin-right-xxs\@md {
      margin-right: var(--space-xxs);
    }
    .margin-right-xs\@md {
      margin-right: var(--space-xs);
    }
    .margin-right-sm\@md {
      margin-right: var(--space-sm);
    }
    .margin-right-md\@md {
      margin-right: var(--space-md);
    }
    .margin-right-lg\@md {
      margin-right: var(--space-lg);
    }
    .margin-right-xl\@md {
      margin-right: var(--space-xl);
    }
    .margin-right-xxl\@md {
      margin-right: var(--space-xxl);
    }
    .margin-right-xxxl\@md {
      margin-right: var(--space-xxxl);
    }
    .margin-right-xxxxl\@md {
      margin-right: var(--space-xxxxl);
    }
    .margin-right-auto\@md {
      margin-right: auto;
    }
    .margin-right-0\@md {
      margin-right: 0;
    }
    .margin-left-xxxxs\@md {
      margin-left: var(--space-xxxxs);
    }
    .margin-left-xxxs\@md {
      margin-left: var(--space-xxxs);
    }
    .margin-left-xxs\@md {
      margin-left: var(--space-xxs);
    }
    .margin-left-xs\@md {
      margin-left: var(--space-xs);
    }
    .margin-left-sm\@md {
      margin-left: var(--space-sm);
    }
    .margin-left-md\@md {
      margin-left: var(--space-md);
    }
    .margin-left-lg\@md {
      margin-left: var(--space-lg);
    }
    .margin-left-xl\@md {
      margin-left: var(--space-xl);
    }
    .margin-left-xxl\@md {
      margin-left: var(--space-xxl);
    }
    .margin-left-xxxl\@md {
      margin-left: var(--space-xxxl);
    }
    .margin-left-xxxxl\@md {
      margin-left: var(--space-xxxxl);
    }
    .margin-left-auto\@md {
      margin-left: auto;
    }
    .margin-left-0\@md {
      margin-left: 0;
    }
    .margin-x-xxxxs\@md {
      margin-left: var(--space-xxxxs);
      margin-right: var(--space-xxxxs);
    }
    .margin-x-xxxs\@md {
      margin-left: var(--space-xxxs);
      margin-right: var(--space-xxxs);
    }
    .margin-x-xxs\@md {
      margin-left: var(--space-xxs);
      margin-right: var(--space-xxs);
    }
    .margin-x-xs\@md {
      margin-left: var(--space-xs);
      margin-right: var(--space-xs);
    }
    .margin-x-sm\@md {
      margin-left: var(--space-sm);
      margin-right: var(--space-sm);
    }
    .margin-x-md\@md {
      margin-left: var(--space-md);
      margin-right: var(--space-md);
    }
    .margin-x-lg\@md {
      margin-left: var(--space-lg);
      margin-right: var(--space-lg);
    }
    .margin-x-xl\@md {
      margin-left: var(--space-xl);
      margin-right: var(--space-xl);
    }
    .margin-x-xxl\@md {
      margin-left: var(--space-xxl);
      margin-right: var(--space-xxl);
    }
    .margin-x-xxxl\@md {
      margin-left: var(--space-xxxl);
      margin-right: var(--space-xxxl);
    }
    .margin-x-xxxxl\@md {
      margin-left: var(--space-xxxxl);
      margin-right: var(--space-xxxxl);
    }
    .margin-x-auto\@md {
      margin-left: auto;
      margin-right: auto;
    }
    .margin-x-0\@md {
      margin-left: 0;
      margin-right: 0;
    }
    .margin-y-xxxxs\@md {
      margin-top: var(--space-xxxxs);
      margin-bottom: var(--space-xxxxs);
    }
    .margin-y-xxxs\@md {
      margin-top: var(--space-xxxs);
      margin-bottom: var(--space-xxxs);
    }
    .margin-y-xxs\@md {
      margin-top: var(--space-xxs);
      margin-bottom: var(--space-xxs);
    }
    .margin-y-xs\@md {
      margin-top: var(--space-xs);
      margin-bottom: var(--space-xs);
    }
    .margin-y-sm\@md {
      margin-top: var(--space-sm);
      margin-bottom: var(--space-sm);
    }
    .margin-y-md\@md {
      margin-top: var(--space-md);
      margin-bottom: var(--space-md);
    }
    .margin-y-lg\@md {
      margin-top: var(--space-lg);
      margin-bottom: var(--space-lg);
    }
    .margin-y-xl\@md {
      margin-top: var(--space-xl);
      margin-bottom: var(--space-xl);
    }
    .margin-y-xxl\@md {
      margin-top: var(--space-xxl);
      margin-bottom: var(--space-xxl);
    }
    .margin-y-xxxl\@md {
      margin-top: var(--space-xxxl);
      margin-bottom: var(--space-xxxl);
    }
    .margin-y-xxxxl\@md {
      margin-top: var(--space-xxxxl);
      margin-bottom: var(--space-xxxxl);
    }
    .margin-y-auto\@md {
      margin-top: auto;
      margin-bottom: auto;
    }
    .margin-y-0\@md {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  @supports (--css: variables) {
    .padding-xxxxs\@md {
      padding: var(--space-xxxxs);
    }
    .padding-xxxs\@md {
      padding: var(--space-xxxs);
    }
    .padding-xxs\@md {
      padding: var(--space-xxs);
    }
    .padding-xs\@md {
      padding: var(--space-xs);
    }
    .padding-sm\@md {
      padding: var(--space-sm);
    }
    .padding-md\@md {
      padding: var(--space-md);
    }
    .padding-lg\@md {
      padding: var(--space-lg);
    }
    .padding-xl\@md {
      padding: var(--space-xl);
    }
    .padding-xxl\@md {
      padding: var(--space-xxl);
    }
    .padding-xxxl\@md {
      padding: var(--space-xxxl);
    }
    .padding-xxxxl\@md {
      padding: var(--space-xxxxl);
    }
    .padding-0\@md {
      padding: 0;
    }
    .padding-component\@md {
      padding: var(--component-padding);
    }
    .padding-top-xxxxs\@md {
      padding-top: var(--space-xxxxs);
    }
    .padding-top-xxxs\@md {
      padding-top: var(--space-xxxs);
    }
    .padding-top-xxs\@md {
      padding-top: var(--space-xxs);
    }
    .padding-top-xs\@md {
      padding-top: var(--space-xs);
    }
    .padding-top-sm\@md {
      padding-top: var(--space-sm);
    }
    .padding-top-md\@md {
      padding-top: var(--space-md);
    }
    .padding-top-lg\@md {
      padding-top: var(--space-lg);
    }
    .padding-top-xl\@md {
      padding-top: var(--space-xl);
    }
    .padding-top-xxl\@md {
      padding-top: var(--space-xxl);
    }
    .padding-top-xxxl\@md {
      padding-top: var(--space-xxxl);
    }
    .padding-top-xxxxl\@md {
      padding-top: var(--space-xxxxl);
    }
    .padding-top-0\@md {
      padding-top: 0;
    }
    .padding-top-component\@md {
      padding-top: var(--component-padding);
    }
    .padding-bottom-xxxxs\@md {
      padding-bottom: var(--space-xxxxs);
    }
    .padding-bottom-xxxs\@md {
      padding-bottom: var(--space-xxxs);
    }
    .padding-bottom-xxs\@md {
      padding-bottom: var(--space-xxs);
    }
    .padding-bottom-xs\@md {
      padding-bottom: var(--space-xs);
    }
    .padding-bottom-sm\@md {
      padding-bottom: var(--space-sm);
    }
    .padding-bottom-md\@md {
      padding-bottom: var(--space-md);
    }
    .padding-bottom-lg\@md {
      padding-bottom: var(--space-lg);
    }
    .padding-bottom-xl\@md {
      padding-bottom: var(--space-xl);
    }
    .padding-bottom-xxl\@md {
      padding-bottom: var(--space-xxl);
    }
    .padding-bottom-xxxl\@md {
      padding-bottom: var(--space-xxxl);
    }
    .padding-bottom-xxxxl\@md {
      padding-bottom: var(--space-xxxxl);
    }
    .padding-bottom-0\@md {
      padding-bottom: 0;
    }
    .padding-bottom-component\@md {
      padding-bottom: var(--component-padding);
    }
    .padding-right-xxxxs\@md {
      padding-right: var(--space-xxxxs);
    }
    .padding-right-xxxs\@md {
      padding-right: var(--space-xxxs);
    }
    .padding-right-xxs\@md {
      padding-right: var(--space-xxs);
    }
    .padding-right-xs\@md {
      padding-right: var(--space-xs);
    }
    .padding-right-sm\@md {
      padding-right: var(--space-sm);
    }
    .padding-right-md\@md {
      padding-right: var(--space-md);
    }
    .padding-right-lg\@md {
      padding-right: var(--space-lg);
    }
    .padding-right-xl\@md {
      padding-right: var(--space-xl);
    }
    .padding-right-xxl\@md {
      padding-right: var(--space-xxl);
    }
    .padding-right-xxxl\@md {
      padding-right: var(--space-xxxl);
    }
    .padding-right-xxxxl\@md {
      padding-right: var(--space-xxxxl);
    }
    .padding-right-0\@md {
      padding-right: 0;
    }
    .padding-right-component\@md {
      padding-right: var(--component-padding);
    }
    .padding-left-xxxxs\@md {
      padding-left: var(--space-xxxxs);
    }
    .padding-left-xxxs\@md {
      padding-left: var(--space-xxxs);
    }
    .padding-left-xxs\@md {
      padding-left: var(--space-xxs);
    }
    .padding-left-xs\@md {
      padding-left: var(--space-xs);
    }
    .padding-left-sm\@md {
      padding-left: var(--space-sm);
    }
    .padding-left-md\@md {
      padding-left: var(--space-md);
    }
    .padding-left-lg\@md {
      padding-left: var(--space-lg);
    }
    .padding-left-xl\@md {
      padding-left: var(--space-xl);
    }
    .padding-left-xxl\@md {
      padding-left: var(--space-xxl);
    }
    .padding-left-xxxl\@md {
      padding-left: var(--space-xxxl);
    }
    .padding-left-xxxxl\@md {
      padding-left: var(--space-xxxxl);
    }
    .padding-left-0\@md {
      padding-left: 0;
    }
    .padding-left-component\@md {
      padding-left: var(--component-padding);
    }
    .padding-x-xxxxs\@md {
      padding-left: var(--space-xxxxs);
      padding-right: var(--space-xxxxs);
    }
    .padding-x-xxxs\@md {
      padding-left: var(--space-xxxs);
      padding-right: var(--space-xxxs);
    }
    .padding-x-xxs\@md {
      padding-left: var(--space-xxs);
      padding-right: var(--space-xxs);
    }
    .padding-x-xs\@md {
      padding-left: var(--space-xs);
      padding-right: var(--space-xs);
    }
    .padding-x-sm\@md {
      padding-left: var(--space-sm);
      padding-right: var(--space-sm);
    }
    .padding-x-md\@md {
      padding-left: var(--space-md);
      padding-right: var(--space-md);
    }
    .padding-x-lg\@md {
      padding-left: var(--space-lg);
      padding-right: var(--space-lg);
    }
    .padding-x-xl\@md {
      padding-left: var(--space-xl);
      padding-right: var(--space-xl);
    }
    .padding-x-xxl\@md {
      padding-left: var(--space-xxl);
      padding-right: var(--space-xxl);
    }
    .padding-x-xxxl\@md {
      padding-left: var(--space-xxxl);
      padding-right: var(--space-xxxl);
    }
    .padding-x-xxxxl\@md {
      padding-left: var(--space-xxxxl);
      padding-right: var(--space-xxxxl);
    }
    .padding-x-0\@md {
      padding-left: 0;
      padding-right: 0;
    }
    .padding-x-component\@md {
      padding-left: var(--component-padding);
      padding-right: var(--component-padding);
    }
    .padding-y-xxxxs\@md {
      padding-top: var(--space-xxxxs);
      padding-bottom: var(--space-xxxxs);
    }
    .padding-y-xxxs\@md {
      padding-top: var(--space-xxxs);
      padding-bottom: var(--space-xxxs);
    }
    .padding-y-xxs\@md {
      padding-top: var(--space-xxs);
      padding-bottom: var(--space-xxs);
    }
    .padding-y-xs\@md {
      padding-top: var(--space-xs);
      padding-bottom: var(--space-xs);
    }
    .padding-y-sm\@md {
      padding-top: var(--space-sm);
      padding-bottom: var(--space-sm);
    }
    .padding-y-md\@md {
      padding-top: var(--space-md);
      padding-bottom: var(--space-md);
    }
    .padding-y-lg\@md {
      padding-top: var(--space-lg);
      padding-bottom: var(--space-lg);
    }
    .padding-y-xl\@md {
      padding-top: var(--space-xl);
      padding-bottom: var(--space-xl);
    }
    .padding-y-xxl\@md {
      padding-top: var(--space-xxl);
      padding-bottom: var(--space-xxl);
    }
    .padding-y-xxxl\@md {
      padding-top: var(--space-xxxl);
      padding-bottom: var(--space-xxxl);
    }
    .padding-y-xxxxl\@md {
      padding-top: var(--space-xxxxl);
      padding-bottom: var(--space-xxxxl);
    }
    .padding-y-0\@md {
      padding-top: 0;
      padding-bottom: 0;
    }
    .padding-y-component\@md {
      padding-top: var(--component-padding);
      padding-bottom: var(--component-padding);
    }
  }
  .text-center\@md {
    text-align: center;
  }
  .text-left\@md {
    text-align: left;
  }
  .text-right\@md {
    text-align: right;
  }
  @supports (--css: variables) {
    .text-xs\@md {
      font-size: var(--text-xs, 0.694em);
    }
    .text-sm\@md {
      font-size: var(--text-sm, 0.833em);
    }
    .text-base\@md {
      font-size: 1em;
    }
    .text-md\@md {
      font-size: var(--text-md, 1.2em);
    }
    .text-lg\@md {
      font-size: var(--text-lg, 1.44em);
    }
    .text-xl\@md {
      font-size: var(--text-xl, 1.728em);
    }
    .text-xxl\@md {
      font-size: var(--text-xxl, 2.074em);
    }
    .text-xxxl\@md {
      font-size: var(--text-xxxl, 2.488em);
    }
  }
  .hide\@md {
    display: none !important;
  }
}

@media (min-width: 80rem) {
  .flex\@lg {
    display: flex;
  }
  .inline-flex\@lg {
    display: inline-flex;
  }
  .flex-wrap\@lg {
    flex-wrap: wrap;
  }
  .flex-column\@lg {
    flex-direction: column;
  }
  .flex-column-reverse\@lg {
    flex-direction: column-reverse;
  }
  .flex-row\@lg {
    flex-direction: row;
  }
  .flex-row-reverse\@lg {
    flex-direction: row-reverse;
  }
  .flex-center\@lg {
    justify-content: center;
    align-items: center;
  }
  .flex-grow-0\@lg {
    flex-grow: 0;
  }
  .flex-grow\@lg {
    flex-grow: 1;
  }
  .flex-shrink-0\@lg {
    flex-shrink: 0;
  }
  .flex-shrink\@lg {
    flex-shrink: 1;
  }
  .justify-start\@lg {
    justify-content: flex-start;
  }
  .justify-end\@lg {
    justify-content: flex-end;
  }
  .justify-center\@lg {
    justify-content: center;
  }
  .justify-between\@lg {
    justify-content: space-between;
  }
  .items-center\@lg {
    align-items: center;
  }
  .items-start\@lg {
    align-items: flex-start;
  }
  .items-end\@lg {
    align-items: flex-end;
  }
  .order-1\@lg {
    order: 1;
  }
  .order-2\@lg {
    order: 2;
  }
  .order-3\@lg {
    order: 3;
  }
  .block\@lg {
    display: block;
  }
  .inline-block\@lg {
    display: inline-block;
  }
  .inline\@lg {
    display: inline;
  }
  @supports (--css: variables) {
    .margin-xxxxs\@lg {
      margin: var(--space-xxxxs);
    }
    .margin-xxxs\@lg {
      margin: var(--space-xxxs);
    }
    .margin-xxs\@lg {
      margin: var(--space-xxs);
    }
    .margin-xs\@lg {
      margin: var(--space-xs);
    }
    .margin-sm\@lg {
      margin: var(--space-sm);
    }
    .margin-md\@lg {
      margin: var(--space-md);
    }
    .margin-lg\@lg {
      margin: var(--space-lg);
    }
    .margin-xl\@lg {
      margin: var(--space-xl);
    }
    .margin-xxl\@lg {
      margin: var(--space-xxl);
    }
    .margin-xxxl\@lg {
      margin: var(--space-xxxl);
    }
    .margin-xxxxl\@lg {
      margin: var(--space-xxxxl);
    }
    .margin-auto\@lg {
      margin: auto;
    }
    .margin-0\@lg {
      margin: 0;
    }
    .margin-top-xxxxs\@lg {
      margin-top: var(--space-xxxxs);
    }
    .margin-top-xxxs\@lg {
      margin-top: var(--space-xxxs);
    }
    .margin-top-xxs\@lg {
      margin-top: var(--space-xxs);
    }
    .margin-top-xs\@lg {
      margin-top: var(--space-xs);
    }
    .margin-top-sm\@lg {
      margin-top: var(--space-sm);
    }
    .margin-top-md\@lg {
      margin-top: var(--space-md);
    }
    .margin-top-lg\@lg {
      margin-top: var(--space-lg);
    }
    .margin-top-xl\@lg {
      margin-top: var(--space-xl);
    }
    .margin-top-xxl\@lg {
      margin-top: var(--space-xxl);
    }
    .margin-top-xxxl\@lg {
      margin-top: var(--space-xxxl);
    }
    .margin-top-xxxxl\@lg {
      margin-top: var(--space-xxxxl);
    }
    .margin-top-auto\@lg {
      margin-top: auto;
    }
    .margin-top-0\@lg {
      margin-top: 0;
    }
    .margin-bottom-xxxxs\@lg {
      margin-bottom: var(--space-xxxxs);
    }
    .margin-bottom-xxxs\@lg {
      margin-bottom: var(--space-xxxs);
    }
    .margin-bottom-xxs\@lg {
      margin-bottom: var(--space-xxs);
    }
    .margin-bottom-xs\@lg {
      margin-bottom: var(--space-xs);
    }
    .margin-bottom-sm\@lg {
      margin-bottom: var(--space-sm);
    }
    .margin-bottom-md\@lg {
      margin-bottom: var(--space-md);
    }
    .margin-bottom-lg\@lg {
      margin-bottom: var(--space-lg);
    }
    .margin-bottom-xl\@lg {
      margin-bottom: var(--space-xl);
    }
    .margin-bottom-xxl\@lg {
      margin-bottom: var(--space-xxl);
    }
    .margin-bottom-xxxl\@lg {
      margin-bottom: var(--space-xxxl);
    }
    .margin-bottom-xxxxl\@lg {
      margin-bottom: var(--space-xxxxl);
    }
    .margin-bottom-auto\@lg {
      margin-bottom: auto;
    }
    .margin-bottom-0\@lg {
      margin-bottom: 0;
    }
    .margin-right-xxxxs\@lg {
      margin-right: var(--space-xxxxs);
    }
    .margin-right-xxxs\@lg {
      margin-right: var(--space-xxxs);
    }
    .margin-right-xxs\@lg {
      margin-right: var(--space-xxs);
    }
    .margin-right-xs\@lg {
      margin-right: var(--space-xs);
    }
    .margin-right-sm\@lg {
      margin-right: var(--space-sm);
    }
    .margin-right-md\@lg {
      margin-right: var(--space-md);
    }
    .margin-right-lg\@lg {
      margin-right: var(--space-lg);
    }
    .margin-right-xl\@lg {
      margin-right: var(--space-xl);
    }
    .margin-right-xxl\@lg {
      margin-right: var(--space-xxl);
    }
    .margin-right-xxxl\@lg {
      margin-right: var(--space-xxxl);
    }
    .margin-right-xxxxl\@lg {
      margin-right: var(--space-xxxxl);
    }
    .margin-right-auto\@lg {
      margin-right: auto;
    }
    .margin-right-0\@lg {
      margin-right: 0;
    }
    .margin-left-xxxxs\@lg {
      margin-left: var(--space-xxxxs);
    }
    .margin-left-xxxs\@lg {
      margin-left: var(--space-xxxs);
    }
    .margin-left-xxs\@lg {
      margin-left: var(--space-xxs);
    }
    .margin-left-xs\@lg {
      margin-left: var(--space-xs);
    }
    .margin-left-sm\@lg {
      margin-left: var(--space-sm);
    }
    .margin-left-md\@lg {
      margin-left: var(--space-md);
    }
    .margin-left-lg\@lg {
      margin-left: var(--space-lg);
    }
    .margin-left-xl\@lg {
      margin-left: var(--space-xl);
    }
    .margin-left-xxl\@lg {
      margin-left: var(--space-xxl);
    }
    .margin-left-xxxl\@lg {
      margin-left: var(--space-xxxl);
    }
    .margin-left-xxxxl\@lg {
      margin-left: var(--space-xxxxl);
    }
    .margin-left-auto\@lg {
      margin-left: auto;
    }
    .margin-left-0\@lg {
      margin-left: 0;
    }
    .margin-x-xxxxs\@lg {
      margin-left: var(--space-xxxxs);
      margin-right: var(--space-xxxxs);
    }
    .margin-x-xxxs\@lg {
      margin-left: var(--space-xxxs);
      margin-right: var(--space-xxxs);
    }
    .margin-x-xxs\@lg {
      margin-left: var(--space-xxs);
      margin-right: var(--space-xxs);
    }
    .margin-x-xs\@lg {
      margin-left: var(--space-xs);
      margin-right: var(--space-xs);
    }
    .margin-x-sm\@lg {
      margin-left: var(--space-sm);
      margin-right: var(--space-sm);
    }
    .margin-x-md\@lg {
      margin-left: var(--space-md);
      margin-right: var(--space-md);
    }
    .margin-x-lg\@lg {
      margin-left: var(--space-lg);
      margin-right: var(--space-lg);
    }
    .margin-x-xl\@lg {
      margin-left: var(--space-xl);
      margin-right: var(--space-xl);
    }
    .margin-x-xxl\@lg {
      margin-left: var(--space-xxl);
      margin-right: var(--space-xxl);
    }
    .margin-x-xxxl\@lg {
      margin-left: var(--space-xxxl);
      margin-right: var(--space-xxxl);
    }
    .margin-x-xxxxl\@lg {
      margin-left: var(--space-xxxxl);
      margin-right: var(--space-xxxxl);
    }
    .margin-x-auto\@lg {
      margin-left: auto;
      margin-right: auto;
    }
    .margin-x-0\@lg {
      margin-left: 0;
      margin-right: 0;
    }
    .margin-y-xxxxs\@lg {
      margin-top: var(--space-xxxxs);
      margin-bottom: var(--space-xxxxs);
    }
    .margin-y-xxxs\@lg {
      margin-top: var(--space-xxxs);
      margin-bottom: var(--space-xxxs);
    }
    .margin-y-xxs\@lg {
      margin-top: var(--space-xxs);
      margin-bottom: var(--space-xxs);
    }
    .margin-y-xs\@lg {
      margin-top: var(--space-xs);
      margin-bottom: var(--space-xs);
    }
    .margin-y-sm\@lg {
      margin-top: var(--space-sm);
      margin-bottom: var(--space-sm);
    }
    .margin-y-md\@lg {
      margin-top: var(--space-md);
      margin-bottom: var(--space-md);
    }
    .margin-y-lg\@lg {
      margin-top: var(--space-lg);
      margin-bottom: var(--space-lg);
    }
    .margin-y-xl\@lg {
      margin-top: var(--space-xl);
      margin-bottom: var(--space-xl);
    }
    .margin-y-xxl\@lg {
      margin-top: var(--space-xxl);
      margin-bottom: var(--space-xxl);
    }
    .margin-y-xxxl\@lg {
      margin-top: var(--space-xxxl);
      margin-bottom: var(--space-xxxl);
    }
    .margin-y-xxxxl\@lg {
      margin-top: var(--space-xxxxl);
      margin-bottom: var(--space-xxxxl);
    }
    .margin-y-auto\@lg {
      margin-top: auto;
      margin-bottom: auto;
    }
    .margin-y-0\@lg {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  @supports (--css: variables) {
    .padding-xxxxs\@lg {
      padding: var(--space-xxxxs);
    }
    .padding-xxxs\@lg {
      padding: var(--space-xxxs);
    }
    .padding-xxs\@lg {
      padding: var(--space-xxs);
    }
    .padding-xs\@lg {
      padding: var(--space-xs);
    }
    .padding-sm\@lg {
      padding: var(--space-sm);
    }
    .padding-md\@lg {
      padding: var(--space-md);
    }
    .padding-lg\@lg {
      padding: var(--space-lg);
    }
    .padding-xl\@lg {
      padding: var(--space-xl);
    }
    .padding-xxl\@lg {
      padding: var(--space-xxl);
    }
    .padding-xxxl\@lg {
      padding: var(--space-xxxl);
    }
    .padding-xxxxl\@lg {
      padding: var(--space-xxxxl);
    }
    .padding-0\@lg {
      padding: 0;
    }
    .padding-component\@lg {
      padding: var(--component-padding);
    }
    .padding-top-xxxxs\@lg {
      padding-top: var(--space-xxxxs);
    }
    .padding-top-xxxs\@lg {
      padding-top: var(--space-xxxs);
    }
    .padding-top-xxs\@lg {
      padding-top: var(--space-xxs);
    }
    .padding-top-xs\@lg {
      padding-top: var(--space-xs);
    }
    .padding-top-sm\@lg {
      padding-top: var(--space-sm);
    }
    .padding-top-md\@lg {
      padding-top: var(--space-md);
    }
    .padding-top-lg\@lg {
      padding-top: var(--space-lg);
    }
    .padding-top-xl\@lg {
      padding-top: var(--space-xl);
    }
    .padding-top-xxl\@lg {
      padding-top: var(--space-xxl);
    }
    .padding-top-xxxl\@lg {
      padding-top: var(--space-xxxl);
    }
    .padding-top-xxxxl\@lg {
      padding-top: var(--space-xxxxl);
    }
    .padding-top-0\@lg {
      padding-top: 0;
    }
    .padding-top-component\@lg {
      padding-top: var(--component-padding);
    }
    .padding-bottom-xxxxs\@lg {
      padding-bottom: var(--space-xxxxs);
    }
    .padding-bottom-xxxs\@lg {
      padding-bottom: var(--space-xxxs);
    }
    .padding-bottom-xxs\@lg {
      padding-bottom: var(--space-xxs);
    }
    .padding-bottom-xs\@lg {
      padding-bottom: var(--space-xs);
    }
    .padding-bottom-sm\@lg {
      padding-bottom: var(--space-sm);
    }
    .padding-bottom-md\@lg {
      padding-bottom: var(--space-md);
    }
    .padding-bottom-lg\@lg {
      padding-bottom: var(--space-lg);
    }
    .padding-bottom-xl\@lg {
      padding-bottom: var(--space-xl);
    }
    .padding-bottom-xxl\@lg {
      padding-bottom: var(--space-xxl);
    }
    .padding-bottom-xxxl\@lg {
      padding-bottom: var(--space-xxxl);
    }
    .padding-bottom-xxxxl\@lg {
      padding-bottom: var(--space-xxxxl);
    }
    .padding-bottom-0\@lg {
      padding-bottom: 0;
    }
    .padding-bottom-component\@lg {
      padding-bottom: var(--component-padding);
    }
    .padding-right-xxxxs\@lg {
      padding-right: var(--space-xxxxs);
    }
    .padding-right-xxxs\@lg {
      padding-right: var(--space-xxxs);
    }
    .padding-right-xxs\@lg {
      padding-right: var(--space-xxs);
    }
    .padding-right-xs\@lg {
      padding-right: var(--space-xs);
    }
    .padding-right-sm\@lg {
      padding-right: var(--space-sm);
    }
    .padding-right-md\@lg {
      padding-right: var(--space-md);
    }
    .padding-right-lg\@lg {
      padding-right: var(--space-lg);
    }
    .padding-right-xl\@lg {
      padding-right: var(--space-xl);
    }
    .padding-right-xxl\@lg {
      padding-right: var(--space-xxl);
    }
    .padding-right-xxxl\@lg {
      padding-right: var(--space-xxxl);
    }
    .padding-right-xxxxl\@lg {
      padding-right: var(--space-xxxxl);
    }
    .padding-right-0\@lg {
      padding-right: 0;
    }
    .padding-right-component\@lg {
      padding-right: var(--component-padding);
    }
    .padding-left-xxxxs\@lg {
      padding-left: var(--space-xxxxs);
    }
    .padding-left-xxxs\@lg {
      padding-left: var(--space-xxxs);
    }
    .padding-left-xxs\@lg {
      padding-left: var(--space-xxs);
    }
    .padding-left-xs\@lg {
      padding-left: var(--space-xs);
    }
    .padding-left-sm\@lg {
      padding-left: var(--space-sm);
    }
    .padding-left-md\@lg {
      padding-left: var(--space-md);
    }
    .padding-left-lg\@lg {
      padding-left: var(--space-lg);
    }
    .padding-left-xl\@lg {
      padding-left: var(--space-xl);
    }
    .padding-left-xxl\@lg {
      padding-left: var(--space-xxl);
    }
    .padding-left-xxxl\@lg {
      padding-left: var(--space-xxxl);
    }
    .padding-left-xxxxl\@lg {
      padding-left: var(--space-xxxxl);
    }
    .padding-left-0\@lg {
      padding-left: 0;
    }
    .padding-left-component\@lg {
      padding-left: var(--component-padding);
    }
    .padding-x-xxxxs\@lg {
      padding-left: var(--space-xxxxs);
      padding-right: var(--space-xxxxs);
    }
    .padding-x-xxxs\@lg {
      padding-left: var(--space-xxxs);
      padding-right: var(--space-xxxs);
    }
    .padding-x-xxs\@lg {
      padding-left: var(--space-xxs);
      padding-right: var(--space-xxs);
    }
    .padding-x-xs\@lg {
      padding-left: var(--space-xs);
      padding-right: var(--space-xs);
    }
    .padding-x-sm\@lg {
      padding-left: var(--space-sm);
      padding-right: var(--space-sm);
    }
    .padding-x-md\@lg {
      padding-left: var(--space-md);
      padding-right: var(--space-md);
    }
    .padding-x-lg\@lg {
      padding-left: var(--space-lg);
      padding-right: var(--space-lg);
    }
    .padding-x-xl\@lg {
      padding-left: var(--space-xl);
      padding-right: var(--space-xl);
    }
    .padding-x-xxl\@lg {
      padding-left: var(--space-xxl);
      padding-right: var(--space-xxl);
    }
    .padding-x-xxxl\@lg {
      padding-left: var(--space-xxxl);
      padding-right: var(--space-xxxl);
    }
    .padding-x-xxxxl\@lg {
      padding-left: var(--space-xxxxl);
      padding-right: var(--space-xxxxl);
    }
    .padding-x-0\@lg {
      padding-left: 0;
      padding-right: 0;
    }
    .padding-x-component\@lg {
      padding-left: var(--component-padding);
      padding-right: var(--component-padding);
    }
    .padding-y-xxxxs\@lg {
      padding-top: var(--space-xxxxs);
      padding-bottom: var(--space-xxxxs);
    }
    .padding-y-xxxs\@lg {
      padding-top: var(--space-xxxs);
      padding-bottom: var(--space-xxxs);
    }
    .padding-y-xxs\@lg {
      padding-top: var(--space-xxs);
      padding-bottom: var(--space-xxs);
    }
    .padding-y-xs\@lg {
      padding-top: var(--space-xs);
      padding-bottom: var(--space-xs);
    }
    .padding-y-sm\@lg {
      padding-top: var(--space-sm);
      padding-bottom: var(--space-sm);
    }
    .padding-y-md\@lg {
      padding-top: var(--space-md);
      padding-bottom: var(--space-md);
    }
    .padding-y-lg\@lg {
      padding-top: var(--space-lg);
      padding-bottom: var(--space-lg);
    }
    .padding-y-xl\@lg {
      padding-top: var(--space-xl);
      padding-bottom: var(--space-xl);
    }
    .padding-y-xxl\@lg {
      padding-top: var(--space-xxl);
      padding-bottom: var(--space-xxl);
    }
    .padding-y-xxxl\@lg {
      padding-top: var(--space-xxxl);
      padding-bottom: var(--space-xxxl);
    }
    .padding-y-xxxxl\@lg {
      padding-top: var(--space-xxxxl);
      padding-bottom: var(--space-xxxxl);
    }
    .padding-y-0\@lg {
      padding-top: 0;
      padding-bottom: 0;
    }
    .padding-y-component\@lg {
      padding-top: var(--component-padding);
      padding-bottom: var(--component-padding);
    }
  }
  .text-center\@lg {
    text-align: center;
  }
  .text-left\@lg {
    text-align: left;
  }
  .text-right\@lg {
    text-align: right;
  }
  @supports (--css: variables) {
    .text-xs\@lg {
      font-size: var(--text-xs, 0.694em);
    }
    .text-sm\@lg {
      font-size: var(--text-sm, 0.833em);
    }
    .text-base\@lg {
      font-size: 1em;
    }
    .text-md\@lg {
      font-size: var(--text-md, 1.2em);
    }
    .text-lg\@lg {
      font-size: var(--text-lg, 1.44em);
    }
    .text-xl\@lg {
      font-size: var(--text-xl, 1.728em);
    }
    .text-xxl\@lg {
      font-size: var(--text-xxl, 2.074em);
    }
    .text-xxxl\@lg {
      font-size: var(--text-xxxl, 2.488em);
    }
  }
  .hide\@lg {
    display: none !important;
  }
}

@media (min-width: 90rem) {
  .flex\@xl {
    display: flex;
  }
  .inline-flex\@xl {
    display: inline-flex;
  }
  .flex-wrap\@xl {
    flex-wrap: wrap;
  }
  .flex-column\@xl {
    flex-direction: column;
  }
  .flex-column-reverse\@xl {
    flex-direction: column-reverse;
  }
  .flex-row\@xl {
    flex-direction: row;
  }
  .flex-row-reverse\@xl {
    flex-direction: row-reverse;
  }
  .flex-center\@xl {
    justify-content: center;
    align-items: center;
  }
  .flex-grow-0\@xl {
    flex-grow: 0;
  }
  .flex-grow\@xl {
    flex-grow: 1;
  }
  .flex-shrink-0\@xl {
    flex-shrink: 0;
  }
  .flex-shrink\@xl {
    flex-shrink: 1;
  }
  .justify-start\@xl {
    justify-content: flex-start;
  }
  .justify-end\@xl {
    justify-content: flex-end;
  }
  .justify-center\@xl {
    justify-content: center;
  }
  .justify-between\@xl {
    justify-content: space-between;
  }
  .items-center\@xl {
    align-items: center;
  }
  .items-start\@xl {
    align-items: flex-start;
  }
  .items-end\@xl {
    align-items: flex-end;
  }
  .order-1\@xl {
    order: 1;
  }
  .order-2\@xl {
    order: 2;
  }
  .order-3\@xl {
    order: 3;
  }
  .block\@xl {
    display: block;
  }
  .inline-block\@xl {
    display: inline-block;
  }
  .inline\@xl {
    display: inline;
  }
  @supports (--css: variables) {
    .margin-xxxxs\@xl {
      margin: var(--space-xxxxs);
    }
    .margin-xxxs\@xl {
      margin: var(--space-xxxs);
    }
    .margin-xxs\@xl {
      margin: var(--space-xxs);
    }
    .margin-xs\@xl {
      margin: var(--space-xs);
    }
    .margin-sm\@xl {
      margin: var(--space-sm);
    }
    .margin-md\@xl {
      margin: var(--space-md);
    }
    .margin-lg\@xl {
      margin: var(--space-lg);
    }
    .margin-xl\@xl {
      margin: var(--space-xl);
    }
    .margin-xxl\@xl {
      margin: var(--space-xxl);
    }
    .margin-xxxl\@xl {
      margin: var(--space-xxxl);
    }
    .margin-xxxxl\@xl {
      margin: var(--space-xxxxl);
    }
    .margin-auto\@xl {
      margin: auto;
    }
    .margin-0\@xl {
      margin: 0;
    }
    .margin-top-xxxxs\@xl {
      margin-top: var(--space-xxxxs);
    }
    .margin-top-xxxs\@xl {
      margin-top: var(--space-xxxs);
    }
    .margin-top-xxs\@xl {
      margin-top: var(--space-xxs);
    }
    .margin-top-xs\@xl {
      margin-top: var(--space-xs);
    }
    .margin-top-sm\@xl {
      margin-top: var(--space-sm);
    }
    .margin-top-md\@xl {
      margin-top: var(--space-md);
    }
    .margin-top-lg\@xl {
      margin-top: var(--space-lg);
    }
    .margin-top-xl\@xl {
      margin-top: var(--space-xl);
    }
    .margin-top-xxl\@xl {
      margin-top: var(--space-xxl);
    }
    .margin-top-xxxl\@xl {
      margin-top: var(--space-xxxl);
    }
    .margin-top-xxxxl\@xl {
      margin-top: var(--space-xxxxl);
    }
    .margin-top-auto\@xl {
      margin-top: auto;
    }
    .margin-top-0\@xl {
      margin-top: 0;
    }
    .margin-bottom-xxxxs\@xl {
      margin-bottom: var(--space-xxxxs);
    }
    .margin-bottom-xxxs\@xl {
      margin-bottom: var(--space-xxxs);
    }
    .margin-bottom-xxs\@xl {
      margin-bottom: var(--space-xxs);
    }
    .margin-bottom-xs\@xl {
      margin-bottom: var(--space-xs);
    }
    .margin-bottom-sm\@xl {
      margin-bottom: var(--space-sm);
    }
    .margin-bottom-md\@xl {
      margin-bottom: var(--space-md);
    }
    .margin-bottom-lg\@xl {
      margin-bottom: var(--space-lg);
    }
    .margin-bottom-xl\@xl {
      margin-bottom: var(--space-xl);
    }
    .margin-bottom-xxl\@xl {
      margin-bottom: var(--space-xxl);
    }
    .margin-bottom-xxxl\@xl {
      margin-bottom: var(--space-xxxl);
    }
    .margin-bottom-xxxxl\@xl {
      margin-bottom: var(--space-xxxxl);
    }
    .margin-bottom-auto\@xl {
      margin-bottom: auto;
    }
    .margin-bottom-0\@xl {
      margin-bottom: 0;
    }
    .margin-right-xxxxs\@xl {
      margin-right: var(--space-xxxxs);
    }
    .margin-right-xxxs\@xl {
      margin-right: var(--space-xxxs);
    }
    .margin-right-xxs\@xl {
      margin-right: var(--space-xxs);
    }
    .margin-right-xs\@xl {
      margin-right: var(--space-xs);
    }
    .margin-right-sm\@xl {
      margin-right: var(--space-sm);
    }
    .margin-right-md\@xl {
      margin-right: var(--space-md);
    }
    .margin-right-lg\@xl {
      margin-right: var(--space-lg);
    }
    .margin-right-xl\@xl {
      margin-right: var(--space-xl);
    }
    .margin-right-xxl\@xl {
      margin-right: var(--space-xxl);
    }
    .margin-right-xxxl\@xl {
      margin-right: var(--space-xxxl);
    }
    .margin-right-xxxxl\@xl {
      margin-right: var(--space-xxxxl);
    }
    .margin-right-auto\@xl {
      margin-right: auto;
    }
    .margin-right-0\@xl {
      margin-right: 0;
    }
    .margin-left-xxxxs\@xl {
      margin-left: var(--space-xxxxs);
    }
    .margin-left-xxxs\@xl {
      margin-left: var(--space-xxxs);
    }
    .margin-left-xxs\@xl {
      margin-left: var(--space-xxs);
    }
    .margin-left-xs\@xl {
      margin-left: var(--space-xs);
    }
    .margin-left-sm\@xl {
      margin-left: var(--space-sm);
    }
    .margin-left-md\@xl {
      margin-left: var(--space-md);
    }
    .margin-left-lg\@xl {
      margin-left: var(--space-lg);
    }
    .margin-left-xl\@xl {
      margin-left: var(--space-xl);
    }
    .margin-left-xxl\@xl {
      margin-left: var(--space-xxl);
    }
    .margin-left-xxxl\@xl {
      margin-left: var(--space-xxxl);
    }
    .margin-left-xxxxl\@xl {
      margin-left: var(--space-xxxxl);
    }
    .margin-left-auto\@xl {
      margin-left: auto;
    }
    .margin-left-0\@xl {
      margin-left: 0;
    }
    .margin-x-xxxxs\@xl {
      margin-left: var(--space-xxxxs);
      margin-right: var(--space-xxxxs);
    }
    .margin-x-xxxs\@xl {
      margin-left: var(--space-xxxs);
      margin-right: var(--space-xxxs);
    }
    .margin-x-xxs\@xl {
      margin-left: var(--space-xxs);
      margin-right: var(--space-xxs);
    }
    .margin-x-xs\@xl {
      margin-left: var(--space-xs);
      margin-right: var(--space-xs);
    }
    .margin-x-sm\@xl {
      margin-left: var(--space-sm);
      margin-right: var(--space-sm);
    }
    .margin-x-md\@xl {
      margin-left: var(--space-md);
      margin-right: var(--space-md);
    }
    .margin-x-lg\@xl {
      margin-left: var(--space-lg);
      margin-right: var(--space-lg);
    }
    .margin-x-xl\@xl {
      margin-left: var(--space-xl);
      margin-right: var(--space-xl);
    }
    .margin-x-xxl\@xl {
      margin-left: var(--space-xxl);
      margin-right: var(--space-xxl);
    }
    .margin-x-xxxl\@xl {
      margin-left: var(--space-xxxl);
      margin-right: var(--space-xxxl);
    }
    .margin-x-xxxxl\@xl {
      margin-left: var(--space-xxxxl);
      margin-right: var(--space-xxxxl);
    }
    .margin-x-auto\@xl {
      margin-left: auto;
      margin-right: auto;
    }
    .margin-x-0\@xl {
      margin-left: 0;
      margin-right: 0;
    }
    .margin-y-xxxxs\@xl {
      margin-top: var(--space-xxxxs);
      margin-bottom: var(--space-xxxxs);
    }
    .margin-y-xxxs\@xl {
      margin-top: var(--space-xxxs);
      margin-bottom: var(--space-xxxs);
    }
    .margin-y-xxs\@xl {
      margin-top: var(--space-xxs);
      margin-bottom: var(--space-xxs);
    }
    .margin-y-xs\@xl {
      margin-top: var(--space-xs);
      margin-bottom: var(--space-xs);
    }
    .margin-y-sm\@xl {
      margin-top: var(--space-sm);
      margin-bottom: var(--space-sm);
    }
    .margin-y-md\@xl {
      margin-top: var(--space-md);
      margin-bottom: var(--space-md);
    }
    .margin-y-lg\@xl {
      margin-top: var(--space-lg);
      margin-bottom: var(--space-lg);
    }
    .margin-y-xl\@xl {
      margin-top: var(--space-xl);
      margin-bottom: var(--space-xl);
    }
    .margin-y-xxl\@xl {
      margin-top: var(--space-xxl);
      margin-bottom: var(--space-xxl);
    }
    .margin-y-xxxl\@xl {
      margin-top: var(--space-xxxl);
      margin-bottom: var(--space-xxxl);
    }
    .margin-y-xxxxl\@xl {
      margin-top: var(--space-xxxxl);
      margin-bottom: var(--space-xxxxl);
    }
    .margin-y-auto\@xl {
      margin-top: auto;
      margin-bottom: auto;
    }
    .margin-y-0\@xl {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  @supports (--css: variables) {
    .padding-xxxxs\@xl {
      padding: var(--space-xxxxs);
    }
    .padding-xxxs\@xl {
      padding: var(--space-xxxs);
    }
    .padding-xxs\@xl {
      padding: var(--space-xxs);
    }
    .padding-xs\@xl {
      padding: var(--space-xs);
    }
    .padding-sm\@xl {
      padding: var(--space-sm);
    }
    .padding-md\@xl {
      padding: var(--space-md);
    }
    .padding-lg\@xl {
      padding: var(--space-lg);
    }
    .padding-xl\@xl {
      padding: var(--space-xl);
    }
    .padding-xxl\@xl {
      padding: var(--space-xxl);
    }
    .padding-xxxl\@xl {
      padding: var(--space-xxxl);
    }
    .padding-xxxxl\@xl {
      padding: var(--space-xxxxl);
    }
    .padding-0\@xl {
      padding: 0;
    }
    .padding-component\@xl {
      padding: var(--component-padding);
    }
    .padding-top-xxxxs\@xl {
      padding-top: var(--space-xxxxs);
    }
    .padding-top-xxxs\@xl {
      padding-top: var(--space-xxxs);
    }
    .padding-top-xxs\@xl {
      padding-top: var(--space-xxs);
    }
    .padding-top-xs\@xl {
      padding-top: var(--space-xs);
    }
    .padding-top-sm\@xl {
      padding-top: var(--space-sm);
    }
    .padding-top-md\@xl {
      padding-top: var(--space-md);
    }
    .padding-top-lg\@xl {
      padding-top: var(--space-lg);
    }
    .padding-top-xl\@xl {
      padding-top: var(--space-xl);
    }
    .padding-top-xxl\@xl {
      padding-top: var(--space-xxl);
    }
    .padding-top-xxxl\@xl {
      padding-top: var(--space-xxxl);
    }
    .padding-top-xxxxl\@xl {
      padding-top: var(--space-xxxxl);
    }
    .padding-top-0\@xl {
      padding-top: 0;
    }
    .padding-top-component\@xl {
      padding-top: var(--component-padding);
    }
    .padding-bottom-xxxxs\@xl {
      padding-bottom: var(--space-xxxxs);
    }
    .padding-bottom-xxxs\@xl {
      padding-bottom: var(--space-xxxs);
    }
    .padding-bottom-xxs\@xl {
      padding-bottom: var(--space-xxs);
    }
    .padding-bottom-xs\@xl {
      padding-bottom: var(--space-xs);
    }
    .padding-bottom-sm\@xl {
      padding-bottom: var(--space-sm);
    }
    .padding-bottom-md\@xl {
      padding-bottom: var(--space-md);
    }
    .padding-bottom-lg\@xl {
      padding-bottom: var(--space-lg);
    }
    .padding-bottom-xl\@xl {
      padding-bottom: var(--space-xl);
    }
    .padding-bottom-xxl\@xl {
      padding-bottom: var(--space-xxl);
    }
    .padding-bottom-xxxl\@xl {
      padding-bottom: var(--space-xxxl);
    }
    .padding-bottom-xxxxl\@xl {
      padding-bottom: var(--space-xxxxl);
    }
    .padding-bottom-0\@xl {
      padding-bottom: 0;
    }
    .padding-bottom-component\@xl {
      padding-bottom: var(--component-padding);
    }
    .padding-right-xxxxs\@xl {
      padding-right: var(--space-xxxxs);
    }
    .padding-right-xxxs\@xl {
      padding-right: var(--space-xxxs);
    }
    .padding-right-xxs\@xl {
      padding-right: var(--space-xxs);
    }
    .padding-right-xs\@xl {
      padding-right: var(--space-xs);
    }
    .padding-right-sm\@xl {
      padding-right: var(--space-sm);
    }
    .padding-right-md\@xl {
      padding-right: var(--space-md);
    }
    .padding-right-lg\@xl {
      padding-right: var(--space-lg);
    }
    .padding-right-xl\@xl {
      padding-right: var(--space-xl);
    }
    .padding-right-xxl\@xl {
      padding-right: var(--space-xxl);
    }
    .padding-right-xxxl\@xl {
      padding-right: var(--space-xxxl);
    }
    .padding-right-xxxxl\@xl {
      padding-right: var(--space-xxxxl);
    }
    .padding-right-0\@xl {
      padding-right: 0;
    }
    .padding-right-component\@xl {
      padding-right: var(--component-padding);
    }
    .padding-left-xxxxs\@xl {
      padding-left: var(--space-xxxxs);
    }
    .padding-left-xxxs\@xl {
      padding-left: var(--space-xxxs);
    }
    .padding-left-xxs\@xl {
      padding-left: var(--space-xxs);
    }
    .padding-left-xs\@xl {
      padding-left: var(--space-xs);
    }
    .padding-left-sm\@xl {
      padding-left: var(--space-sm);
    }
    .padding-left-md\@xl {
      padding-left: var(--space-md);
    }
    .padding-left-lg\@xl {
      padding-left: var(--space-lg);
    }
    .padding-left-xl\@xl {
      padding-left: var(--space-xl);
    }
    .padding-left-xxl\@xl {
      padding-left: var(--space-xxl);
    }
    .padding-left-xxxl\@xl {
      padding-left: var(--space-xxxl);
    }
    .padding-left-xxxxl\@xl {
      padding-left: var(--space-xxxxl);
    }
    .padding-left-0\@xl {
      padding-left: 0;
    }
    .padding-left-component\@xl {
      padding-left: var(--component-padding);
    }
    .padding-x-xxxxs\@xl {
      padding-left: var(--space-xxxxs);
      padding-right: var(--space-xxxxs);
    }
    .padding-x-xxxs\@xl {
      padding-left: var(--space-xxxs);
      padding-right: var(--space-xxxs);
    }
    .padding-x-xxs\@xl {
      padding-left: var(--space-xxs);
      padding-right: var(--space-xxs);
    }
    .padding-x-xs\@xl {
      padding-left: var(--space-xs);
      padding-right: var(--space-xs);
    }
    .padding-x-sm\@xl {
      padding-left: var(--space-sm);
      padding-right: var(--space-sm);
    }
    .padding-x-md\@xl {
      padding-left: var(--space-md);
      padding-right: var(--space-md);
    }
    .padding-x-lg\@xl {
      padding-left: var(--space-lg);
      padding-right: var(--space-lg);
    }
    .padding-x-xl\@xl {
      padding-left: var(--space-xl);
      padding-right: var(--space-xl);
    }
    .padding-x-xxl\@xl {
      padding-left: var(--space-xxl);
      padding-right: var(--space-xxl);
    }
    .padding-x-xxxl\@xl {
      padding-left: var(--space-xxxl);
      padding-right: var(--space-xxxl);
    }
    .padding-x-xxxxl\@xl {
      padding-left: var(--space-xxxxl);
      padding-right: var(--space-xxxxl);
    }
    .padding-x-0\@xl {
      padding-left: 0;
      padding-right: 0;
    }
    .padding-x-component\@xl {
      padding-left: var(--component-padding);
      padding-right: var(--component-padding);
    }
    .padding-y-xxxxs\@xl {
      padding-top: var(--space-xxxxs);
      padding-bottom: var(--space-xxxxs);
    }
    .padding-y-xxxs\@xl {
      padding-top: var(--space-xxxs);
      padding-bottom: var(--space-xxxs);
    }
    .padding-y-xxs\@xl {
      padding-top: var(--space-xxs);
      padding-bottom: var(--space-xxs);
    }
    .padding-y-xs\@xl {
      padding-top: var(--space-xs);
      padding-bottom: var(--space-xs);
    }
    .padding-y-sm\@xl {
      padding-top: var(--space-sm);
      padding-bottom: var(--space-sm);
    }
    .padding-y-md\@xl {
      padding-top: var(--space-md);
      padding-bottom: var(--space-md);
    }
    .padding-y-lg\@xl {
      padding-top: var(--space-lg);
      padding-bottom: var(--space-lg);
    }
    .padding-y-xl\@xl {
      padding-top: var(--space-xl);
      padding-bottom: var(--space-xl);
    }
    .padding-y-xxl\@xl {
      padding-top: var(--space-xxl);
      padding-bottom: var(--space-xxl);
    }
    .padding-y-xxxl\@xl {
      padding-top: var(--space-xxxl);
      padding-bottom: var(--space-xxxl);
    }
    .padding-y-xxxxl\@xl {
      padding-top: var(--space-xxxxl);
      padding-bottom: var(--space-xxxxl);
    }
    .padding-y-0\@xl {
      padding-top: 0;
      padding-bottom: 0;
    }
    .padding-y-component\@xl {
      padding-top: var(--component-padding);
      padding-bottom: var(--component-padding);
    }
  }
  .text-center\@xl {
    text-align: center;
  }
  .text-left\@xl {
    text-align: left;
  }
  .text-right\@xl {
    text-align: right;
  }
  @supports (--css: variables) {
    .text-xs\@xl {
      font-size: var(--text-xs, 0.694em);
    }
    .text-sm\@xl {
      font-size: var(--text-sm, 0.833em);
    }
    .text-base\@xl {
      font-size: 1em;
    }
    .text-md\@xl {
      font-size: var(--text-md, 1.2em);
    }
    .text-lg\@xl {
      font-size: var(--text-lg, 1.44em);
    }
    .text-xl\@xl {
      font-size: var(--text-xl, 1.728em);
    }
    .text-xxl\@xl {
      font-size: var(--text-xxl, 2.074em);
    }
    .text-xxxl\@xl {
      font-size: var(--text-xxxl, 2.488em);
    }
  }
  .hide\@xl {
    display: none !important;
  }
}

@media not all and (min-width: 32rem) {
  .has-margin\@xs {
    margin: 0 !important;
  }
  .has-padding\@xs {
    padding: 0 !important;
  }
  .display\@xs {
    display: none !important;
  }
}

@media not all and (min-width: 48rem) {
  .has-margin\@sm {
    margin: 0 !important;
  }
  .has-padding\@sm {
    padding: 0 !important;
  }
  .display\@sm {
    display: none !important;
  }
}

@media not all and (min-width: 64rem) {
  .has-margin\@md {
    margin: 0 !important;
  }
  .has-padding\@md {
    padding: 0 !important;
  }
  .display\@md {
    display: none !important;
  }
}

@media not all and (min-width: 80rem) {
  .has-margin\@lg {
    margin: 0 !important;
  }
  .has-padding\@lg {
    padding: 0 !important;
  }
  .display\@lg {
    display: none !important;
  }
}

@media not all and (min-width: 90rem) {
  .has-margin\@xl {
    margin: 0 !important;
  }
  .has-padding\@xl {
    padding: 0 !important;
  }
  .display\@xl {
    display: none !important;
  }
}

:root, [data-theme="default"] {
  --color-primary-darker: hsl(357, 78%, 30%);
  --color-primary-darker-h: 357;
  --color-primary-darker-s: 78%;
  --color-primary-darker-l: 30%;
  --color-primary-dark: hsl(357, 78%, 40%);
  --color-primary-dark-h: 357;
  --color-primary-dark-s: 78%;
  --color-primary-dark-l: 40%;
  --color-primary: hsl(357, 78%, 50%);
  --color-primary-h: 357;
  --color-primary-s: 78%;
  --color-primary-l: 50%;
  --color-primary-light: hsl(357, 78%, 60%);
  --color-primary-light-h: 357;
  --color-primary-light-s: 78%;
  --color-primary-light-l: 60%;
  --color-primary-lighter: hsl(357, 78%, 70%);
  --color-primary-lighter-h: 357;
  --color-primary-lighter-s: 78%;
  --color-primary-lighter-l: 70%;
  --color-accent-darker: hsl(47, 91%, 32%);
  --color-accent-darker-h: 47;
  --color-accent-darker-s: 91%;
  --color-accent-darker-l: 32%;
  --color-accent-dark: hsl(47, 91%, 42%);
  --color-accent-dark-h: 47;
  --color-accent-dark-s: 91%;
  --color-accent-dark-l: 42%;
  --color-accent: hsl(47, 91%, 52%);
  --color-accent-h: 47;
  --color-accent-s: 91%;
  --color-accent-l: 52%;
  --color-accent-light: hsl(47, 91%, 62%);
  --color-accent-light-h: 47;
  --color-accent-light-s: 91%;
  --color-accent-light-l: 62%;
  --color-accent-lighter: hsl(47, 91%, 72%);
  --color-accent-lighter-h: 47;
  --color-accent-lighter-s: 91%;
  --color-accent-lighter-l: 72%;
  --color-black: hsl(240, 8%, 12%);
  --color-black-h: 240;
  --color-black-s: 8%;
  --color-black-l: 12%;
  --color-white: hsl(0, 0%, 100%);
  --color-white-h: 0;
  --color-white-s: 0%;
  --color-white-l: 100%;
  --color-warning-darker: hsl(46, 100%, 41%);
  --color-warning-darker-h: 46;
  --color-warning-darker-s: 100%;
  --color-warning-darker-l: 41%;
  --color-warning-dark: hsl(46, 100%, 51%);
  --color-warning-dark-h: 46;
  --color-warning-dark-s: 100%;
  --color-warning-dark-l: 51%;
  --color-warning: hsl(46, 100%, 61%);
  --color-warning-h: 46;
  --color-warning-s: 100%;
  --color-warning-l: 61%;
  --color-warning-light: hsl(46, 100%, 71%);
  --color-warning-light-h: 46;
  --color-warning-light-s: 100%;
  --color-warning-light-l: 71%;
  --color-warning-lighter: hsl(46, 100%, 81%);
  --color-warning-lighter-h: 46;
  --color-warning-lighter-s: 100%;
  --color-warning-lighter-l: 81%;
  --color-success-darker: hsl(94, 48%, 36%);
  --color-success-darker-h: 94;
  --color-success-darker-s: 48%;
  --color-success-darker-l: 36%;
  --color-success-dark: hsl(94, 48%, 46%);
  --color-success-dark-h: 94;
  --color-success-dark-s: 48%;
  --color-success-dark-l: 46%;
  --color-success: hsl(94, 48%, 56%);
  --color-success-h: 94;
  --color-success-s: 48%;
  --color-success-l: 56%;
  --color-success-light: hsl(94, 48%, 66%);
  --color-success-light-h: 94;
  --color-success-light-s: 48%;
  --color-success-light-l: 66%;
  --color-success-lighter: hsl(94, 48%, 76%);
  --color-success-lighter-h: 94;
  --color-success-lighter-s: 48%;
  --color-success-lighter-l: 76%;
  --color-error-darker: hsl(349, 75%, 31%);
  --color-error-darker-h: 349;
  --color-error-darker-s: 75%;
  --color-error-darker-l: 31%;
  --color-error-dark: hsl(349, 75%, 41%);
  --color-error-dark-h: 349;
  --color-error-dark-s: 75%;
  --color-error-dark-l: 41%;
  --color-error: hsl(349, 75%, 51%);
  --color-error-h: 349;
  --color-error-s: 75%;
  --color-error-l: 51%;
  --color-error-light: hsl(349, 75%, 61%);
  --color-error-light-h: 349;
  --color-error-light-s: 75%;
  --color-error-light-l: 61%;
  --color-error-lighter: hsl(349, 75%, 71%);
  --color-error-lighter-h: 349;
  --color-error-lighter-s: 75%;
  --color-error-lighter-l: 71%;
  --color-bg: hsl(0, 0%, 100%);
  --color-bg-h: 0;
  --color-bg-s: 0%;
  --color-bg-l: 100%;
  --color-contrast-lower: hsl(0, 0%, 95%);
  --color-contrast-lower-h: 0;
  --color-contrast-lower-s: 0%;
  --color-contrast-lower-l: 95%;
  --color-contrast-low: hsl(240, 1%, 83%);
  --color-contrast-low-h: 240;
  --color-contrast-low-s: 1%;
  --color-contrast-low-l: 83%;
  --color-contrast-medium: hsl(240, 1%, 48%);
  --color-contrast-medium-h: 240;
  --color-contrast-medium-s: 1%;
  --color-contrast-medium-l: 48%;
  --color-contrast-high: hsl(240, 4%, 20%);
  --color-contrast-high-h: 240;
  --color-contrast-high-s: 4%;
  --color-contrast-high-l: 20%;
  --color-contrast-higher: hsl(240, 8%, 12%);
  --color-contrast-higher-h: 240;
  --color-contrast-higher-s: 8%;
  --color-contrast-higher-l: 12%;
}

@supports (--css: variables) {
  [data-theme="black"] {
    --color-primary-darker: hsl(357, 78%, 30%);
    --color-primary-darker-h: 357;
    --color-primary-darker-s: 78%;
    --color-primary-darker-l: 30%;
    --color-primary-dark: hsl(357, 78%, 40%);
    --color-primary-dark-h: 357;
    --color-primary-dark-s: 78%;
    --color-primary-dark-l: 40%;
    --color-primary: hsl(357, 78%, 50%);
    --color-primary-h: 357;
    --color-primary-s: 78%;
    --color-primary-l: 50%;
    --color-primary-light: hsl(357, 78%, 60%);
    --color-primary-light-h: 357;
    --color-primary-light-s: 78%;
    --color-primary-light-l: 60%;
    --color-primary-lighter: hsl(357, 78%, 70%);
    --color-primary-lighter-h: 357;
    --color-primary-lighter-s: 78%;
    --color-primary-lighter-l: 70%;
    --color-accent-darker: hsl(47, 91%, 32%);
    --color-accent-darker-h: 47;
    --color-accent-darker-s: 91%;
    --color-accent-darker-l: 32%;
    --color-accent-dark: hsl(47, 91%, 42%);
    --color-accent-dark-h: 47;
    --color-accent-dark-s: 91%;
    --color-accent-dark-l: 42%;
    --color-accent: hsl(47, 91%, 52%);
    --color-accent-h: 47;
    --color-accent-s: 91%;
    --color-accent-l: 52%;
    --color-accent-light: hsl(47, 91%, 62%);
    --color-accent-light-h: 47;
    --color-accent-light-s: 91%;
    --color-accent-light-l: 62%;
    --color-accent-lighter: hsl(47, 91%, 72%);
    --color-accent-lighter-h: 47;
    --color-accent-lighter-s: 91%;
    --color-accent-lighter-l: 72%;
    --color-black: hsl(240, 8%, 12%);
    --color-black-h: 240;
    --color-black-s: 8%;
    --color-black-l: 12%;
    --color-white: hsl(0, 0%, 100%);
    --color-white-h: 0;
    --color-white-s: 0%;
    --color-white-l: 100%;
    --color-warning-darker: hsl(46, 100%, 41%);
    --color-warning-darker-h: 46;
    --color-warning-darker-s: 100%;
    --color-warning-darker-l: 41%;
    --color-warning-dark: hsl(46, 100%, 51%);
    --color-warning-dark-h: 46;
    --color-warning-dark-s: 100%;
    --color-warning-dark-l: 51%;
    --color-warning: hsl(46, 100%, 61%);
    --color-warning-h: 46;
    --color-warning-s: 100%;
    --color-warning-l: 61%;
    --color-warning-light: hsl(46, 100%, 71%);
    --color-warning-light-h: 46;
    --color-warning-light-s: 100%;
    --color-warning-light-l: 71%;
    --color-warning-lighter: hsl(46, 100%, 81%);
    --color-warning-lighter-h: 46;
    --color-warning-lighter-s: 100%;
    --color-warning-lighter-l: 81%;
    --color-success-darker: hsl(94, 48%, 36%);
    --color-success-darker-h: 94;
    --color-success-darker-s: 48%;
    --color-success-darker-l: 36%;
    --color-success-dark: hsl(94, 48%, 46%);
    --color-success-dark-h: 94;
    --color-success-dark-s: 48%;
    --color-success-dark-l: 46%;
    --color-success: hsl(94, 48%, 56%);
    --color-success-h: 94;
    --color-success-s: 48%;
    --color-success-l: 56%;
    --color-success-light: hsl(94, 48%, 66%);
    --color-success-light-h: 94;
    --color-success-light-s: 48%;
    --color-success-light-l: 66%;
    --color-success-lighter: hsl(94, 48%, 76%);
    --color-success-lighter-h: 94;
    --color-success-lighter-s: 48%;
    --color-success-lighter-l: 76%;
    --color-error-darker: hsl(349, 75%, 31%);
    --color-error-darker-h: 349;
    --color-error-darker-s: 75%;
    --color-error-darker-l: 31%;
    --color-error-dark: hsl(349, 75%, 41%);
    --color-error-dark-h: 349;
    --color-error-dark-s: 75%;
    --color-error-dark-l: 41%;
    --color-error: hsl(349, 75%, 51%);
    --color-error-h: 349;
    --color-error-s: 75%;
    --color-error-l: 51%;
    --color-error-light: hsl(349, 75%, 61%);
    --color-error-light-h: 349;
    --color-error-light-s: 75%;
    --color-error-light-l: 61%;
    --color-error-lighter: hsl(349, 75%, 71%);
    --color-error-lighter-h: 349;
    --color-error-lighter-s: 75%;
    --color-error-lighter-l: 71%;
    --color-bg: hsl(240, 8%, 12%);
    --color-bg-h: 240;
    --color-bg-s: 8%;
    --color-bg-l: 12%;
    --color-contrast-lower: hsl(240, 6%, 15%);
    --color-contrast-lower-h: 240;
    --color-contrast-lower-s: 6%;
    --color-contrast-lower-l: 15%;
    --color-contrast-low: hsl(252, 4%, 25%);
    --color-contrast-low-h: 252;
    --color-contrast-low-s: 4%;
    --color-contrast-low-l: 25%;
    --color-contrast-medium: hsl(240, 1%, 57%);
    --color-contrast-medium-h: 240;
    --color-contrast-medium-s: 1%;
    --color-contrast-medium-l: 57%;
    --color-contrast-high: hsl(0, 0%, 89%);
    --color-contrast-high-h: 0;
    --color-contrast-high-s: 0%;
    --color-contrast-high-l: 89%;
    --color-contrast-higher: hsl(0, 0%, 100%);
    --color-contrast-higher-h: 0;
    --color-contrast-higher-s: 0%;
    --color-contrast-higher-l: 100%;
  }
}

:root {
  --space-unit: 1em;
  --space-xxxxs: calc(0.125 * var(--space-unit));
  --space-xxxs: calc(0.25 * var(--space-unit));
  --space-xxs: calc(0.375 * var(--space-unit));
  --space-xs: calc(0.5 * var(--space-unit));
  --space-sm: calc(0.75 * var(--space-unit));
  --space-md: calc(1.25 * var(--space-unit));
  --space-lg: calc(2 * var(--space-unit));
  --space-xl: calc(3.25 * var(--space-unit));
  --space-xxl: calc(5.25 * var(--space-unit));
  --space-xxxl: calc(8.5 * var(--space-unit));
  --space-xxxxl: calc(13.75 * var(--space-unit));
  --component-padding: var(--space-md);
}

@supports (--css: variables) {
  @media (min-width: 64rem) {
    :root {
      --space-unit: 1.3em;
      --space-xxxxs: calc(0.09 * var(--space-unit));
      --space-xxxs: calc(0.146 * var(--space-unit));
      --space-xxs: calc(0.236 * var(--space-unit));
      --space-xs: calc(0.382 * var(--space-unit));
      --space-sm: calc(0.618 * var(--space-unit));
      --space-md: calc(1 * var(--space-unit));
      --space-lg: calc(1.618 * var(--space-unit));
      --space-xl: calc(2.618 * var(--space-unit));
      --space-xxl: calc(4.236 * var(--space-unit));
      --space-xxxl: calc(6.854 * var(--space-unit));
      --space-xxxxl: calc(11.08 * var(--space-unit));
    }
  }
}

:root {
  --font-primary: Muli, sans-serif;
  --font-secondary: Open sans, sans-serif;
  --text-base-size: 1em;
  --text-scale-ratio: 1.2;
  --text-xs: calc((1em / var(--text-scale-ratio)) / var(--text-scale-ratio));
  --text-sm: calc(var(--text-xs) * var(--text-scale-ratio));
  --text-md: calc(var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));
  --body-line-height: 1.4;
  --heading-line-height: 1.2;
  --font-primary-capital-letter: 1;
  --font-secondary-capital-letter: 1;
}

@supports (--css: variables) {
  @media (min-width: 64rem) {
    :root {
      --text-base-size: 1.25em;
      --text-scale-ratio: 1.25;
    }
  }
}

body {
  font-family: var(--font-secondary);
}

h1, h2, h3, h4 {
  font-family: var(--font-primary);
  font-weight: 900;
}

.font-primary {
  font-family: var(--font-primary);
}

.font-secondary {
  font-family: var(--font-secondary);
}

/* -------------------------------- 

File#: _1_text-background-effects
Title: Text Background Effects
Descr: A collection of text backgrounds animated on hover
Usage: codyhouse.co/license

-------------------------------- */
.text-bg-fx {
  background-repeat: no-repeat;
  will-change: background-size;
  transition: background-size 0.3s var(--ease-in-out);
}

.text-bg-fx:hover {
  background-size: 100% 100%;
}

.text-bg-fx--scale-x {
  padding: var(--space-xxxxs) 0;
  background-image: linear-gradient(hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2), hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2));
  background-size: 0% 100%;
}

.text-bg-fx--scale-y {
  text-decoration: none;
  background-image: linear-gradient(hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2), hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2));
  background-size: 100% 2px;
  background-position: center bottom;
}

.text-bg-fx--underline,
.text-bg-fx--underline-bold {
  text-decoration: none;
  background-size: 0% 100%;
}

.text-bg-fx--underline {
  background-image: linear-gradient(transparent calc(100% - 3px), currentColor calc(100% - 3px), currentColor calc(100% - 2px), transparent 2px);
}

.text-bg-fx--underline-bold {
  background-image: linear-gradient(transparent 50%, hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2) 50%);
}

.text-bg-fx--text-shadow {
  text-shadow: 1.5px 0 var(--color-bg), -1.5px 0 var(--color-bg);
}

.title--script {
  font-family: 'Caveat', cursive !important;
}

.no-underline {
  text-decoration: none;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

/* -------------------------------- 

File#: _1_reveal-effects
Title: Reveal Effects
Descr: A collection of reveal effects targeting specific elements as they enter the viewport
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --reveal-fx-duration: 0.6s;
  --reveal-fx-timing-function: var(--ease-out);
}

@media (min-width: 64rem) {
  .js .reveal-fx {
    opacity: 0;
    transition: all var(--reveal-fx-duration) var(--reveal-fx-timing-function);
  }
  .js .reveal-fx::before {
    display: none;
    content: 'reveal-fx';
  }
  .js .reveal-fx--translate, .js .reveal-fx--translate-up {
    transform: translateY(10%);
  }
  .js .reveal-fx--translate-right {
    transform: translateX(-10%);
  }
  .js .reveal-fx--translate-left {
    transform: translateX(10%);
  }
  .js .reveal-fx--translate-down {
    transform: translateY(-10%);
  }
  .js .reveal-fx--scale {
    transform: scale(0.8);
  }
  .js .reveal-fx--scale-up {
    transform: translateY(10%) scale(0.8);
  }
  .js .reveal-fx--scale-right {
    transform: translateX(-10%) scale(0.8);
  }
  .js .reveal-fx--scale-left {
    transform: translateX(10%) scale(0.8);
  }
  .js .reveal-fx--scale-down {
    transform: translateY(-10%) scale(0.8);
  }
  .js .reveal-fx--rotate, .js .reveal-fx--rotate-down, .js .reveal-fx--rotate-right, .js .reveal-fx--rotate-left, .js .reveal-fx--rotate-up {
    perspective: 1000px;
  }
  .js .reveal-fx--rotate > *, .js .reveal-fx--rotate-down > *, .js .reveal-fx--rotate-right > *, .js .reveal-fx--rotate-left > *, .js .reveal-fx--rotate-up > * {
    transition: transform var(--reveal-fx-duration) var(--reveal-fx-timing-function);
  }
  .js .reveal-fx--rotate > *, .js .reveal-fx--rotate-down > * {
    transform-origin: top;
    transform: rotateX(-45deg);
  }
  .js .reveal-fx--rotate-right > * {
    transform-origin: left center;
    transform: rotateY(45deg);
  }
  .js .reveal-fx--rotate-left > * {
    transform-origin: right center;
    transform: rotateY(-45deg);
  }
  .js .reveal-fx--rotate-up > * {
    transform-origin: bottom;
    transform: rotateX(45deg);
  }
  .js .reveal-fx--text-mask {
    overflow: hidden;
    display: inline-block;
  }
  .js .reveal-fx--text-mask > * {
    display: block;
    transform: translateY(100%);
    transition: transform var(--reveal-fx-duration) var(--reveal-fx-timing-function);
    will-change: transform;
  }
  .js [class*="reveal-fx--translate"], .js [class*="reveal-fx--scale"] {
    will-change: opacity, transform;
  }
  .js .reveal-fx--text-mask > *, .js [class*="reveal-fx--rotate"] > * {
    will-change: transform;
  }
  .js .reveal-fx--is-visible {
    opacity: 1;
  }
  .js .reveal-fx--is-visible[class*="reveal-fx--translate"], .js .reveal-fx--is-visible[class*="reveal-fx--scale"],
  .js .reveal-fx--is-visible[class*="reveal-fx--rotate"] > *,
  .js .reveal-fx--is-visible.reveal-fx--text-mask > * {
    transform: translate(0);
  }
}

:root {
  --btn-font-size: 1em;
  --btn-font-size-sm: 0.8em;
  --btn-font-size-md: 1.2em;
  --btn-font-size-lg: 1.4em;
  --btn-padding-x: var(--space-md);
  --btn-padding-y: var(--space-xs);
  --btn-radius: 0;
}

.btn {
  cursor: pointer;
  font-family: var(--font-primary);
  font-weight: 800;
  text-decoration: none;
  line-height: 1;
  box-shadow: 0px 2px 8px 0 hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.12), 0px 1px 1px 0 hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.12);
  transition: all 0.3s ease;
}

.btn:hover {
  box-shadow: 0px 1px 2px 0 hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.12), 0px 1px 1px 0 hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.12);
}

.btn:focus {
  box-shadow: 0px 0px 0px 2px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.2);
  outline: none;
}

.btn:active {
  transform: translateY(2px);
}

.btn--primary {
  background-color: var(--color-primary);
  color: var(--color-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn--primary:hover {
  background-color: var(--color-primary-dark);
}

.btn--primary:focus {
  box-shadow: 0px 0px 0px 2px hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2);
}

.btn--subtle {
  background-color: var(--color-contrast-lower);
  color: var(--color-contrast-higher);
}

.btn--accent {
  background-color: var(--color-accent);
  color: var(--color-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn--accent:hover {
  background-color: var(--color-accent-dark);
}

.btn--accent:focus {
  box-shadow: 0px 0px 0px 2px hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), 0.2);
}

.btn--disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.arrow {
  transition: all 0.5s !important;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 73px;
  height: 2px;
  background: currentColor;
  margin-left: 14px;
}

.arrow:before {
  content: "";
  position: absolute;
  right: 1px;
  width: 15px;
  height: 15px;
  border: 2px solid currentColor;
  border-left: 0;
  border-bottom: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -6px;
}

.arrow:hover {
  width: 80px;
}

/* -------------------------------- 

File#: _1_btn-slide-fx
Title: Button Slide Effect
Descr: Button w/ animated background that slides-in on :hover
Usage: codyhouse.co/license

-------------------------------- */
.btn--slide-fx {
  position: relative;
  overflow: hidden;
}

.btn--slide-fx .btn__label, .btn--slide-fx .icon {
  position: relative;
  z-index: 2;
}

.btn--slide-fx::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-contrast-higher);
  z-index: 1;
  transform: scaleX(0) scaleY(1);
}

.btn--slide-fx:not(.js-btn--slide-fx)::before,
.btn--slide-fx.btn-slide-fx-animate::before {
  transform-origin: right center;
  transition: transform 0.3s var(--ease-in-out);
}

.btn--slide-fx:not(.js-btn--slide-fx):hover::before,
.btn--slide-fx.btn-slide-fx-hover::before {
  transform: scaleX(1) scaleY(1);
  transform-origin: left center;
}

.btn--slide-fx-from-right:not(.js-btn--slide-fx)::before,
.btn--slide-fx-from-right.btn-slide-fx-animate::before {
  transform-origin: left center;
}

.btn--slide-fx-from-right:not(.js-btn--slide-fx):hover::before,
.btn--slide-fx-from-right.btn-slide-fx-hover::before {
  transform-origin: right center;
}

.btn--slide-fx-from-bottom::before,
.btn--slide-fx-from-top::before {
  transform: scaleX(1) scaleY(0);
}

.btn--slide-fx-from-bottom:not(.js-btn--slide-fx):hover::before,
.btn--slide-fx-from-top:not(.js-btn--slide-fx):hover::before,
.btn--slide-fx-from-bottom.btn-slide-fx-hover
.btn--slide-fx-from-top.btn-slide-fx-hover::before {
  transform: scaleX(1) scaleY(1);
}

.btn--slide-fx-from-bottom:not(.js-btn--slide-fx)::before,
.btn--slide-fx-from-bottom.btn-slide-fx-animate::before {
  transform-origin: center top;
}

.btn--slide-fx-from-bottom:not(.js-btn--slide-fx):hover::before,
.btn--slide-fx-from-bottom.btn-slide-fx-hover::before {
  transform-origin: center bottom;
}

.btn--slide-fx-from-top:not(.js-btn--slide-fx)::before,
.btn--slide-fx-from-top.btn-slide-fx-animate::before {
  transform-origin: center bottom;
}

.btn--slide-fx-from-top:not(.js-btn--slide-fx):hover::before,
.btn--slide-fx-from-top.btn-slide-fx-hover::before {
  transform-origin: center top;
}

.btn--slide-fx-to-left::before,
.btn--slide-fx-to-right::before,
.btn--slide-fx-to-top::before,
.btn--slide-fx-to-bottom::before {
  transform: scaleX(1) scaleY(1);
}

.btn--slide-fx-to-left.btn-slide-fx-hover-out::before,
.btn--slide-fx-to-right.btn-slide-fx-hover-out::before {
  transform: scaleX(0) scaleY(1);
}

.btn--slide-fx-to-top.btn-slide-fx-hover-out::before,
.btn--slide-fx-to-bottom.btn-slide-fx-hover-out::before {
  transform: scaleX(1) scaleY(0);
}

.btn--slide-fx-to-left.btn-slide-fx-animate::before {
  transform-origin: left center;
}

.btn--slide-fx-to-right.btn-slide-fx-animate::before {
  transform-origin: right center;
}

.btn--slide-fx-to-top.btn-slide-fx-animate::before {
  transform-origin: center top;
}

.btn--slide-fx-to-bottom.btn-slide-fx-animate::before {
  transform-origin: center bottom;
}

.btn--slide-fx-replace-label .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.3s 0.1s, opacity 0.3s var(--ease-out);
}

.btn--slide-fx-replace-label:hover .icon {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

:root {
  --form-control-padding-x: var(--space-sm);
  --form-control-padding-y: var(--space-xs);
  --form-control-radius: 0.25em;
}

.form-control {
  background-color: var(--color-bg);
  border: 2px solid var(--color-contrast-low);
}

.form-control::-webkit-input-placeholder {
  color: var(--color-contrast-medium);
}

.form-control::-moz-placeholder {
  color: var(--color-contrast-medium);
}

.form-control:-ms-input-placeholder {
  color: var(--color-contrast-medium);
}

.form-control:-moz-placeholder {
  color: var(--color-contrast-medium);
}

.form-control:focus {
  border-color: var(--color-primary);
  box-shadow: 0px 0px 0px 3px hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2);
  outline: none;
}

.form-control[disabled], .form-control[readonly] {
  cursor: not-allowed;
}

.form-control[aria-invalid="true"] {
  border-color: var(--color-error);
}

.form-control[aria-invalid="true"]:focus {
  box-shadow: 0px 0px 0px 3px hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), 0.2);
}

.form-legend {
  color: var(--color-contrast-higher);
  font-size: var(--text-md);
  line-height: 1.2;
  margin-bottom: var(--space-xxs);
}

.form-label {
  font-size: var(--text-sm);
}

/* -------------------------------- 

File#: _1_alert
Title: Alert
Descr: Feedback message
Usage: codyhouse.co/license

-------------------------------- */
.alert {
  padding: var(--space-xs) var(--space-sm);
  background-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2);
  border-radius: var(--radius-md);
  color: var(--color-contrast-higher);
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
}

.alert__link {
  color: inherit;
  text-decoration: underline;
}

.alert__close-btn {
  display: inline-block;
  flex-shrink: 0;
  margin-left: var(--space-sm);
}

.alert__close-btn .icon {
  display: block;
}

.alert--success {
  background-color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), 0.2);
}

.alert--error {
  background-color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), 0.2);
}

.alert--warning {
  background-color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), 0.2);
}

.alert--is-visible {
  position: static;
  clip: auto;
  clip-path: none;
}

html {
  scroll-behavior: smooth;
}

/* -------------------------------- 

File#: _2_flexi-header
Title: Flexi Header
Descr: Customizable header template
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --f-header-height: 50px;
  --f-header-logo-width: 180px;
}

@media (min-width: 64rem) {
  :root {
    --f-header-height: 100px;
  }
}

.f-header {
  position: relative;
  height: var(--f-header-height);
  z-index: var(--zindex-header);
}

.f-header__mobile-content {
  position: relative;
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
}

.f-header__logo {
  display: block;
  width: var(--f-header-logo-width);
  flex-shrink: 0;
  text-decoration: none;
}

.f-header__logo svg, .f-header__logo img {
  display: block;
}

.f-header__nav-control {
  --anim-menu-btn-size: 40px;
  --anim-menu-btn-icon-size: 28px;
  --anim-menu-btn-icon-stroke: 2px;
}

.f-header__nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  padding: 0 0 var(--space-md);
  background-color: var(--color-bg);
  box-shadow: var(--shadow-md);
  font-size: var(--text-md);
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-1em);
}

.f-header__nav::before {
  content: '';
  display: block;
  position: sticky;
  top: 0;
  height: var(--f-header-height);
  background-color: inherit;
  border-bottom: 1px solid var(--color-contrast-lower);
}

.f-header__nav--is-visible {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: visibility 0s, opacity .3s, transform .3s;
}

.f-header__nav-logo-wrapper {
  display: none;
}

.f-header__item {
  flex-shrink: 0;
  border-bottom: 1px solid var(--color-contrast-lower);
}

.f-header__dropdown-icon {
  display: none;
}

.f-header__link,
.f-header__dropdown-link {
  display: block;
  display: flex;
  align-items: center;
  color: var(--color-contrast-high);
  text-decoration: none;
  padding: var(--space-xs) 0;
  font-weight: bold;
  font-family: var(--font-primary);
}

.f-header__link:hover, .f-header__link[aria-current="page"],
.f-header__dropdown-link:hover,
.f-header__dropdown-link[aria-current="page"] {
  color: var(--color-primary);
}

.f-header__link .icon,
.f-header__dropdown-link .icon {
  flex-shrink: 0;
}

.f-header__btn,
.f-header__form-control {
  width: 100%;
  margin: var(--space-xs) 0;
}

.f-header__dropdown {
  padding-left: var(--space-md);
}

@media (min-width: 64rem) {
  .f-header__mobile-content {
    display: none;
  }
  .f-header__nav {
    position: static;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition: none;
    max-height: none;
    overflow: visible;
    overscroll-behavior: auto;
    height: var(--f-header-height);
    font-size: 0.875em;
  }
  .f-header__nav::before {
    display: none;
  }
  .f-header__nav-logo-wrapper {
    display: block;
    display: flex;
  }
  .f-header__nav-grid,
  .f-header__list {
    display: flex;
    align-items: center;
  }
  .f-header__nav-grid {
    height: 100%;
  }
  .f-header__item {
    display: inline-block;
    position: relative;
    border-bottom: none;
    margin-right: var(--space-xs);
  }
  .f-header__item:last-child {
    margin-right: 0;
  }
  .f-header__dropdown-icon {
    display: block;
    margin-left: var(--space-xxxxs);
    width: 12px;
    height: 12px;
    flex-shrink: 0;
  }
  .f-header__link {
    padding: var(--space-xxxs) var(--space-xxs);
  }
  .f-header__link--icon span {
    display: none;
  }
  .f-header__btn,
  .f-header__form-control {
    margin: 0;
  }
  .f-header__dropdown {
    font-size: 1rem;
    position: absolute;
    top: 100%;
    width: 200px;
    left: calc(50% - 100px);
    padding: var(--space-xxxs) 0;
    background-color: var(--color-bg);
    border-radius: var(--radius-md);
    box-shadow: var(--shadow-md);
    z-index: var(--zindex-popover);
    visibility: hidden;
    opacity: 0;
    transition: visibility .2s .2s, opacity .2s 0s;
  }
  .f-header__item:hover .f-header__dropdown {
    visibility: visible;
    opacity: 1;
    transition: visibility .2s 0s, opacity .2s 0s;
  }
  .f-header__dropdown-link {
    color: var(--color-contrast-high);
    padding: var(--space-xs) var(--space-sm);
  }
  .f-header__dropdown-link:hover {
    background-color: var(--color-contrast-lower);
  }
}

html:not(.js) .f-header__nav-control {
  display: none;
}

html:not(.js) .f-header__nav {
  position: static;
  padding: var(--space-xxs) 0 var(--space-md);
  box-shadow: none;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

/* -------------------------------- 

File#: _1_anim-menu-btn
Title: Animated Menu Button
Descr: A menu button w/ a morphing icon
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --anim-menu-btn-size: 48px;
  --anim-menu-btn-icon-size: 32px;
  --anim-menu-btn-icon-stroke: 2px;
}

.anim-menu-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--anim-menu-btn-size);
  height: var(--anim-menu-btn-size);
  color: var(--color-contrast-high);
}

.anim-menu-btn__icon {
  position: relative;
  display: block;
  font-size: var(--anim-menu-btn-icon-size);
  width: 1em;
  height: var(--anim-menu-btn-icon-stroke);
  color: inherit;
  background-image: linear-gradient(currentColor, currentColor);
  background-repeat: no-repeat;
  transform: scale(1);
}

.anim-menu-btn__icon::before, .anim-menu-btn__icon::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: inherit;
  border-radius: inherit;
}

.anim-menu-btn__icon--close {
  background-size: 100% 100%;
  will-change: transform, background-size;
  transition: transform .2s, background-size .2s;
}

.anim-menu-btn:active .anim-menu-btn__icon--close {
  transform: scale(0.9);
}

.anim-menu-btn__icon--close::before, .anim-menu-btn__icon--close::after {
  will-change: inherit;
  transition: inherit;
}

.anim-menu-btn__icon--close::before {
  transform: translateY(-0.25em) rotate(0);
}

.anim-menu-btn__icon--close::after {
  transform: translateY(0.25em) rotate(0);
}

.anim-menu-btn--state-b .anim-menu-btn__icon--close {
  background-size: 0% 100%;
}

.anim-menu-btn--state-b .anim-menu-btn__icon--close::before {
  transform: translateY(0) rotate(45deg);
}

.anim-menu-btn--state-b .anim-menu-btn__icon--close::after {
  transform: translateY(0) rotate(-45deg);
}

.anim-menu-btn__icon--arrow-left,
.anim-menu-btn__icon--arrow-right,
.anim-menu-btn__icon--arrow-up,
.anim-menu-btn__icon--arrow-down {
  border-radius: 50em;
  will-change: transform;
  transition: transform .2s;
}

.anim-menu-btn:active .anim-menu-btn__icon--arrow-left, .anim-menu-btn:active
.anim-menu-btn__icon--arrow-right, .anim-menu-btn:active
.anim-menu-btn__icon--arrow-up, .anim-menu-btn:active
.anim-menu-btn__icon--arrow-down {
  transform: scale(0.9);
}

.anim-menu-btn__icon--arrow-left::before, .anim-menu-btn__icon--arrow-left::after,
.anim-menu-btn__icon--arrow-right::before,
.anim-menu-btn__icon--arrow-right::after,
.anim-menu-btn__icon--arrow-up::before,
.anim-menu-btn__icon--arrow-up::after,
.anim-menu-btn__icon--arrow-down::before,
.anim-menu-btn__icon--arrow-down::after {
  transform-origin: calc(var(--anim-menu-btn-icon-stroke)/2) 50%;
  will-change: transform, width;
  transition: transform .2s, width .2s;
}

.anim-menu-btn__icon--arrow-left::before,
.anim-menu-btn__icon--arrow-right::before,
.anim-menu-btn__icon--arrow-up::before,
.anim-menu-btn__icon--arrow-down::before {
  transform: translateY(-0.25em) rotate(0);
}

.anim-menu-btn__icon--arrow-left::after,
.anim-menu-btn__icon--arrow-right::after,
.anim-menu-btn__icon--arrow-up::after,
.anim-menu-btn__icon--arrow-down::after {
  transform: translateY(0.25em) rotate(0);
}

.anim-menu-btn__icon--arrow-right {
  transform: rotate(180deg);
}

.anim-menu-btn:active .anim-menu-btn__icon--arrow-right {
  transform: rotate(180deg) scale(0.9);
}

.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-left::before, .anim-menu-btn--state-b .anim-menu-btn__icon--arrow-left::after,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-right::before,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-right::after,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-up::before,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-up::after,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-down::before,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-down::after {
  width: 50%;
}

.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-left::before,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-right::before,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-up::before,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-down::before {
  transform: translateY(0) rotate(-45deg);
}

.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-left::after,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-right::after,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-up::after,
.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-down::after {
  transform: translateY(0) rotate(45deg);
}

.anim-menu-btn--state-b:active .anim-menu-btn__icon--arrow-up {
  transform: rotate(90deg) scale(0.9);
}

.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-up {
  transform: rotate(90deg);
}

.anim-menu-btn--state-b:active .anim-menu-btn__icon--arrow-down {
  transform: rotate(-90deg) scale(0.9);
}

.anim-menu-btn--state-b .anim-menu-btn__icon--arrow-down {
  transform: rotate(-90deg);
}

/* -------------------------------- 

File#: _1_section-divider
Title: Section divider
Descr: A collection of SVG section dividers.
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --section-divider-width: 1920;
  --section-divider-height: 60;
  --section-divider-ratio: calc(100% * var(--section-divider-height) / var(--section-divider-width));
}

[class*="has-section-divider"] {
  position: relative;
}

.has-section-dividers {
  padding-top: var(--section-divider-ratio);
  padding-bottom: var(--section-divider-ratio);
}

.has-section-dividers .section-divider:first-child {
  bottom: auto;
  top: -1px;
  transform: scaleY(-1);
}

.has-section-divider-top {
  padding-top: var(--section-divider-ratio);
}

.has-section-divider-top .section-divider {
  bottom: auto;
  top: -1px;
  transform: scaleY(-1);
}

.has-section-divider-bottom {
  padding-bottom: var(--section-divider-ratio);
}

.has-bg-image {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
}

.has-bg-image .bg-overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -1;
  background-color: rgba(var(--color-primary-light), 0.2);
}

.section-divider {
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  height: auto;
  width: 100%;
}

.hero {
  display: flex;
  align-items: center;
  background-color: var(--color-bg);
  min-height: 380px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@media (min-width: 64rem) {
  .hero {
    min-height: 540px;
  }
}

.hero__label {
  color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), 0.6);
  font-size: var(--text-sm);
}

.hero--overlay-layer {
  position: relative;
}

.hero--overlay-layer .hero__content {
  position: relative;
  z-index: 2;
}

.hero--overlay-layer::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), 0.65);
  z-index: 1;
}

.hero--full-screen {
  height: 100vh;
}

/* -------------------------------- 

File#: _2_contact-v3
Title: Contact v3
Descr: Contact block w/ info about how to get in touch
Usage: codyhouse.co/license

-------------------------------- */
/* -------------------------------- 

File#: _1_details-list-v2
Title: Details List v2
Descr: Pairs of related information displayed in a list
Usage: codyhouse.co/license

-------------------------------- */
.details-list-v2__item {
  padding: var(--space-md) 0;
  border-bottom: 1px solid var(--color-contrast-low);
}

.details-list-v2__item > * {
  margin-bottom: var(--space-xxs);
}

.details-list-v2__item dd:last-of-type {
  margin-bottom: 0;
}

.details-list-v2__dt {
  font-weight: bold;
}

.details-list-v2__dd {
  line-height: 1.4;
}

@media (min-width: 64rem) {
  @supports (grid-area: auto) {
    .details-list-v2--cols {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
    .details-list-v2--cols .details-list-v2__item {
      padding: var(--space-md);
      text-align: center;
      border-bottom-width: 0px;
      border-right: 1px solid var(--color-contrast-low);
    }
    .details-list-v2--cols .details-list-v2__item:last-child {
      border-right-width: 0;
    }
  }
}

.google-maps {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.google-maps--ratio-4\:1 {
  padding-bottom: 25%;
}

.google-maps--ratio-3\:1 {
  padding-bottom: 33%;
}

/* -------------------------------- 

File#: _2_features-v3
Title: Features v3
Descr: A section containing a list of features
Usage: codyhouse.co/license

-------------------------------- */
.features-v3__text {
  padding-top: var(--space-xxl);
  padding-bottom: calc(var(--space-xl) * 2);
  background-color: var(--color-contrast-lower);
  position: relative;
  z-index: -1;
  background-size: cover;
  background-repeat: no-repeat;
}

.features-v3__text .overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.8);
  z-index: -1;
}

.features-v3__cards {
  margin-top: calc(var(--space-xl) * -1);
}

/* -------------------------------- 

File#: _2_feature-v4
Title: Feature v4
Descr: Content group containing media element + text that outsets its container at a specific breakpoint
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --feature-text-offset: 50%;
}

.feature--text-offset .feature__item {
  position: relative;
  z-index: 2;
}

.feature--text-offset .feature__item--media {
  z-index: 1;
}

@media (min-width: 48rem) {
  .feature__text-offset-item\@sm {
    width: calc(100% + var(--feature-text-offset));
  }
  .feature--invert\@sm .feature__text-offset-item\@sm {
    margin-left: calc(var(--feature-text-offset) * -1);
  }
  .feature-group--auto-invert\@sm .feature:nth-child(2n) .feature__text-offset-item\@sm {
    margin-left: calc(var(--feature-text-offset) * -1);
  }
}

@media (min-width: 64rem) {
  .feature__text-offset-item\@md {
    width: calc(100% + var(--feature-text-offset));
  }
  .feature--invert\@md .feature__text-offset-item\@sm,
  .feature--invert\@md .feature__text-offset-item\@md {
    margin-left: calc(var(--feature-text-offset) * -1);
  }
  .feature-group--auto-invert\@md .feature:nth-child(2n) .feature__text-offset-item\@sm,
  .feature-group--auto-invert\@md .feature:nth-child(2n) .feature__text-offset-item\@md {
    margin-left: calc(var(--feature-text-offset) * -1);
  }
}

@media (min-width: 80rem) {
  .feature__text-offset-item\@lg {
    width: calc(100% + var(--feature-text-offset));
  }
  .feature--invert\@lg .feature__text-offset-item\@sm,
  .feature--invert\@lg .feature__text-offset-item\@md,
  .feature--invert\@lg .feature__text-offset-item\@lg {
    margin-left: calc(var(--feature-text-offset) * -1);
  }
  .feature-group--auto-invert\@lg .feature:nth-child(2n) .feature__text-offset-item\@sm,
  .feature-group--auto-invert\@lg .feature:nth-child(2n) .feature__text-offset-item\@md,
  .feature-group--auto-invert\@lg .feature:nth-child(2n) .feature__text-offset-item\@lg {
    margin-left: calc(var(--feature-text-offset) * -1);
  }
}

/* -------------------------------- 

File#: _1_feature
Title: Feature
Descr: Content group containing text + media (img, video, iframe, inline SVG)
Usage: codyhouse.co/license

-------------------------------- */
.feature__item--media {
  display: flex;
  align-items: center;
}

.feature__item--media figure {
  width: 100%;
}

.feature__item--media video, .feature__item--media img, .feature__item--media svg {
  display: block;
}

.feature__item--media video, .feature__item--media img {
  width: 100%;
  height: auto;
}

@supports (display: grid) {
  .feature__item--media svg {
    width: 100%;
    height: auto;
  }
}

.feature__label {
  color: var(--color-contrast-medium);
  font-size: var(--text-sm);
}

@media (min-width: 48rem) {
  .feature--invert\@sm .feature__grid > *:first-child {
    order: 2;
  }
  .feature--invert\@sm .feature__grid > *:last-child {
    order: 1;
  }
}

@media (min-width: 64rem) {
  .feature--invert\@md .feature__grid > *:first-child {
    order: 2;
  }
  .feature--invert\@md .feature__grid > *:last-child {
    order: 1;
  }
}

@media (min-width: 80rem) {
  .feature--invert\@lg .feature__grid > *:first-child {
    order: 2;
  }
  .feature--invert\@lg .feature__grid > *:last-child {
    order: 1;
  }
}

@media (min-width: 48rem) {
  .feature-group--auto-invert\@sm .feature:nth-child(2n) .feature__grid > *:first-child {
    order: 2;
  }
  .feature-group--auto-invert\@sm .feature:nth-child(2n) .feature__grid > *:last-child {
    order: 1;
  }
}

@media (min-width: 64rem) {
  .feature-group--auto-invert\@md .feature:nth-child(2n) .feature__grid > *:first-child {
    order: 2;
  }
  .feature-group--auto-invert\@md .feature:nth-child(2n) .feature__grid > *:last-child {
    order: 1;
  }
}

@media (min-width: 80rem) {
  .feature-group--auto-invert\@lg .feature:nth-child(2n) .feature__grid > *:first-child {
    order: 2;
  }
  .feature-group--auto-invert\@lg .feature:nth-child(2n) .feature__grid > *:last-child {
    order: 1;
  }
}

/* -------------------------------- 

File#: _1_features-v2
Title: Features v2
Descr: A section containing a list of features
Usage: codyhouse.co/license

-------------------------------- */
.features-v2__item figure {
  flex-shrink: 0;
}

.features-v2__item img {
  display: block;
  width: 64px;
}

@media (min-width: 64rem) {
  .features-v2__item img {
    width: 64px;
  }
}

/* -------------------------------- 

File#: _1_feature-v4
Title: Feature v4
Descr: Content group containing media element + text that outsets its container at a specific breakpoint
Usage: codyhouse.co/license

-------------------------------- */
.feature-v4 {
  --feature-text-offset: 65%;
}

.logo-line {
  position: absolute;
  right: 20px;
  top: 40px;
  width: 800px;
  opacity: 0.2;
  max-width: 86vw;
}

.logo-line.logo-line--left {
  right: auto;
  left: 20px;
}

@media (min-width: 32rem) {
  .feature-v4__text-offset\@xs {
    width: calc(100% + var(--feature-text-offset));
  }
  .feature-v4--invert\@xs .feature-v4__grid > *:last-child {
    order: -1;
  }
  .feature-v4--invert\@xs .feature-v4__text-offset\@xs {
    margin-left: calc(var(--feature-text-offset) * -1);
  }
}

@media (min-width: 48rem) {
  .feature-v4__text-offset\@sm {
    width: calc(100% + var(--feature-text-offset));
  }
  .feature-v4--invert\@sm .feature-v4__grid > *:last-child {
    order: -1;
  }
  .feature-v4--invert\@sm .feature-v4__text-offset\@sm {
    margin-left: calc(var(--feature-text-offset) * -1);
  }
}

@media (min-width: 64rem) {
  .feature-v4__text-offset\@md {
    width: calc(100% + var(--feature-text-offset));
  }
  .feature-v4--invert\@md .feature-v4__grid > *:last-child {
    order: -1;
  }
  .feature-v4--invert\@md .feature-v4__text-offset\@md {
    margin-left: calc(var(--feature-text-offset) * -1);
  }
}

@media (min-width: 80rem) {
  .feature-v4__text-offset\@lg {
    width: calc(100% + var(--feature-text-offset));
  }
  .feature-v4--invert\@lg .feature-v4__grid > *:last-child {
    order: -1;
  }
  .feature-v4--invert\@lg .feature-v4__text-offset\@lg {
    margin-left: calc(var(--feature-text-offset) * -1);
  }
}

@media (min-width: 90rem) {
  .feature-v4__text-offset\@xl {
    width: calc(100% + var(--feature-text-offset));
  }
  .feature-v4--invert\@xl .feature-v4__grid > *:last-child {
    order: -1;
  }
  .feature-v4--invert\@xl .feature-v4__text-offset\@xl {
    margin-left: calc(var(--feature-text-offset) * -1);
  }
}

@media (min-width: 80rem) {
  .element--bordered {
    position: relative;
  }
  .element--bordered:after {
    content: '';
    width: 40px;
    height: 100%;
    background-color: var(--color-accent);
    position: absolute;
    right: 0px;
    top: 0;
  }
}

/* -------------------------------- 

File#: _1_features
Title: Features
Descr: A section containing a list of features
Usage: codyhouse.co/license

-------------------------------- */
.features__item img {
  display: block;
  width: 100%;
  margin-bottom: var(--space-sm);
}

.features--img-sm img {
  max-width: 80px;
}

/* -------------------------------- 

File#: _1_feature-v5
Title: Feature v5
Descr: Content group containing text components and a media element
Usage: codyhouse.co/license

-------------------------------- */
.feature-v5 {
  --feature-media-offset: var(--space-xxl);
  --feature-content-media-gap: var(--space-lg);
}

.feature-v5__content {
  background-color: var(--color-contrast-lower);
  padding-bottom: calc(var(--feature-content-media-gap) + var(--feature-media-offset));
}

.feature-v5__media {
  margin-top: calc(-1 * var(--feature-media-offset));
}

/* -------------------------------- 

File#: _1_card-v2
Title: Card v2
Descr: Container of information used as teasers for further content, often displayed in a gallery of related items
Usage: codyhouse.co/license

-------------------------------- */
.card-v2 {
  position: relative;
  border-radius: var(--radius-md);
  overflow: hidden;
}

.card-v2 img {
  display: block;
  width: 100%;
}

.card-v2__caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: var(--space-md) var(--space-sm) var(--space-sm);
  background: linear-gradient(hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0), var(--color-black));
  color: var(--color-white);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.35);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.card-v2__title {
  font-size: var(--text-md);
}

@media (min-width: 64rem) {
  .card-v2__title {
    font-size: 1em;
  }
}

.card-v2__label {
  margin-top: var(--space-xxxs);
  font-size: var(--text-sm);
  opacity: 0.75;
}

.card-v2--blur-fx .card-v2__caption {
  padding: var(--space-sm);
  background: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.7);
  border-radius: 0 0 var(--radius-md) var(--radius-md);
  backdrop-filter: blur(10px);
}

a.card-v2 {
  display: block;
  text-decoration: none;
  transition: box-shadow .2s;
}

a.card-v2:hover {
  box-shadow: var(--shadow-md);
}

/* -------------------------------- 

File#: _1_card-v8
Title: Card v8
Descr: Container of information used as teaser for further content exploration
Usage: codyhouse.co/license

-------------------------------- */
.card-v8 {
  display: block;
  border-radius: var(--radius-lg);
  overflow: hidden;
  background-color: var(--color-bg);
  text-decoration: none;
  transition: .2s;
}

.card-v8 img {
  display: block;
  width: 100%;
}

.card-v8:hover {
  box-shadow: var(--shadow-md);
}

.card-v8:hover .card-v8__title {
  background-size: 100% 100%;
}

.card-v8__title {
  background-repeat: no-repeat;
  will-change: background-size;
  transition: background-size 0.3s var(--ease-in-out);
  text-decoration: none;
  background-image: linear-gradient(transparent 50%, hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2) 50%);
  background-size: 0% 100%;
}

/* -------------------------------- 

File#: _1_card-v3
Title: Card v3
Descr: Container of information used as teasers for further content, often displayed in a gallery of related items
Usage: codyhouse.co/license

-------------------------------- */
.card-v3 {
  background-color: var(--color-contrast-lower);
}

.card-v3__link {
  text-decoration: none;
  display: block;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: inherit;
}

.card-v3__img img {
  display: block;
  width: 100%;
}

.card-v3__content {
  padding: var(--space-sm);
}

.card-v3__label {
  font-size: var(--text-sm);
  margin-bottom: var(--space-xs);
  color: var(--color-contrast-medium);
}

.card-v3__footer {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-sm);
  margin-top: auto;
}

.card-v3__footer span {
  display: inline-block;
  font-size: var(--text-sm);
  will-change: transform;
  transform: translateY(20%);
  opacity: 0;
  transition: transform .3s, opacity .3s;
}

.card-v3__footer .icon {
  display: block;
  color: inherit;
}

.card-v3__footer::before {
  content: '';
  width: 100%;
  height: 3px;
  background-color: var(--color-primary);
  position: absolute;
  bottom: 0;
  left: 0;
  transform-origin: left center;
  transform: scaleX(0);
  transition: transform 0.3s var(--ease-in-out);
}

.card-v3:hover .card-v3__footer span {
  transform: translateY(0);
  opacity: 1;
}

.card-v3:hover .card-v3__footer::before {
  transform: scaleX(1);
}

/* -------------------------------- 

File#: _1_card-v9
Title: Card v9
Descr: Container of information used as teaser for further content exploration
Usage: codyhouse.co/license

-------------------------------- */
.card-v9 {
  display: block;
  position: relative;
  z-index: 1;
  text-decoration: none;
  color: inherit;
  background-color: var(--color-contrast-lower);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: var(--radius-md);
  overflow: hidden;
  box-shadow: var(--shadow-sm);
  transition: box-shadow .3s;
}

.card-v9:hover {
  box-shadow: var(--shadow-md);
}

.card-v9__content {
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;
  z-index: 2;
  padding: var(--component-padding);
}

.card-v9__text {
  padding-bottom: var(--space-xxxl);
  max-width: 540px;
}

.card-v9__label {
  font-size: var(--text-sm);
  color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.55);
  margin-bottom: var(--space-xxs);
}

.card-v9__title {
  font-size: var(--text-xl);
  min-height: 140px;
}

.card-v9__btn {
  position: relative;
  display: inline-block;
  padding: var(--space-xs) var(--space-sm);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.card-v9__btn i {
  position: relative;
  z-index: 2;
  color: var(--color-bg);
  opacity: 0;
  transition: opacity .3s;
}

.card-v9__btn::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--radius-md);
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.8);
  backdrop-filter: blur(5px);
  opacity: 0;
  transform: translateY(25%);
  transition: opacity 0.3s, transform 0.3s var(--ease-out);
}

.card-v9:hover .card-v9__btn i {
  opacity: 1;
}

.card-v9:hover .card-v9__btn::after {
  opacity: 1;
  transform: translateY(0);
}

.card-v9--overlay-bg::before {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), 0.5);
  transition: background-color .3s;
}

.card-v9--overlay-bg:hover::before {
  background-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), 0.8);
}

/* -------------------------------- 

File#: _3_products-gallery-v2
Title: Products Gallery v2
Descr: A gallery of product cards
Usage: codyhouse.co/license

-------------------------------- */
/* -------------------------------- 

File#: _2_product-card-v2
Title: Product Card v2
Descr: A selection of product information used as a teaser for further content
Usage: codyhouse.co/license

-------------------------------- */
.prod-card-v2 {
  --rating-icon-size: 1.475em;
  position: relative;
}

.prod-card-v2__img-link {
  display: block;
  position: relative;
  border-radius: var(--radius-lg);
  overflow: hidden;
  box-shadow: var(--shadow-sm);
}

.prod-card-v2__img-link img {
  display: block;
  width: 100%;
}

.prod-card-v2__img-link img:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: .3s;
}

.prod-card-v2__img-link:hover img:nth-child(2) {
  opacity: 1;
}

.prod-card-v2__badge {
  position: absolute;
  z-index: 1;
  top: var(--space-sm);
  right: var(--space-sm);
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.9);
  padding: var(--space-xxs) var(--space-sm);
  pointer-events: none;
  font-size: var(--text-sm);
  color: var(--color-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.product-card-v2__title {
  color: var(--color-contrast-higher);
  text-decoration: none;
  font-weight: bold;
}

.product-card-v2__title:hover {
  text-decoration: underline;
}

.prod-card-v2__price {
  text-decoration: none;
}

.prod-card-v2__old-price {
  color: var(--color-contrast-medium);
  text-decoration: line-through;
}

.prod-card-v2__old-price::before {
  content: 'original price';
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
}

/* -------------------------------- 

File#: _1_stories
Title: Stories
Descr: A collection of related article tasters
Usage: codyhouse.co/license

-------------------------------- */
.stories {
  background-color: var(--color-contrast-lower);
  padding: var(--component-padding);
  border-radius: var(--radius-md);
}

@media (min-width: 64rem) {
  .stories {
    font-size: var(--text-sm);
  }
}

.stories__legend {
  font-size: 1em;
  margin-bottom: var(--space-md);
  color: var(--color-contrast-medium);
}

.stories__story {
  display: flex;
}

.stories__story:not(:last-child) {
  margin-bottom: var(--space-sm);
  padding-bottom: var(--space-sm);
  border-bottom: 1px solid var(--color-contrast-low);
}

.stories__img-wrapper {
  display: block;
  width: 4.6em;
  height: 4.6em;
  border-radius: var(--radius-md);
  overflow: hidden;
  flex-shrink: 0;
  transition: opacity .2s;
  margin-right: var(--space-xs);
}

.stories__img-wrapper figure, .stories__img-wrapper img {
  width: inherit;
  height: inherit;
}

.stories__img-wrapper img {
  display: block;
  object-fit: cover;
}

.stories__img-wrapper:hover {
  opacity: 0.85;
}

.stories__text {
  margin-top: var(--space-xxxs);
}

.stories__headline {
  font-size: var(--text-md);
}

.stories__metadata {
  color: var(--color-contrast-medium);
  font-size: var(--text-sm);
  text-transform: uppercase;
}

.stories__headline a,
.stories__metadata a {
  color: var(--color-contrast-higher);
  text-decoration: none;
}

.stories__headline a:hover,
.stories__metadata a:hover {
  text-decoration: underline;
}

.stories__separator {
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  background-color: var(--color-contrast-low);
  border-radius: 50%;
  margin: 0 var(--space-xxxs);
}

/* -------------------------------- 

File#: _1_main-footer-v4
Title: Main Footer v4
Descr: Footer navigation template
Usage: codyhouse.co/license

-------------------------------- */
.footer-v4__nav {
  margin-bottom: var(--space-lg);
}

.footer-v4__nav-item {
  margin-bottom: var(--space-sm);
}

.footer-v4__nav-item a {
  color: var(--color-contrast-high);
  font-size: 1.25em;
}

.footer-v4__nav-item a:hover {
  color: var(--color-primary);
}

.footer-v4__logo {
  margin-bottom: var(--space-sm);
}

.footer-v4__logo a, .footer-v4__logo svg, .footer-v4__logo img {
  width: 160px;
  display: block;
}

.footer-v4__print {
  color: var(--color-contrast-medium);
  font-size: var(--text-sm);
  margin-bottom: var(--space-sm);
}

.footer-v4__socials {
  display: flex;
  align-items: center;
}

.footer-v4__socials a {
  text-decoration: none;
  display: inline-block;
  margin-right: var(--space-xs);
  color: var(--color-contrast-medium);
}

.footer-v4__socials a:hover {
  color: var(--color-contrast-high);
}

.footer-v4__socials a svg {
  display: block;
  width: 1.25em;
  height: 1.25em;
  color: inherit;
}

@media (min-width: 64rem) {
  .footer-v4 {
    text-align: center;
  }
  .footer-v4__nav-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .footer-v4__nav-item {
    display: inline-block;
    margin: var(--space-xxxs) var(--space-xs);
  }
  .footer-v4__nav-item a {
    font-size: 0.75em;
  }
  .footer-v4__nav-item:first-child {
    padding-left: 0;
  }
  .footer-v4__nav-item:last-child {
    padding-right: 0;
  }
  .footer-v4__logo {
    display: inline-block;
  }
  .footer-v4__print {
    font-size: var(--text-xs);
  }
  .footer-v4__socials {
    justify-content: center;
  }
  .footer-v4__socials a {
    margin: 0 var(--space-xxxs);
  }
  .footer-v4__socials a svg {
    width: 1em;
    height: 1em;
  }
}

/* -------------------------------- 

File#: _1_main-footer
Title: Main Footer
Descr: Footer navigation
Usage: codyhouse.co/license

-------------------------------- */
@media (min-width: 64rem) {
  .main-footer__content {
    display: flex;
    justify-content: space-between;
  }
}

.main-footer__logo {
  flex-shrink: 0;
  margin-bottom: var(--space-md);
}

.main-footer__logo a, .main-footer__logo svg, .main-footer__logo img {
  width: 180px;
  display: block;
}

@media (min-width: 64rem) {
  .main-footer__nav {
    width: calc(100% - 420px);
  }
}

@media (min-width: 80rem) {
  .main-footer__nav {
    width: auto;
  }
}

.main-footer__nav-list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.main-footer__nav-item {
  display: grid;
  grid-gap: var(--space-xxs);
  margin-bottom: var(--space-md);
  width: 100%;
  padding-right: var(--space-sm);
}

@media (min-width: 32rem) {
  .main-footer__nav-item {
    width: 50%;
  }
}

@media (min-width: 80rem) {
  .main-footer__nav-item {
    grid-gap: var(--space-xs);
    width: 240px;
  }
}

.main-footer__nav-item a {
  color: var(--color-contrast-medium);
}

.main-footer__nav-item a:hover {
  color: var(--color-contrast-high);
}

@media (min-width: 80rem) {
  .main-footer__nav-item a {
    font-size: 0.9em;
  }
}

.main-footer__colophon {
  border-top: 1px solid var(--color-contrast-low);
  padding-top: var(--space-xxxs);
  margin-top: var(--space-md);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.main-footer__colophon-nav {
  font-size: var(--text-sm);
  color: var(--color-contrast-medium);
  margin-right: var(--space-sm);
}

.main-footer__colophon-nav > * {
  margin-right: var(--space-xxs);
}

.main-footer__colophon-nav a {
  color: inherit;
}

.main-footer__colophon-nav a:hover {
  color: var(--color-contrast-high);
}

@media (min-width: 64rem) {
  .main-footer__colophon-nav {
    font-size: var(--text-xs);
  }
}

.main-footer__socials {
  display: flex;
  margin: var(--space-xxs) 0;
}

.main-footer__socials a {
  text-decoration: none;
  display: inline-block;
  margin-left: var(--space-xxs);
  color: var(--color-contrast-medium);
}

.main-footer__socials a:hover {
  color: var(--color-contrast-high);
}

.main-footer__socials a svg {
  display: block;
  width: 1em;
  height: 1em;
  color: inherit;
}

/* -------------------------------- 

File#: _2_anim-cards
Title: Animated Cards
Descr: A collection of cards that slides over time
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --anim-cards-width: 280px;
  --anim-cards-gap: var(--space-md);
}

@supports (--css: variables) {
  @media (min-width: 48rem) {
    :root {
      --anim-cards-width: 400px;
    }
  }
  @media (min-width: 64rem) {
    :root {
      --anim-cards-width: 550px;
    }
  }
}

.anim-cards {
  overflow: hidden;
  margin-top: var(--anim-cards-gap);
  margin-bottom: var(--anim-cards-gap);
}

.anim-cards__list {
  display: flex;
  overflow: auto;
}

.anim-cards__item {
  float: left;
  width: var(--anim-cards-width);
  flex-shrink: 0;
  margin-right: var(--anim-cards-gap);
}

.js .anim-cards:not(.anim-cards--anim-off) .anim-cards__list {
  overflow: visible;
  will-change: transform;
  transform: translateZ(0);
  backface-visibility: hidden;
}

.js .anim-cards--reverse:not(.anim-cards--anim-off) .anim-cards__list {
  flex-direction: row-reverse;
}

/* -------------------------------- 

File#: _1_masonry
Title: Masonry
Descr: Gallery with elements laid out in optimal position based on available vertical space
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --masonry-grid-gap: var(--space-sm);
  --masonry-col-auto-size: 280px;
}

.masonry__loader {
  display: none;
}

.masonry__list {
  display: flex;
  flex-wrap: wrap;
  margin-right: calc( -1 * var(--masonry-grid-gap));
  margin-bottom: calc( -1 * var(--masonry-grid-gap));
}

.masonry__item {
  display: inline-block;
  width: var(--masonry-col-auto-size);
  margin-right: var(--masonry-grid-gap);
  margin-bottom: var(--masonry-grid-gap);
}

.js .masonry {
  position: relative;
}

.js .masonry__loader {
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}

.js .masonry__list {
  opacity: 0;
  transition: opacity .4s;
}

.js .masonry--loaded .masonry__loader {
  display: none;
}

.js .masonry--loaded .masonry__list {
  opacity: 1;
}

@supports (flex-basis: 0px) {
  .js .masonry__list {
    flex-direction: column;
  }
  .js .masonry__item {
    flex-basis: 0px;
  }
}

/* -------------------------------- 

File#: _1_card-v9
Title: Card v9
Descr: Container of information used as teaser for further content exploration
Usage: codyhouse.co/license

-------------------------------- */
.card-v9 {
  display: block;
  position: relative;
  z-index: 1;
  text-decoration: none;
  color: inherit;
  background-color: var(--color-contrast-lower);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 0;
  overflow: hidden;
  box-shadow: var(--shadow-sm);
  transition: box-shadow .3s;
}

.card-v9:hover {
  box-shadow: var(--shadow-md);
}

.card-v9__content {
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;
  z-index: 2;
  padding: var(--component-padding);
}

.card-v9__text {
  padding-bottom: var(--space-xxxl);
  max-width: 540px;
}

.card-v9__label {
  font-size: var(--text-sm);
  color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.55);
  margin-bottom: var(--space-xxs);
}

.card-v9__title {
  font-size: var(--text-xl);
}

.card-v9__btn {
  position: relative;
  display: inline-block;
  padding: var(--space-xs) var(--space-sm);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.card-v9__btn i {
  position: relative;
  z-index: 2;
  color: var(--color-bg);
  opacity: 0;
  transition: opacity .3s;
}

.card-v9__btn::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
  background-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.8);
  backdrop-filter: blur(5px);
  opacity: 0;
  transform: translateY(25%);
  transition: opacity 0.3s, transform 0.3s var(--ease-out);
}

.card-v9:hover .card-v9__btn i {
  opacity: 1;
}

.card-v9:hover .card-v9__btn::after {
  opacity: 1;
  transform: translateY(0);
}

.card-v9--overlay-bg::before {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.5);
  transition: background-color .3s;
}

.card-v9--overlay-bg:hover::before {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.8);
}

/* -------------------------------- 

File#: _2_article-gallery-v4
Title: Article Gallery v4
Descr: A gallery of blog articles
Usage: codyhouse.co/license

-------------------------------- */
.articles-v4.masonry {
  --masonry-grid-gap: var(--space-sm);
  --masonry-col-auto-size: 320px;
}

/* -------------------------------- 

File#: _1_adv-gallery
Title: Advanced Gallery
Descr: Advanced image gallery template
Usage: codyhouse.co/license

-------------------------------- */
.adv-gallery__img-wrapper img {
  width: 100%;
  display: block;
}

.adv-gallery__label {
  background-color: var(--color-contrast-lower);
  padding: var(--space-md);
}

.adv-gallery__grid > * {
  margin-bottom: var(--space-md);
}

@media (min-width: 64rem) {
  .adv-gallery__grid > :nth-child(1), .adv-gallery__grid > :nth-child(2), .adv-gallery__grid > :nth-child(3) {
    width: 32%;
    float: left;
  }
  .adv-gallery__grid > :nth-child(1), .adv-gallery__grid > :nth-child(2) {
    margin-right: 2%;
  }
  .adv-gallery__grid > :nth-child(4) {
    clear: both;
  }
}

@supports (grid-area: auto) {
  .adv-gallery__grid {
    display: grid;
    grid-gap: var(--space-md);
  }
  .adv-gallery__grid > * {
    margin: 0 !important;
    width: auto !important;
    float: none !important;
  }
  @media (min-width: 64rem) {
    .adv-gallery__grid {
      grid-template-columns: repeat(12, 1fr);
    }
    .adv-gallery__grid > :nth-child(1) {
      grid-column: span 5;
    }
    .adv-gallery__grid > :nth-child(2) {
      grid-column: span 7;
    }
    .adv-gallery__grid > :nth-child(3) {
      grid-column: span 9;
    }
    .adv-gallery__grid > :nth-child(4) {
      grid-column: span 3;
    }
  }
  @media (min-width: 90rem) {
    .adv-gallery__grid {
      grid-gap: var(--space-sm);
      grid-template-rows: repeat(12, 50px);
    }
    .adv-gallery__grid > :nth-child(1) {
      grid-column: 1 / 4;
      grid-row: 1 / 7;
    }
    .adv-gallery__grid > :nth-child(2) {
      grid-column: 1 / 4;
      grid-row: 7 / -1;
    }
    .adv-gallery__grid > :nth-child(3) {
      grid-column: 4 / 11;
      grid-row: 1 / -1;
    }
    .adv-gallery__grid > :nth-child(4) {
      grid-column: 11 / 13;
      grid-row: 1 / -1;
    }
    .adv-gallery__img-wrapper img {
      object-fit: cover;
      height: 100%;
    }
    .adv-gallery__label {
      display: flex;
    }
    .adv-gallery__label p {
      margin-top: auto;
    }
    .adv-gallery__label p::before {
      content: '';
      display: block;
      height: 80px;
      width: 1px;
      background-color: var(--color-contrast-high);
      margin-bottom: var(--space-sm);
    }
  }
}

/* -------------------------------- 

File#: _2_svg-slideshow
Title: SVG Image Slideshow
Descr: Show a collection of images one at a time, using SVG clipping effects to transition between items
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --svg-slideshow-btn-width: 1.6em;
  --svg-slideshow-btn-height: 3.2em;
  --svg-slideshow-btn-icon-size: 1.6em;
  --svg-slideshow-btn-offset: var(--space-xs);
}

.svg-slideshow {
  position: relative;
  z-index: 1;
}

.svg-slideshow__control {
  display: none;
}

.js .svg-slideshow {
  opacity: 0;
  transition: opacity 0.2s;
}

.js .svg-slideshow--loaded {
  opacity: 1;
}

.js .svg-slideshow__item {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-color: transparent;
  opacity: 0;
}

.js .svg-slideshow__item svg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.js .svg-slideshow__item img {
  display: block;
  opacity: 0;
}

.js .svg-slideshow__item svg image {
  opacity: 1;
}

.js .svg-slideshow__item--selected {
  position: relative;
  z-index: 2;
  opacity: 1;
}

.js .svg-slideshow__item--animating {
  z-index: 3;
  opacity: 1;
}

.js .svg-slideshow[data-swipe="on"] .svg-slideshow__item {
  user-select: none;
}

.js .svg-slideshow[data-swipe="on"] .svg-slideshow__item image {
  pointer-events: none;
}

.js .svg-slideshow[data-swipe="on"] .svg-slideshow__control {
  display: none;
}

.js .svg-slideshow__control {
  display: block;
  position: absolute;
  z-index: 4;
  top: 50%;
  transform: translateY(-50%);
}

.js .svg-slideshow__control:first-of-type {
  left: var(--svg-slideshow-btn-offset);
}

.js .svg-slideshow__control:last-of-type {
  right: var(--svg-slideshow-btn-offset);
}

@media (min-width: 64rem) {
  .js .svg-slideshow[data-swipe="on"] .svg-slideshow__control {
    display: block;
  }
}

.svg-slideshow__btn {
  display: block;
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.75);
  height: var(--svg-slideshow-btn-height);
  width: var(--svg-slideshow-btn-width);
  border-radius: var(--radius-md);
  cursor: pointer;
  transition: background .2s, transform 0.2s;
}

.svg-slideshow__btn:hover {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.85);
}

.svg-slideshow__btn:hover .icon {
  color: var(--color-bg);
}

.svg-slideshow:not(.svg-slideshow--is-animating) .svg-slideshow__btn:active {
  transform: scale(0.95);
}

.svg-slideshow__btn .icon {
  display: block;
  width: var(--svg-slideshow-btn-icon-size);
  height: var(--svg-slideshow-btn-icon-size);
  margin: 0 auto;
  transition: color .2s;
  color: var(--color-white);
}

@supports (grid-area: auto) {
  .svg-slideshow__btn {
    background-color: transparent;
  }
  .svg-slideshow__btn .icon {
    color: var(--color-contrast-higher);
  }
}

.svg-slideshow__navigation {
  position: absolute;
  z-index: 4;
  bottom: 0;
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.svg-slideshow__nav-item {
  display: inline-block;
  margin: 0 var(--space-xxxs);
}

.svg-slideshow__nav-item button {
  display: block;
  position: relative;
  font-size: 8px;
  color: var(--color-contrast-high);
  height: 1em;
  width: 1em;
  border-radius: 50%;
  background-color: currentColor;
  opacity: 0.4;
  cursor: pointer;
  transition: background .3s;
}

.svg-slideshow__nav-item button::before {
  content: '';
  position: absolute;
  top: calc(50% - 0.5em);
  left: calc(50% - 0.5em);
  height: 1em;
  width: 1em;
  font-size: 14px;
  border-radius: inherit;
  border: 1px solid var(--color-contrast-high);
  opacity: 0;
  transform: scale(0);
  transition: 0.3s;
}

.svg-slideshow__nav-item button:focus {
  outline: none;
}

.svg-slideshow__nav-item button:focus::before {
  opacity: 1;
  transform: scale(1);
}

.svg-slideshow__nav-item--selected button {
  opacity: 1;
}

@media (min-width: 64rem) {
  .svg-slideshow__navigation {
    height: 40px;
  }
  .svg-slideshow__nav-item button {
    font-size: 10px;
  }
  .svg-slideshow__nav-item button::before {
    font-size: 16px;
  }
}

/* -------------------------------- 

File#: N/A
Title: Swipe Content
Descr: A Vanilla JavaScript plugin to detect touch interactions
Usage: codyhouse.co/license

-------------------------------- */
.swipe-content {
  height: 280px;
  width: 280px;
  background-color: var(--color-contrast-low);
  border-radius: var(--radius-md);
  cursor: default;
  transition: background 0.2s;
}

.swipe-content:active {
  background-color: var(--color-contrast-medium);
}
