:root {
  // font family
  --font-primary: Muli, sans-serif;
  --font-secondary: Open sans, sans-serif;

  // body font size
  --text-base-size: 1em;
  
  // type scale
  --text-scale-ratio: 1.2;
  --text-xs: calc((1em / var(--text-scale-ratio)) / var(--text-scale-ratio));
  --text-sm: calc(var(--text-xs) * var(--text-scale-ratio));
  --text-md: calc(var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));

  // line-height
  --body-line-height: 1.4;
  --heading-line-height: 1.2;

  // capital letters - used in combo with the lhCrop mixin
  --font-primary-capital-letter: 1;
  --font-secondary-capital-letter: 1;
}

@supports(--css: variables) {
  :root {
    @include breakpoint(md) {
      --text-base-size: 1.25em;
      --text-scale-ratio: 1.25;
    }
  }
}

body {
  font-family: var(--font-secondary);
}

h1, h2, h3, h4 {
  font-family: var(--font-primary);
  font-weight: 900;
}

// font family
.font-primary { font-family: var(--font-primary);}
.font-secondary { font-family: var(--font-secondary);}

/* -------------------------------- 

File#: _1_text-background-effects
Title: Text Background Effects
Descr: A collection of text backgrounds animated on hover
Usage: codyhouse.co/license

-------------------------------- */

.text-bg-fx {
  background-repeat: no-repeat;
  will-change: background-size;
  transition: background-size .3s var(--ease-in-out);

  &:hover {
    background-size: 100% 100%;
  }
}

.text-bg-fx--scale-x {
  padding: var(--space-xxxxs) 0;
  background-image: linear-gradient(alpha(var(--color-primary), 0.2), alpha(var(--color-primary), 0.2));
  background-size: 0% 100%;
}

.text-bg-fx--scale-y {
  text-decoration: none;
  background-image: linear-gradient(alpha(var(--color-primary), 0.2), alpha(var(--color-primary), 0.2));
  background-size: 100% 2px;
  background-position: center bottom;
}

.text-bg-fx--underline,
.text-bg-fx--underline-bold {
  text-decoration: none;
  background-size: 0% 100%;
}

.text-bg-fx--underline { // text underline size = 1px
  background-image: linear-gradient(transparent calc(100% - 3px), currentColor calc(100% - 3px), currentColor calc(100% - 2px), transparent 2px);
}

.text-bg-fx--underline-bold {
  background-image: linear-gradient(transparent 50%, alpha(var(--color-primary), 0.2) 50%);
}

.text-bg-fx--text-shadow { // you can use this with the .text-bg-fx--underline effect
  text-shadow: 1.5px 0 var(--color-bg), -1.5px 0 var(--color-bg);
}

.title--script {
  font-family: 'Caveat', cursive !important;
}

.no-underline {
  text-decoration: none;
}