/* -------------------------------- 

File#: _1_card-v2
Title: Card v2
Descr: Container of information used as teasers for further content, often displayed in a gallery of related items
Usage: codyhouse.co/license

-------------------------------- */

.card-v2 {
  position: relative;
  border-radius: var(--radius-md);
  overflow: hidden;

  img {
    display: block;
    width: 100%;
  }
}

.card-v2__caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: var(--space-md) var(--space-sm) var(--space-sm);
  background: linear-gradient(alpha(var(--color-black), 0), var(--color-black));

  color: var(--color-white);
  text-shadow: 0 1px 1px rgba(#000, 0.35);
  // text-align: center;
  @include fontSmooth;
}

.card-v2__title {
  font-size: var(--text-md);

  @include breakpoint(md) {
    font-size: 1em;
  }
}

.card-v2__label {
  margin-top: var(--space-xxxs);
  font-size: var(--text-sm);
  opacity: 0.75;
}

// --blurred-img
.card-v2--blur-fx {
  .card-v2__caption {
    padding: var(--space-sm);
    background: alpha(var(--color-black), 0.7);
    border-radius: 0 0 var(--radius-md) var(--radius-md);
    backdrop-filter: blur(10px);
  }
}

// --link
a.card-v2 {
  display: block;
  text-decoration: none;
  transition: box-shadow .2s;

  &:hover {
    box-shadow: var(--shadow-md);
  }
}

/* -------------------------------- 

File#: _1_card-v8
Title: Card v8
Descr: Container of information used as teaser for further content exploration
Usage: codyhouse.co/license

-------------------------------- */

.card-v8 {
  display: block;
  border-radius: var(--radius-lg);
  overflow: hidden;
  background-color: var(--color-bg);
  text-decoration: none;
  transition: .2s;

  img {
    display: block;
    width: 100%;
  }

  &:hover {
    box-shadow: var(--shadow-md);

    .card-v8__title {
      background-size: 100% 100%;
    }
  }
}

.card-v8__title {
  background-repeat: no-repeat;
  will-change: background-size;
  transition: background-size .3s var(--ease-in-out);
  text-decoration: none;
  background-image: linear-gradient(transparent 50%, alpha(var(--color-primary), 0.2) 50%);
  background-size: 0% 100%;
}

/* -------------------------------- 

File#: _1_card-v3
Title: Card v3
Descr: Container of information used as teasers for further content, often displayed in a gallery of related items
Usage: codyhouse.co/license

-------------------------------- */

.card-v3 {
  background-color: var(--color-contrast-lower);
}

.card-v3__link {
  text-decoration: none;
  display: block; // fallback
  display: flex;
  flex-direction: column;
  height: 100%;
  color: inherit;
}

.card-v3__img {
  img {
    display: block;
    width: 100%;
  }
}

.card-v3__content {
  padding: var(--space-sm);
}

.card-v3__label {
  font-size: var(--text-sm);
  margin-bottom: var(--space-xs);
  color: var(--color-contrast-medium);
}

.card-v3__footer {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-sm);
  margin-top: auto;

  span { // text
    display: inline-block;
    font-size: var(--text-sm);
    will-change: transform;
    transform: translateY(20%);
    opacity: 0;
    transition: transform .3s, opacity .3s;
  }

  .icon {
    display: block;
    color: inherit;
  }

  &::before { // animated bottom line
    content: '';
    width: 100%;
    height: 3px;
    background-color: var(--color-primary);
    position: absolute;
    bottom: 0;
    left: 0;
    transform-origin: left center;
    transform: scaleX(0);
    transition: transform .3s var(--ease-in-out);
  }
}

.card-v3:hover {  
  .card-v3__footer {
    span {
      transform: translateY(0);
      opacity: 1;
    }

    &::before {
      transform: scaleX(1);
    }
  }
}

/* -------------------------------- 

File#: _1_card-v9
Title: Card v9
Descr: Container of information used as teaser for further content exploration
Usage: codyhouse.co/license

-------------------------------- */

.card-v9 {
  display: block;
  position: relative;
  z-index: 1;
  text-decoration: none;
  color: inherit;
  background-color: var(--color-contrast-lower);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: var(--radius-md);
  overflow: hidden;
  box-shadow: var(--shadow-sm);
  transition: box-shadow .3s;

  &:hover {
    box-shadow: var(--shadow-md);
  }
}

.card-v9__content {
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;
  z-index: 2;
  padding: var(--component-padding);
}

.card-v9__text {
  padding-bottom: var(--space-xxxl); // this padding determines the ratio of the card
  max-width: 540px;
}

.card-v9__label {
  font-size: var(--text-sm);
  color: alpha(var(--color-contrast-higher), 0.55);
  margin-bottom: var(--space-xxs);
}

.card-v9__title {
  font-size: var(--text-xl);
  min-height: 140px;
}

.card-v9__btn {
  position: relative;
  display: inline-block;
  padding: var(--space-xs) var(--space-sm);
  @include fontSmooth;

  i { // button label
    position: relative;
    z-index: 2;
    color: var(--color-bg);
    opacity: 0;
    transition: opacity .3s;
  }

  &::after { // button background
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--radius-md);
    background-color: alpha(var(--color-contrast-higher), 0.8);
    backdrop-filter: blur(5px);
    opacity: 0;
    transform: translateY(25%);
    transition: opacity .3s, transform .3s var(--ease-out);
  }
}

.card-v9:hover {
  .card-v9__btn {
    i {
      opacity: 1;
    }

    &::after {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

// --overlay-bg
.card-v9--overlay-bg {
  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: alpha(var(--color-bg), 0.5);
    transition: background-color .3s;
  }

  &:hover::before {
    background-color: alpha(var(--color-bg), 0.8);
  }
}