/* -------------------------------- 

File#: _1_section-divider
Title: Section divider
Descr: A collection of SVG section dividers.
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --section-divider-width: 1920; // px
  --section-divider-height: 60; // px
  --section-divider-ratio: calc(100% * var(--section-divider-height) / var(--section-divider-width));
}

[class*="has-section-divider"] {
  position: relative;
}

.has-section-dividers { // section with dividers on both sides
  padding-top: var(--section-divider-ratio);
  padding-bottom: var(--section-divider-ratio);

  .section-divider:first-child {
    bottom: auto;
    top: -1px;
    transform: scaleY(-1);
  }
}

.has-section-divider-top { // section with bottom divider
  padding-top: var(--section-divider-ratio);

  .section-divider {
    bottom: auto;
    top: -1px;
    transform: scaleY(-1);
  }
}

.has-section-divider-bottom { // section with top divider
  padding-bottom: var(--section-divider-ratio);
}

.has-bg-image {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;

  .bg-overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -1;
    background-color: rgba(var(--color-primary-light), 0.2);
  }
}

.section-divider {
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  height: auto;
  width: 100%;
}



.hero {
  display: flex;
  align-items: center;
  background-color: var(--color-bg);
  min-height: 380px;
  // if bg = img
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  @include breakpoint(md) {
    min-height: 540px;
  }
}

.hero__content {}

.hero__label {
  color: alpha(var(--color-contrast-high), 0.6);
  font-size: var(--text-sm);
}

// --overlay-layer
.hero--overlay-layer {
  position: relative;

  .hero__content {
    position: relative;
    z-index: 2;
  }

  &::after { // add a layer in between the img/video background and the content
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: alpha(var(--color-bg), 0.65);
    z-index: 1;
  }
}

// --full-screen
.hero--full-screen {
  height: 100vh;
}

