/* -------------------------------- 

File#: _2_features-v3
Title: Features v3
Descr: A section containing a list of features
Usage: codyhouse.co/license

-------------------------------- */

.features-v3__text {
  padding-top: var(--space-xxl);
  padding-bottom: calc(var(--space-xl) * 2);
  background-color: var(--color-contrast-lower);
  position: relative;
  z-index: -1;
  background-size: cover;
  background-repeat: no-repeat;

  .overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: alpha(var(--color-contrast-higher), 0.8);
    z-index: -1;
  }
}

.features-v3__cards {
  margin-top: calc(var(--space-xl) * -1);
}

/* -------------------------------- 

File#: _2_feature-v4
Title: Feature v4
Descr: Content group containing media element + text that outsets its container at a specific breakpoint
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --feature-text-offset: 50%;
}

.feature--text-offset {
  .feature__item {
    position: relative;
    z-index: 2;
  }

  .feature__item--media {
    z-index: 1;
  }
}

@include breakpoint(sm) {
  .feature__text-offset-item\@sm {
    width: calc(100% + var(--feature-text-offset));
  }
  
  .feature--invert\@sm {
    .feature__text-offset-item\@sm {
      margin-left: calc(var(--feature-text-offset) * -1);
    }
  }

  .feature-group--auto-invert\@sm {
    .feature:nth-child(2n) {
      .feature__text-offset-item\@sm {
        margin-left: calc(var(--feature-text-offset) * -1);
      }
    }
  }
}

@include breakpoint(md) {
  .feature__text-offset-item\@md {
    width: calc(100% + var(--feature-text-offset));
  }
  
  .feature--invert\@md {
    .feature__text-offset-item\@sm,
    .feature__text-offset-item\@md {
      margin-left: calc(var(--feature-text-offset) * -1);
    }
  }

  .feature-group--auto-invert\@md {
    .feature:nth-child(2n) {
      .feature__text-offset-item\@sm,
      .feature__text-offset-item\@md {
        margin-left: calc(var(--feature-text-offset) * -1);
      }
    }
  }
}

@include breakpoint(lg) {
  .feature__text-offset-item\@lg {
    width: calc(100% + var(--feature-text-offset));
  }
  
  .feature--invert\@lg {
    .feature__text-offset-item\@sm,
    .feature__text-offset-item\@md,
    .feature__text-offset-item\@lg {
      margin-left: calc(var(--feature-text-offset) * -1);
    }
  }

  .feature-group--auto-invert\@lg {
    .feature:nth-child(2n) {
      .feature__text-offset-item\@sm,
      .feature__text-offset-item\@md,
      .feature__text-offset-item\@lg {
        margin-left: calc(var(--feature-text-offset) * -1);
      }
    }
  }
}

/* -------------------------------- 

File#: _1_feature
Title: Feature
Descr: Content group containing text + media (img, video, iframe, inline SVG)
Usage: codyhouse.co/license

-------------------------------- */

.feature {}
.feature__item {}

// media
.feature__item--media {
  display: flex;
  align-items: center;

  figure {
    width: 100%;
  }

  video, img, svg {
    display: block;
  }
  
  video, img {
    width: 100%;
    height: auto;
  }

  @supports (display: grid) {
    svg {
      width: 100%;
      height: auto;
    }
  }
}

.feature__label {
  color: var(--color-contrast-medium);
  font-size: var(--text-sm);
}

// --invert -> invert order of feature items at specific breakpoints
@include breakpoint(sm) {
  .feature--invert\@sm {
    .feature__grid > *:first-child {
      order: 2;
    }

    .feature__grid > *:last-child {
      order: 1;
    }
  }
}

@include breakpoint(md) {
  .feature--invert\@md {
    .feature__grid > *:first-child {
      order: 2;
    }

    .feature__grid > *:last-child {
      order: 1;
    }
  }
}

@include breakpoint(lg) {
  .feature--invert\@lg {
    .feature__grid > *:first-child {
      order: 2;
    }

    .feature__grid > *:last-child {
      order: 1;
    }
  }
}

// auto-invert features in a grid
.feature-group {}

@include breakpoint(sm) {
  .feature-group--auto-invert\@sm {
    .feature:nth-child(2n) {
      .feature__grid > *:first-child {
        order: 2;
      }
  
      .feature__grid > *:last-child {
        order: 1;
      }
    }
  }
}

@include breakpoint(md) {
  .feature-group--auto-invert\@md {
    .feature:nth-child(2n) {
      .feature__grid > *:first-child {
        order: 2;
      }
  
      .feature__grid > *:last-child {
        order: 1;
      }
    }
  }
}

@include breakpoint(lg) {
  .feature-group--auto-invert\@lg {
    .feature:nth-child(2n) {
      .feature__grid > *:first-child {
        order: 2;
      }
  
      .feature__grid > *:last-child {
        order: 1;
      }
    }
  }
}

/* -------------------------------- 

File#: _1_features-v2
Title: Features v2
Descr: A section containing a list of features
Usage: codyhouse.co/license

-------------------------------- */

.features-v2 {}

.features-v2__item {
  figure {
    flex-shrink: 0;
  }

  img {
    display: block;
    width: 64px;
  }
}

@include breakpoint(md) {
  .features-v2__item img {
    width: 64px;
  }
}

/* -------------------------------- 

File#: _1_feature-v4
Title: Feature v4
Descr: Content group containing media element + text that outsets its container at a specific breakpoint
Usage: codyhouse.co/license

-------------------------------- */

.feature-v4 {
  --feature-text-offset: 65%; // if percentage = higher -> overlapping text takes more space
 


}



  .logo-line {
    position: absolute;
    right: 20px;
    top: 40px;
    //z-index: 999;
    width: 800px;
    opacity: 0.2;
    max-width: 86vw;

    &.logo-line--left {
      right: auto;
      left: 20px;
    }
  }

@each $mq in 'xs' 'sm' 'md' 'lg' 'xl' {
  @include breakpoint(#{$mq}) {
    .feature-v4__text-offset\@#{$mq} {
      width: calc(100% + var(--feature-text-offset));
    }
  
    .feature-v4--invert\@#{$mq} {
      .feature-v4__grid > *:last-child {
        order: -1;
      }
  
      .feature-v4__text-offset\@#{$mq} {
        margin-left: calc(var(--feature-text-offset) * -1);
      }
    }
  }
}

.element--bordered {


  @include breakpoint(lg) {
  position: relative;
  &:after {
    content: '';
    width: 40px;
    height: 100%;
    background-color: var(--color-accent);
    position: absolute;
    right: 0px;
    top: 0;

  }
}

}

/* -------------------------------- 

File#: _1_features
Title: Features
Descr: A section containing a list of features
Usage: codyhouse.co/license

-------------------------------- */

.features {}

.features__item {
  img {
    display: block;
    width: 100%;
    margin-bottom: var(--space-sm);
  }
}

// --img-sm -> set max-width for images
.features--img-sm {
  img {
    max-width: 80px;
  }
}

/* -------------------------------- 

File#: _1_feature-v5
Title: Feature v5
Descr: Content group containing text components and a media element
Usage: codyhouse.co/license

-------------------------------- */

.feature-v5 {
  --feature-media-offset: var(--space-xxl); // image offset
  --feature-content-media-gap: var(--space-lg); // space between text and image
}

.feature-v5__content {
  background-color: var(--color-contrast-lower);
  padding-bottom: calc(var(--feature-content-media-gap) + var(--feature-media-offset));
}

.feature-v5__media {
  margin-top: calc(-1 * var(--feature-media-offset));
}

