
/* -------------------------------- 

File#: _1_masonry
Title: Masonry
Descr: Gallery with elements laid out in optimal position based on available vertical space
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --masonry-grid-gap: var(--space-sm);
  --masonry-col-auto-size: 280px; // col min-width value -> used in JS to auto-update the masonry cols width
}

.masonry {}

.masonry__loader {
  display: none;
}

.masonry__list {
  display: flex;
  flex-wrap: wrap;
  margin-right: calc( -1 * var(--masonry-grid-gap));
  margin-bottom: calc( -1 * var(--masonry-grid-gap));
}

.masonry__item {
  display: inline-block; // flex fallback
  width: var(--masonry-col-auto-size); // this will be updated using JS
  margin-right: var(--masonry-grid-gap);
  margin-bottom: var(--masonry-grid-gap);
}

.js {
  .masonry {
    position: relative;
  }

  .masonry__loader {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0; 
  }

  .masonry__list {
    opacity: 0;
    transition: opacity .4s;
  }

  .masonry--loaded { // reveal gallery once it has been initialized using JS
    .masonry__loader {
      display: none;
    }

    .masonry__list {
      opacity: 1;
    }
  }

  @supports(flex-basis: 0px) {
    .masonry__list {
      flex-direction: column;
    }
  
    .masonry__item {
      flex-basis: 0px;
    }
  }
}

/* -------------------------------- 

File#: _1_card-v9
Title: Card v9
Descr: Container of information used as teaser for further content exploration
Usage: codyhouse.co/license

-------------------------------- */

.card-v9 {
  display: block;
  position: relative;
  z-index: 1;
  text-decoration: none;
  color: inherit;
  background-color: var(--color-contrast-lower);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 0;
  overflow: hidden;
  box-shadow: var(--shadow-sm);
  transition: box-shadow .3s;

  &:hover {
    box-shadow: var(--shadow-md);
  }
}

.card-v9__content {
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;
  z-index: 2;
  padding: var(--component-padding);
}

.card-v9__text {
  padding-bottom: var(--space-xxxl); // this padding determines the ratio of the card
  max-width: 540px;
}

.card-v9__label {
  font-size: var(--text-sm);
  color: alpha(var(--color-contrast-higher), 0.55);
  margin-bottom: var(--space-xxs);
}

.card-v9__title {
  font-size: var(--text-xl);
}

.card-v9__btn {
  position: relative;
  display: inline-block;
  padding: var(--space-xs) var(--space-sm);
  @include fontSmooth;

  i { // button label
    position: relative;
    z-index: 2;
    color: var(--color-bg);
    opacity: 0;
    transition: opacity .3s;
  }

  &::after { // button background
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    background-color: alpha(var(--color-primary), 0.8);
    backdrop-filter: blur(5px);
    opacity: 0;
    transform: translateY(25%);
    transition: opacity .3s, transform .3s var(--ease-out);
  }
}

.card-v9:hover {
  .card-v9__btn {
    i {
      opacity: 1;
    }

    &::after {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

// --overlay-bg
.card-v9--overlay-bg {
  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: alpha(var(--color-contrast-higher), 0.5);
    transition: background-color .3s;
  }

  &:hover::before {
    background-color: alpha(var(--color-contrast-higher), 0.8);
  }
}

/* -------------------------------- 

File#: _2_article-gallery-v4
Title: Article Gallery v4
Descr: A gallery of blog articles
Usage: codyhouse.co/license

-------------------------------- */

.articles-v4.masonry {
  --masonry-grid-gap: var(--space-sm);
  --masonry-col-auto-size: 320px; // col min-width value -> used in JS to auto-update the masonry cols width
}
