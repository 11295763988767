/* -------------------------------- 

File#: _3_products-gallery-v2
Title: Products Gallery v2
Descr: A gallery of product cards
Usage: codyhouse.co/license

-------------------------------- */

.products-gallery-v2 {}

/* -------------------------------- 

File#: _2_product-card-v2
Title: Product Card v2
Descr: A selection of product information used as a teaser for further content
Usage: codyhouse.co/license

-------------------------------- */
.prod-card-v2 {
  --rating-icon-size: 1.475em;
  position: relative;
}

.prod-card-v2__img-link {
  display: block;
  position: relative;
  border-radius: var(--radius-lg);
  overflow: hidden;
  box-shadow: var(--shadow-sm);

  img {
    display: block;
    width: 100%;
  }

  img:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: .3s;
  }
  
  &:hover {
    img:nth-child(2) {
      opacity: 1;
    }
  }
}

.prod-card-v2__badge {
  position: absolute;
  z-index: 1;
  top: var(--space-sm);
  right: var(--space-sm);
  background-color: alpha(var(--color-black), 0.9);
  padding: var(--space-xxs) var(--space-sm);
  pointer-events: none;

  font-size: var(--text-sm);
  color: var(--color-white);
  @include fontSmooth;
}

.product-card-v2__title {
  color: var(--color-contrast-higher);
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
}

.prod-card-v2__price {
  text-decoration: none;
}

.prod-card-v2__old-price {
  color: var(--color-contrast-medium);
  text-decoration: line-through;

  &::before {
    content: 'original price';
    @include srHide;
  }
}

/* -------------------------------- 

File#: _1_stories
Title: Stories
Descr: A collection of related article tasters
Usage: codyhouse.co/license

-------------------------------- */

.stories {
  background-color: var(--color-contrast-lower);
  padding: var(--component-padding);
  border-radius: var(--radius-md);

  @include breakpoint(md) {
    font-size: var(--text-sm);
  }
}

.stories__legend {
  font-size: 1em;
  margin-bottom: var(--space-md);
  color: var(--color-contrast-medium);
}

.stories__story {
  display: flex;

  &:not(:last-child) {
    margin-bottom: var(--space-sm);
    padding-bottom: var(--space-sm);
    border-bottom: 1px solid var(--color-contrast-low);
  }
}

.stories__img-wrapper {
  display: block;
  width: 4.6em;
  height: 4.6em;
  border-radius: var(--radius-md);
  overflow: hidden;
  flex-shrink: 0;
  transition: opacity .2s;
  margin-right: var(--space-xs);

  figure, img {
    width: inherit;
    height: inherit;
  }

  img {
    display: block;
    object-fit: cover;
  }

  &:hover {
    opacity: 0.85;
  }
}

.stories__text {
  margin-top: var(--space-xxxs);
}

.stories__headline {
  font-size: var(--text-md);
}

.stories__metadata {
  color: var(--color-contrast-medium);
  font-size: var(--text-sm);
  text-transform: uppercase;
}

.stories__headline a,
.stories__metadata a {
  color: var(--color-contrast-higher);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.stories__separator {
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  background-color: var(--color-contrast-low);
  border-radius: 50%;
  margin: 0 var(--space-xxxs);
}