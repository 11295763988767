:root{
  --form-control-padding-x: var(--space-sm);
  --form-control-padding-y: var(--space-xs);
  --form-control-radius: 0.25em;
}

.form-control {
  background-color: var(--color-bg);
  border: 2px solid var(--color-contrast-low);

  &::-webkit-input-placeholder {
    color: var(--color-contrast-medium);
  }

  &::-moz-placeholder {
    color: var(--color-contrast-medium);
  }

  &:-ms-input-placeholder {
    color: var(--color-contrast-medium);
  }

  &:-moz-placeholder {
    color: var(--color-contrast-medium);
  }

  &:focus {
    border-color: var(--color-primary);
    box-shadow: 0px 0px 0px 3px alpha(var(--color-primary), 0.2);
    outline: none;
  }
}

.form-control[disabled], .form-control[readonly] {
  cursor: not-allowed;
}

.form-control[aria-invalid="true"] {
  border-color: var(--color-error);

  &:focus {
    box-shadow: 0px 0px 0px 3px alpha(var(--color-error), 0.2);
  }
}

.form-legend {
  color: var(--color-contrast-higher);
  font-size: var(--text-md);
  line-height: 1.2;
  margin-bottom: var(--space-xxs);
}

.form-label {
  font-size: var(--text-sm);
}

/* -------------------------------- 

File#: _1_alert
Title: Alert
Descr: Feedback message
Usage: codyhouse.co/license

-------------------------------- */

.alert {
  padding: var(--space-xs) var(--space-sm);
  background-color: alpha(var(--color-primary), 0.2);
  border-radius: var(--radius-md);
  color: var(--color-contrast-higher);
  // hide element
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
}

.alert__link {
  color: inherit;
  text-decoration: underline;
}

.alert__close-btn {
  display: inline-block; // flex fallback
  flex-shrink: 0;
  margin-left: var(--space-sm);

  .icon {
    display: block;
  }
}

// themes
.alert--success {
  background-color: alpha(var(--color-success), 0.2);
}

.alert--error {
  background-color: alpha(var(--color-error), 0.2);
}

.alert--warning {
  background-color: alpha(var(--color-warning), 0.2);
}

// toggle visibility
.alert--is-visible {
  position: static;
  clip: auto;
  clip-path: none;
}
